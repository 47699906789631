import styles from "./Create.module.sass";
import Form from './Form';

const ChangePassword = () => {

    return (
        <div className={styles.customClass}>
            <Form />
        </div>
    )
};

export default ChangePassword;