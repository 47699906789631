import React from "react";
import styles from "./NewProduct.module.sass";
import NameAndDescription from "./NameAndDescription";
import { useLocation } from "react-router";

const AddMarket = () => {
  const { state } = useLocation();

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription state={state} className={styles.card} />
        </div>
      </div>
    </>
  );
};

export default AddMarket;
