import React, { useEffect, useRef, useState } from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import TextInput from "../../../../components/TextInput";
import OrderDropdown from "../../../../components/OrderDropdown";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch } from "react-redux";
import { getRegionCreator } from "../../../../actions/getRegions";
import { addNotification } from "../../../../components/Notification";
import { getDigitsAfterDecimal } from "../../../../components/helper";

const NewPost = ({
  coinData,
  rechargeCoinHandler,
  getRegionData
}) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState("");
  const [amount, setAmount] = useState("");
  const [regionData, setRegionData] = useState([]);
  const [regionText, setRegionText] = useState("Please select a value");
  const [regionValue, setRegionValue] = useState("");
  const dispatch = useDispatch();

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    if (getRegionData?.length === 0) {
      dispatch(getRegionCreator());
    }
    const newDropDownValue = getRegionData?.map(x => {
      return { "id": x.id, "name": x.name }
    });
    setRegionData([...newDropDownValue]);
  }, [getRegionData]);

  return (
    <div className={styles.post}>
      <div className={cn("title-green", styles.title)}>Recharge {capitalizeFirstLetter(coinData?.name)}</div>
      <div className={styles.title}>Region: <span className={styles.text}>
        {coinData?.balance !== null || coinData?.balance !== undefined ? getDigitsAfterDecimal(coinData?.balance, coinData?.decimalPrecision) : "---"},</span>
        Int: <span className={styles.text}>
          {coinData?.int_balance !== undefined || coinData?.int_balance !== null ? getDigitsAfterDecimal(coinData?.int_balance, coinData?.decimalPrecision) : "---"}
        </span>
      </div>
      <div className={styles.dropdown}>
        <OrderDropdown
          label="Region"
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={regionValue}
          setValue={setRegionValue}
          text={regionText}
          setText={setRegionText}
          options={regionData}
        />
      </div>
      <TextInput
        className={styles.field}
        label="Amount"
        name="amount"
        type="text"
        value={amount}
        onChange={(e) => {
          const re = /^\d*\.?\d*$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            setAmount(e.target.value)
          }
        }}
        placeholder="Please enter amount"
        required
        onBlur={() => simpleValidator.current.showMessageFor("amount")}
      />
      <span className={styles.danger}>
        {simpleValidator.current.message("amount", amount, "required")}
      </span>
      <div className={styles.control}>
        <button className={cn("button", styles.button)} onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (regionText !== "Please select a value") {
              rechargeCoinHandler(coinData?.coinId, coinData?.symbol, amount, regionValue);
            }
            else {
              addNotification({
                title: "Alert",
                message: "Please select region",
                type: "danger",
              });
            }
          }
          else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
          }
        }}>
          <span>Submit</span>
        </button>
      </div>
    </div>
  );
};

export default NewPost;
