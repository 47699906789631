import React, { useState } from "react";
import cn from "classnames";
import styles from "./Refunds.module.sass";

// data
import { refunds } from "../../../mocks/refunds";
import Card from "../../../components/Card";
import Row from "../WalletInformation/Row";
import Loader from "../../../components/Loader";
import { getDigitsAfterDecimal } from "../../../components/helper";
import CustomDropdown from "../../../components/CustomDropdown";

const navigation = ["Open requests", "Closed requests"];

const Refunds = ({ walletDetails }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [regionText, setRegionText] = useState("");

  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title="Wallet Information"
      classTitle={cn("title-purple", styles.title)}
    >
      <div className={styles.row}>
        <div className={styles.card}>Total Region Balance : {walletDetails?.total_eur ? getDigitsAfterDecimal(walletDetails?.total_eur, walletDetails?.euro_decimal_amount_precision) + " EUR" : "---"}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.card}>Total International Balance : {walletDetails?.total_usdt ? getDigitsAfterDecimal(walletDetails?.total_usdt, walletDetails?.btc_decimal_amount_precision) + " USDT" : "---"}</div>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>Symbol</div>
            <div className={styles.col}>Region Balance</div>
            <div className={styles.col}>Int Balance</div>
            {/* <div className={styles.col}>Legacy Address</div>
            <div className={styles.col}>Stable Coin</div> */}
          </div>
          {walletDetails?.balances?.map((x, index) => (
            <Row item={x} key={index} walletDetails={walletDetails} />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Refunds;
