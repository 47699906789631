import React, { useRef, useState } from "react";
import styles from "./Share.module.sass";
import TextInput from "../../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import cn from "classnames";
import LoaderScreen from "../../../../../components/LoaderScreen";

const Share = ({ loading, item, rechargeBothandler, setVisibleBotModal }) => {
  const [amount, setAmount] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();

  return (
    <div className={styles.share}>
      {loading && <LoaderScreen />}
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Recharge Bot</div>
      </div>
      <TextInput
        className={styles.field}
        label="Amount"
        name="amount"
        type="text"
        placeholder="Please enter amount"
        onChange={(e) => { setAmount(e.target.value) }}
        value={amount}
        required
        onBlur={() => simpleValidator.current.showMessageFor("amount")}
      />
      <span> {simpleValidator.current.message("amount", amount, "required")}</span>

      <button className={cn("button button-small", styles.button)}
        onClick={(e) => {
          e.stopPropagation();
          if (simpleValidator.current.allValid()) {
            rechargeBothandler(item.uid, amount, setVisibleBotModal);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(1);
          }
        }}>
        <span>Continue</span>
      </button>
    </div>
  );
};

export default Share;
