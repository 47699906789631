import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import requestHandler from "../../../actions/httpClient";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Icon from "../../../components/Icon";
import OrderDropdown from "../../../components/OrderDropdown";
import CreateReward from "./Row/CreateReward";
import UpdateVoucher from "./Row/UpdateVoucher";

const levelData = [
  { id: 1, name: "Enable" },
  { id: 0, name: "Disable" },
];

const RecentPost = ({ className }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [updateRewardModalVisible, setUpdateRewardModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rewardList, setRewardList] = useState([]);
  const [rewardData, setRewardData] = useState([]);
  const [levelTypeValue, setLevelTypeValue] = useState(levelData[0].id);
  const [levelTypeText, setLevelTypeText] = useState(levelData[0].name);

  useEffect(() => {
    getRewardsList();
  }, [levelTypeValue]);

  const getRewardsList = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      type: levelTypeValue,
    };
    try {
      const getRewardsPayload = await requestHandler("getRewardLevelList", "post", data, "jwt_token");
      setRewardList(getRewardsPayload?.data?.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recently Added"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.dropdown}>
              <OrderDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={levelTypeValue}
                setValue={setLevelTypeValue}
                text={levelTypeText}
                setText={setLevelTypeText}
                options={levelData}
              />
            </div>
            <div className={styles.control}>
              <button className={cn("button", styles.button)} onClick={() => setVisibleModal(true)}>
                <Icon name="add" size="20" />
                <span>Add Reward & Level</span>
              </button>
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Level Number</div>
            <div className={styles.col}>Level Name</div>
            <div className={styles.col}>Min Invites</div>
            <div className={styles.col}>Max Invites</div>
            <div className={styles.col}>Min Volume</div>
            <div className={styles.col}>Max Volume</div>
            <div className={styles.col}>Commision(%)</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={10} rowCount={12} />
            :
            rewardList?.length > 0 ?
              <>
                {
                  rewardList?.map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      setRewardData={setRewardData}
                      setUpdateRewardModalVisible={setUpdateRewardModalVisible}
                    />
                  ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <CreateReward
          loading={loading}
          setLoading={setLoading}
          setVisibleModal={setVisibleModal}
          getRewardsList={getRewardsList}
          levelData={levelData}
        />
      </Modal>

      <Modal
        outerClassName={styles.outer}
        visible={updateRewardModalVisible}
        onClose={() => setUpdateRewardModalVisible(false)}
      >
        <UpdateVoucher
          loading={loading}
          setLoading={setLoading}
          item={rewardData}
          setUpdateRewardModalVisible={setUpdateRewardModalVisible}
          getRewardsList={getRewardsList}
          levelData={levelData}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
