import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Icon from "../../../components/Icon";
import CreateReward from "./Row/CreateReward";

const RecentPost = ({
  className,
  addNotificationHandler,
  visibleModal,
  setVisibleModal,
  notificationData,
  loading,
  resendNotificationHandler
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recently Added"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.control}>
              <button className={cn("button", styles.button)} onClick={() => setVisibleModal(true)}>
                <Icon name="add" size="20" />
                <span>Add New Notification</span>
              </button>
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Title</div>
            <div className={styles.col}>Message</div>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={5} rowCount={12} />
            :
            notificationData?.length > 0 ?
              <>
                {
                  notificationData?.map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      index={index}
                      resendNotificationHandler={resendNotificationHandler}
                    />
                  ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <CreateReward
          addNotificationHandler={addNotificationHandler}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
