import requstHandler from "../httpClient";
import {
    GET_REGION_FAILED,
    GET_REGION_REQUEST,
    GET_REGION_SUCCESS
} from "./actionType";


const getRegionRequest = () => {
    return {
        type: GET_REGION_REQUEST,
    };
};

const getRegionSuccess = (payload) => {
    return {
        type: GET_REGION_SUCCESS,
        payload: payload,
    };
};

const getRegionFailed = (err) => {
    return {
        type: GET_REGION_FAILED,
        message: err,
    };
};

const getRegionCreator = () => {
    return async (dispatch) => {
        dispatch(getRegionRequest());
        try {
            const regionPayload = await requstHandler('region', 'post');
            dispatch(getRegionSuccess(regionPayload?.data?.data));
        }
        catch (e) {
            dispatch(getRegionFailed(e.message));
        };
    };
};

export { getRegionRequest, getRegionSuccess, getRegionFailed, getRegionCreator };