import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import TextInput from "../../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import OrderDropdown from "../../../../../components/OrderDropdown"
import requestHandler from "../../../../../actions/httpClient";
import LoaderScreen from "../../../../../components/LoaderScreen";
import { addNotification } from "../../../../../components/Notification";

var type = [
  { id: parseInt(process.env.REACT_APP_COUPON_TYPE_PAYOUT_1), name: "Payout 1" }
];

const Share = ({ loading, setLoading, setVisibleModal, getVoucherList }) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [voucherName, setVoucherName] = useState("");
  const [voucherValidity, setVoucherValidity] = useState("");
  const [voucherValidityAfterActivate, setVoucherValidityActivate] = useState("");
  const [voucherAmount, setVoucherAmount] = useState("");
  const [voucherImage, setVoucherImage] = useState("");
  const [voucherMinDeposit, setVoucherMinDeposit] = useState("");
  const [typeValue, setTypeValue] = useState(type[0].id);
  const [typeText, setTypeText] = useState(type[0].name);

  const addCouponHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      type: typeValue,
      name: voucherName,
      validity: voucherValidity,
      validity_after_activate: voucherValidityAfterActivate,
      amount: voucherAmount,
      image: voucherImage,
      min_deposit: voucherMinDeposit,
    };
    try {
      const addCouponPayload = await requestHandler("createReferralCoupan", "post", data, "jwt_token", "file");
      setLoading(false);
      if (addCouponPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: addCouponPayload?.data?.message[0].msg,
          type: 'success'
        });
        setVisibleModal(false);
        getVoucherList();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0].msg,
        type: 'danger'
      });
    };
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Add Voucher</div>
        <div className={styles.description}>
          <div className={styles.customField}>
            <OrderDropdown
              label="Select type"
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={typeValue}
              setValue={setTypeValue}
              text={typeText}
              setText={setTypeText}
              options={type}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Voucher Name"
              name="voucherName"
              type="text"
              onChange={(e) => setVoucherName(e.target.value)}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("voucherName")}
            />
            {simpleValidator.current.message("voucherName", voucherName, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Voucher Amount"
              name="voucherAmount"
              type="text"
              onChange={(e) => setVoucherAmount(e.target.value)}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("voucherAmount")}
            />
            {simpleValidator.current.message("voucherAmount", voucherAmount, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Voucher Validity (Days)"
              name="voucherValidity"
              type="text"
              onChange={(e) => setVoucherValidity(e.target.value)}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("voucherValidity")}
            />
            {simpleValidator.current.message("voucherValidity", voucherValidity, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Voucher Validity After Activation (Days)"
              name="voucherValidityActivation"
              type="text"
              onChange={(e) => setVoucherValidityActivate(e.target.value)}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("voucherValidityActivation")}
            />
            {simpleValidator.current.message("voucherValidityActivation", voucherValidityAfterActivate, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Voucher Image"
              name="voucherImage"
              type="file"
              onChange={(e) => setVoucherImage(e.target.files[0])}
              required
              onBlur={() => simpleValidator.current.showMessageFor("voucherImage")}
            />
            {simpleValidator.current.message("voucherImage", voucherImage, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Voucher Min Deposit"
              name="voucherMinDeposit"
              type="text"
              placeholder="Please enter a value"
              onChange={(e) => setVoucherMinDeposit(e.target.value)}
              required
              onBlur={() => simpleValidator.current.showMessageFor("voucherMinDeposit")}
            />
            {simpleValidator.current.message("voucherMinDeposit", voucherMinDeposit, "required")}
          </div>
        </div>
        <div className={styles.control}>
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              addCouponHandler();
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }
          }>
            <span>Continue</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Share;
