import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item, updateUserHandler }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item.fname === null || undefined || item.lname === null || undefined ? "---" : item.fname + " " + item.lname}
      </div>
      <div className={styles.col}>
        <div className={styles.text}>
          {item.email}
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item.uid}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item.role}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.row}>
          <div className={styles.buttonClass}>
            <div className={styles.settings}>
              <div>
                {item?.email_verified === parseInt(process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS) ?
                  (
                    <button className={cn("button-small", styles.acceptButton)} type="button"
                      onClick={() => {
                        updateUserHandler(item?.id, item?.fname, item?.lname, parseInt(process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS), item?.kyc_verification, item?.residence_verification);
                      }}
                    >
                      <span>Activate</span>
                    </button>
                  ) :
                  (
                    <button className={cn("button-small", styles.rejectButton)} type="button"
                      onClick={() => {
                        updateUserHandler(item?.id, item?.fname, item?.lname, parseInt(process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS), item?.kyc_verification, item?.residence_verification);
                      }}
                    >
                      <span>Deactivate</span>
                    </button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
