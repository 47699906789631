import React from "react";
import { toLocaleStringDateFormat } from "../../../../../components/helper";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item }) => {
  return (
    <div className={styles.row}>

      <div className={styles.col}>
        {item?.source?.type}
        <div className={styles.text}>
          {item?.source?.bic}
        </div>
      </div>
      <div className={styles.col}>
        {item?.amount}
      </div>
      <div className={styles.col}>
        {item?.region}
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.reference}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {item?.payment_status === "Success" ? (
          <div className={cn("status-green-dark", styles.distribution)}>
            Success
          </div>
        ) : (
          <div className={cn("status-red-dark", styles.distribution)}>
            {item?.payment_status}
          </div>
        )}
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.createdAt)}
      </div>
    </div>
  );
};

export default Row;
