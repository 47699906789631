import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({
  item,
  index,
  updateStatusHandler
}) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {index + 1}
      </div>
      <div className={styles.col}>
        {item?.banner_name}
      </div>
      <div className={styles.col}>
        <div className={styles.preview}>
          <img
            src={process.env.REACT_APP_IMAGE_URL + "/" + item.image}
            alt="Banner" />
        </div>
      </div>
      <div className={styles.col}>
        {item?.type === parseInt(process.env.REACT_APP_GLOBAL_BANNER) ?
          "Global"
          :
          item?.type === parseInt(process.env.REACT_APP_HOME_BANNER) ?
            "Home"
            :
            item?.type === parseInt(process.env.REACT_APP_BITDENEX_PAY_BANNER) ?
              "Bitdenex Pay"
              :
              item?.type === parseInt(process.env.REACT_APP_NFT_BANNER) ?
                "Nft"
                :
                "---"
        }
      </div>
      <div className={styles.col}>
        {
          item?.status === parseInt(process.env.REACT_APP_BANNER_STATUS_ENABLE) ? (
            <div className={cn("status-green-dark", styles.distribution)}>
              Enable
            </div>)
            :
            (
              <div className={cn("status-red-dark", styles.distribution)}>
                Disabled
              </div>
            )
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.createdAt)}
      </div>
      {
        item?.status === parseInt(process.env.REACT_APP_BANNER_STATUS_ENABLE) ?
          <div className={styles.col}>
            <div className={styles.control}>
              <button className={cn("button-small", styles.dropdown)} onClick={(e) => {
                e.preventDefault();
                updateStatusHandler(item?.id, parseInt(process.env.REACT_APP_BANNER_STATUS_DISABLE));
              }}>
                <span>Disable</span>
              </button>
            </div>
          </div>
          :
          <div className={styles.col}>
            <div className={styles.control}>
              <button className={cn("button-small", styles.dropdown)} onClick={(e) => {
                e.preventDefault();
                updateStatusHandler(item?.id, parseInt(process.env.REACT_APP_BANNER_STATUS_ENABLE));
              }}>
                <span>Enable</span>
              </button>
            </div>
          </div>
      }

    </div>
  );
};

export default Row;
