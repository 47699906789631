import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Row from "./Row";

const RecentPost = ({ className, bitdenexPayWithdrawData }) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Bitdenex Pay(Sent)"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>To (CId)</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Txn Id</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {bitdenexPayWithdrawData?.map((x, index) => (
            <Row item={x} key={index} />
          ))}
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
