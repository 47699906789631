import {
    GET_REGION_FAILED,
    GET_REGION_REQUEST,
    GET_REGION_SUCCESS
} from "../actions/getRegions/actionType";

const intialState = {
    loading: false,
    error: "",
    getRegionData: [],
};

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case GET_REGION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_REGION_SUCCESS:
            return {
                ...state,
                loading: false,
                getRegionData: action.payload,
            };

        case GET_REGION_FAILED:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    };
};


export default reducer;
