import React, { useState } from "react";
import cn from "classnames";
import styles from "./Refunds.module.sass";

// data
import Card from "../../../components/Card";
import Row from "../WhiteListAddressInfo/Row";

const navigation = ["Open requests", "Closed requests"];

const Refunds = ({ addressData, updateAddressHandler, superadmin }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title="Addresses"
      classTitle={cn("title-purple", styles.title)}
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>Address</div>
            <div className={styles.col}>Region</div>
            <div className={styles.col}>Ownership</div>
            <div className={styles.col}>Scan Ownership</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            {/* {superadmin && <div className={styles.col}>Action</div>} */}
          </div>
          {addressData?.map((x, index) => (
            <Row
              item={x}
              key={index}
              updateAddressHandler={updateAddressHandler}
              superadmin={superadmin}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Refunds;
