import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({ item }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item.userId}
      </div>
      <div className={styles.col}>
        <div className={styles.details}>
          {item.coin.toUpperCase()}
        </div>
      </div>
      <div className={styles.col}>
        {getDigitsAfterDecimal(item.amount, item.decimalPrecision)}
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item.updatedAt)}
      </div>
    </div>
  );
};

export default Row;
