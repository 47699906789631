import {
  MARKET_FAILED,
  MARKET_REQUEST,
  MARKET_SUCCESS
} from "../actions/market/actionType";

const intialState = {
  loading: false,
  error: "",
  marketData: [],
  currencyData: [],
};

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case MARKET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case MARKET_SUCCESS:
      return {
        ...state,
        loading: false,
        marketData: action.payload.marketData,
        currencyData: action.payload.currency,
      };

    case MARKET_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
