import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import CustomDropdown from "../../../components/CustomDropdown";
import NewPost from "./NewPost";
import Modal from "../../../components/Modal";

const RecentPost = ({
  className,
  loading,
  statusText,
  setStatusText,
  statusValue,
  setStatusValue,
  statusValues,
  getStatusValues,
  bannerData,
  visibleModal,
  setVisibleModal,
  addBannerHandler,
  updateStatusHandler,
  regionValues,
  setRegionValue,
  regionValue,
  bannerTypes,
  bannerText,
  setBannerText,
  bannerValue,
  setBannerValue,
  getBannerTypeStatus
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Added"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.dropdown}>
              <CustomDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={statusValue}
                setValue={setStatusValue}
                text={statusText}
                setText={setStatusText}
                options={statusValues}
                handleClick={getStatusValues}
                small
              />
            </div>
            <div className={styles.control}>
              <button className={cn("button-small", styles.dropdown)} onClick={(e) => {
                e.preventDefault();
                setVisibleModal(true);
              }}>
                <span>Add Banner</span>
              </button>
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Title</div>
            <div className={styles.col}>Image</div>
            <div className={styles.col}>Type</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={7} rowCount={12} />
            :
            bannerData?.length > 0 ?
              <>
                {
                  bannerData?.map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      index={index}
                      updateStatusHandler={updateStatusHandler}
                    />
                  ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost
          statusText={statusText}
          statusValue={statusValue}
          statusValues={statusValues}
          addBannerHandler={addBannerHandler}
          getStatusValues={getStatusValues}
          loading={loading}
          regionValue={regionValue}
          regionValues={regionValues}
          setRegionValue={setRegionValue}
          bannerTypes={bannerTypes}
          bannerText={bannerText}
          setBannerText={setBannerText}
          bannerValue={bannerValue}
          setBannerValue={setBannerValue}
          getBannerTypeStatus={getBannerTypeStatus}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
