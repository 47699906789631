import React, { useState } from "react";
import cn from "classnames";
import styles from "./BotBalanceHistory.module.sass";
import Card from "../../../components/Card";
import OrderDropdown from "../../../components/OrderDropdown";
import requestHandler from "../../../actions/httpClient";
import { useEffect } from "react";
import Dropdown from "../../../components/Dropdown";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import { addNotification } from "../../../components/Notification";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const BotBalanceHistory = ({ className }) => {
  const [regionText, setRegionText] = useState("Please select region");
  const [regionValue, setRegionValue] = useState();
  const [regionData, setRegionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [coinsData, setCoinsData] = useState([]);
  const [coinText, setCoinText] = useState("Please select coin");
  const [botBalanceHistoryData, setBotBalanceHistoryData] = useState([]);

  useEffect(() => {
    getRegion();
  }, [regionValue]);

  const getBotBalanceHistory = async () => {
    setLoading(true);
    let data = {
      coin: coinText,
      region: regionValue,
      signature: localStorage.getItem("signature"),
    };
    try {
      const botBalanceHistoryPayload = await requestHandler("getBotBalanceHistory", "post", data, "jwt_token");
      setLoading(false);
      setBotBalanceHistoryData(botBalanceHistoryPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const getRegion = async () => {
    setLoading(true);
    setCoinText("Please select coin");
    setCoinsData([]);
    try {
      const regionPayload = await requestHandler("region", "post");
      setLoading(false);
      getMarkets(regionValue ? regionValue : regionPayload?.data?.data[0]?.id);

      const newDropDownValue = regionPayload?.data?.data?.map((x) => { return { "id": x.id, "name": x.name } });
      setRegionData([...newDropDownValue]);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const getMarkets = async (id) => {
    setLoading(true);
    try {
      let data = {
        type: "adminRecharge",
        region: id,
      };
      const marketsPayload = await requestHandler("get_markets", "post", data);
      setLoading(false);
      if (regionText && regionValue) {
        const coinListDropDownValue = marketsPayload?.data?.data[0].markets?.map((x) => { return x.symbol });
        setCoinsData([...coinListDropDownValue]);
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Admin Balance History"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >

        <div className={styles.description}>
          <div className={styles.group}>
            <div className={styles.field}>
              <div className={styles.customDropDown}>
                <OrderDropdown
                  label="Select Region"
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={regionValue}
                  setValue={setRegionValue}
                  text={regionText}
                  setText={setRegionText}
                  options={regionData}
                />
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.customDropDown}>
                <Dropdown
                  className={styles.field}
                  label="Coin"
                  value={coinText}
                  setValue={setCoinText}
                  options={coinsData}
                />
              </div>
            </div>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              if (regionText.toLowerCase() === "please select region") {
                addNotification({
                  title: 'Error',
                  message: "Please Select Region",
                  type: 'danger'
                })
              }
              else if (coinText.toLowerCase() === "please select coin") {
                addNotification({
                  title: 'Error',
                  message: "Please Select Coin",
                  type: 'danger'
                })
              }
              else {
                getBotBalanceHistory();
              }
            }}
            className={cn("button", styles.button)}
          >
            Show List
          </button>
        </div>
      </Card>
      <Card
        className={cn(styles.card, className)}
        title="Admin Balance History"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>C Id</div>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={4} rowCount={10} />
            :
            botBalanceHistoryData.length > 0 ?
              <>
                {botBalanceHistoryData?.map((x, index) => {
                  return (
                    <Row item={x} key={index} />
                  )
                })
                }
              </> : "No Data Found"
          }
        </div>
      </Card>
    </>
  );
};

export default BotBalanceHistory;
