import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import NewModal from "../../../components/NewModal";
import Row from "./Row";
import NewPost from "./NewPost";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Icon from "../../../components/Icon";

const RecentPost = ({
  className,
  transactionData,
  loading,
  regionValue,
  disableTransactionHandler,
  enableTransactionHandler,
  setVisibleModal,
  visibleModal,
  addTransactionHandler
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Transactions"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <button className={cn("button", styles.button)} onClick={() => setVisibleModal(true)}>
              <Icon name="add" size="20" />
              <span>Add Transaction</span>
            </button>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Low</div>
            <div className={styles.col}>Medium</div>
            <div className={styles.col}>High</div>
            <div className={styles.col}>Duration</div>
            <div className={styles.col}>Interval</div>
            <div className={styles.col}>Module</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={8} rowCount={12} />
            :
            transactionData?.length > 0 ?
              <>
                {
                  transactionData?.map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      regionValue={regionValue}
                      enableTransactionHandler={enableTransactionHandler}
                      disableTransactionHandler={disableTransactionHandler}
                    />
                  ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
      <NewModal
        outerClassName={styles.outer}
        showModal={visibleModal}
        handleCloseModal={() => setVisibleModal(false)}
      >
        <NewPost
          addTransactionHandler={addTransactionHandler}
          loading={loading}
        />
      </NewModal>
    </>
  );
};

export default RecentPost;
