import CreateUser from './ProfileInformation'
import styles from "./Create.module.sass";
import requestHandler from '../../actions/httpClient';
import { addNotification } from '../../components/Notification';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const CreateUsers = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const updateUserBalancehandler = async (userId, coinSymbol, amount, region) => {
        setLoading(true);
        let data = {
            signature: localStorage.getItem("signature"),
            userId: userId,
            coinSymbol: coinSymbol,
            amount: amount,
            region: region,
        };
        try {
            const updateuserBalancePayload = await requestHandler("updateLostUserBalance", "post", data, 'jwt_token');
            setLoading(false);
            if (updateuserBalancePayload.status === 200) {
                addNotification({
                    title: 'Success',
                    message: updateuserBalancePayload?.data?.message[0]?.msg,
                    type: 'success'
                });
                navigate('/');
            }
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: 'Error',
                message: e?.data?.message[0]?.msg,
                type: 'danger'
            });
        };
    }

    return (
        <div className={styles.customClass}>
            <CreateUser
                className={styles.card}
                updateUserBalancehandler={updateUserBalancehandler}
                loading={loading}
            />
        </div>
    )
}

export default CreateUsers