import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import TextInput from "../../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import OrderDropdown from "../../../../../components/OrderDropdown"
import requestHandler from "../../../../../actions/httpClient";
import LoaderScreen from "../../../../../components/LoaderScreen";
import { addNotification } from "../../../../../components/Notification";

var type = [
  { id: parseInt(process.env.REACT_APP_COUPON_TYPE_PAYOUT_1), name: "Payout 1" }
];

const Share = ({ levelData, loading, setLoading, setVisibleModal, getRewardsList }) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [levelName, setLevelName] = useState("");
  const [level, setLevel] = useState("");
  const [percentage, setPercentage] = useState("");
  const [minInvites, setMinInvites] = useState("");
  const [maxInvites, setMaxInvites] = useState("");
  const [minVolume, setMinVolume] = useState("");
  const [maxVolume, setMaxVolume] = useState("");
  const [assessment, setAssessment] = useState("");
  const [statusText, setStatusText] = useState(levelData[0].name);
  const [statusValue, setStatusValue] = useState(levelData[0].id);

  const addRewardHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      name: levelName,
      level: level,
      percentage: percentage,
      status: statusValue,
      min_invites: minInvites,
      min_volume: minVolume,
      max_invites: maxInvites,
      max_volume: maxVolume,
      assesment: assessment,
    };
    try {
      const addRewardPayload = await requestHandler("createRewardLevel", "post", data, "jwt_token");
      setLoading(false);
      if (addRewardPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: addRewardPayload?.data?.message[0].msg,
          type: 'success'
        });
        setVisibleModal(false);
        getRewardsList();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0].msg,
        type: 'danger'
      });
    };
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Add Reward & Level</div>
        <div className={styles.description}>
          <div className={styles.customField}>
            <OrderDropdown
              label="Status"
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={statusValue}
              setValue={setStatusValue}
              text={statusText}
              setText={setStatusText}
              options={levelData}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Level Name"
              name="levelName"
              type="text"
              onChange={(e) => setLevelName(e.target.value)}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("levelName")}
            />
            {simpleValidator.current.message("levelName", levelName, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Level"
              name="level"
              type="text"
              onChange={(e) => setLevel(e.target.value)}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("level")}
            />
            {simpleValidator.current.message("level", level, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Percentage(%)"
              name="percentage"
              type="text"
              onChange={(e) => setPercentage(e.target.value)}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("percentage")}
            />
            {simpleValidator.current.message("percentage", percentage, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Minimum Invites"
              name="minInvites"
              type="text"
              onChange={(e) => setMinInvites(e.target.value)}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("minInvites")}
            />
            {simpleValidator.current.message("minInvites", minInvites, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Maximum Invites"
              name="maxInvites"
              type="text"
              onChange={(e) => setMaxInvites(e.target.value)}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("maxInvites")}
            />
            {simpleValidator.current.message("maxInvites", maxInvites, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Minimum Volume"
              name="minVolume"
              type="text"
              placeholder="Please enter a value"
              onChange={(e) => setMinVolume(e.target.value)}
              required
              onBlur={() => simpleValidator.current.showMessageFor("minVolume")}
            />
            {simpleValidator.current.message("minVolume", minVolume, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Maximum Volume"
              name="maxVolume"
              type="text"
              placeholder="Please enter a value"
              onChange={(e) => setMaxVolume(e.target.value)}
              required
              onBlur={() => simpleValidator.current.showMessageFor("maxVolume")}
            />
            {simpleValidator.current.message("maxVolume", maxVolume, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Assessment"
              name="assessment"
              type="text"
              placeholder="Please enter a value"
              onChange={(e) => setAssessment(e.target.value)}
              required
              onBlur={() => simpleValidator.current.showMessageFor("assessment")}
            />
            {simpleValidator.current.message("assessment", assessment, "required")}
          </div>
        </div>
        <div className={styles.control}>
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              addRewardHandler();
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }
          }>
            <span>Continue</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Share;
