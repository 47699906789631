import { combineReducers } from "redux";

import marketReducer from "./marketReducer";
import signInReducer from './signInReducer'
import customerListReducer from './customerListReducer'
import userDetailsReducer from './userDetailsReducer'
import referralListReducer from './referralListReducer'
import userWalletReducer from './userWalletReducer'
import coinReducer from './coinReducer'
import marketListReducer from './marketListReducer'
import addNewCoinReducer from './addNewCoinReducer'
import addNewMarketReducer from './addNewMarketReducer'
import getRegionReducer from './getRegionReducer'

export default combineReducers({
  market: marketReducer,
  signIn: signInReducer,
  customerList: customerListReducer,
  userDetails: userDetailsReducer,
  referralList: referralListReducer,
  walletDetail: userWalletReducer,
  coin: coinReducer,
  marketList: marketListReducer,
  addNewCoin: addNewCoinReducer,
  addNewMarket: addNewMarketReducer,
  getRegion: getRegionReducer,
});
