import React, { useRef, useState } from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import Dropdown from "../../../../components/Dropdown";
import TextInput from "../../../../components/TextInput";
import LoaderScreen from "../../../../components/LoaderScreen";
import SimpleReactValidator from "simple-react-validator";

const intervalValues = ["Single", "Hour"];
const moduleValues = [
  "Fiat Deposit", "Crypto Deposit", "Crypto Withdraw", "Fiat Withdraw", "Fiat to Crypto", "Crypto to Fiat"
];

const durationValues = ["24 Hours", "48 Hours", "72 Hours", "96 Hours", "120 Hours"];

const NewPost = ({ addTransactionHandler, loading }) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [module, setModule] = useState(moduleValues[0]);
  const [low, setLow] = useState("");
  const [medium, setMedium] = useState("");
  const [high, setHigh] = useState("");
  const [duration, setDuration] = useState(durationValues[0]);
  const [interval, setInterval] = useState(intervalValues[0]);

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Add Transaction</div>
        <div className={styles.regionDropdown}>
          <Dropdown
            label="Module"
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={module}
            setValue={setModule}
            options={moduleValues}
            small
          />
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Low"
              name="low"
              type="text"
              value={low}
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                if (regex.test(e.target.value) || e.target.value === '') {
                  setLow(e.target.value)
                }
              }
              }
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("low")}
            />
            <span className={styles.errorMessage}>
              {simpleValidator.current.message("low", low, "required")}
            </span>
          </div>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Medium"
            name="medium"
            type="text"
            value={medium}
            onChange={(e) => {
              const regex = /^\d*\.?\d*$/;
              if (regex.test(e.target.value) || e.target.value === '') {
                setMedium(e.target.value)
              }
            }
            } placeholder="Please enter a value"
            required
            onBlur={() => simpleValidator.current.showMessageFor("medium")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("medium", medium, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="High"
            name="high"
            type="text"
            value={high}
            onChange={(e) => {
              const regex = /^\d*\.?\d*$/;
              if (regex.test(e.target.value) || e.target.value === '') {
                setHigh(e.target.value)
              }
            }
            } placeholder="Please enter a value"
            required
            onBlur={() => simpleValidator.current.showMessageFor("high")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("high", high, "required")}
          </span>
        </div>
        <div className={styles.regionDropdown}>
          <Dropdown
            label={"Interval"}
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={interval}
            setValue={setInterval}
            options={intervalValues}
            small
          />
        </div>
        {interval?.toLowerCase() === "hour" &&
          // <div className={styles.customField}>
          //   <TextInput
          //     className={styles.field}
          //     label="Duration"
          //     name="duration"
          //     type="text"
          //     value={duration}
          //     onChange={(e) => {
          //       const regex = /^\d*\.?\d*$/;
          //       if (regex.test(e.target.value) || e.target.value === '') {
          //         setDuration(e.target.value)
          //       }
          //     }}
          //     placeholder="Please enter a value"
          //     required
          //     onBlur={() => simpleValidator.current.showMessageFor("duration")}
          //   />
          //   <span className={styles.errorMessage}>
          //     {simpleValidator.current.message("duration", duration, "required")}
          //   </span>
          // </div>
          <div className={styles.regionDropdown}>
            <Dropdown
              label={"Duration"}
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={duration}
              setValue={setDuration}
              options={durationValues}
              small
            />
          </div>
        }
        <div className={styles.control}>
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              addTransactionHandler(module, low, medium, high, interval, interval?.toLowerCase() === "single" ? 0 : duration.split(" ")[0]);
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }
          }>
            <span>Continue</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default NewPost;
