import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { getActInactCoinStatusWithoutStatusColor, getCoinType, getDigitsAfterDecimal } from "../../../../components/helper";
import { Link } from "react-router-dom";

const Row = ({ item }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.preview}>
            <img srcSet={`${process.env.REACT_APP_IMAGE_URL + "/" + item.icon} 2x`} src={process.env.REACT_APP_IMAGE_URL + "/" + item.icon} alt="Coin" />
          </div>
          <div className={styles.details}>
            <div
              className={styles.post}
              dangerouslySetInnerHTML={{ __html: item.name.toUpperCase() }}>
            </div>
            <div className={styles.text}><small>{item.symbol.toUpperCase()}</small></div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {parseInt(item.maintenanceMode) === parseInt(process.env.REACT_APP_COIN_MAINTENENCE_MODE_YES) ? "Yes" :
          parseInt(item.maintenanceMode) === parseInt(process.env.REACT_APP_COIN_MAINTENENCE_MODE_NO) ? "No" : "---"
        }
      </div>
      <div className={styles.col}>
        {
          getActInactCoinStatusWithoutStatusColor(item.status) === "Active" ?
            (<div className={cn("status-green-dark", styles.distribution)}>
              Active
            </div>)
            :
            (<div className={cn("status-red-dark", styles.distribution)}>
              InActive
            </div>)
        }
      </div>
      <div className={styles.col}>
        {item?.fireblock_id}
      </div>
      {/* <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {getCoinType(item.stableCoin)}
            </div>
          </div>
        </div>
      </div> */}
      <div className={styles.col}>
        {item?.network_slug && item?.network_slug.length > 0 ?
          <>
            {item?.network_slug && item?.network_slug?.map((x, index) => {
              return (
                <div className={styles.cell} key={index}>
                  <div className={styles.box}>
                    <div className={styles.number}>
                      {x}
                    </div>
                  </div>
                </div>
              )
            })}
          </>
          :
          'NA'
        }
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {getDigitsAfterDecimal(item.maxFee, item.decimalPrecision)}
            </div>
            <div className={styles.text}><small>Max</small> </div>
          </div>
        </div>

        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {getDigitsAfterDecimal(item.minFee, item.decimalPrecision)}
            </div>
            <div className={styles.text}><small>Min</small></div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <Link to={{
          pathname: `/trade/coins/${item.symbol}`,
        }}
          state={item}
        >
          Edit
        </Link>
      </div>
    </div>
  );
};

export default Row;
