import React, { useState } from "react";
import cn from "classnames";
import styles from "./Refunds.module.sass";
import Card from "../../../components/Card";
import Row from "./Row";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import CustomModal from "../../../components/CustomModal";
import Modal from "../../../components/Modal";
import Details from "./Details";
import requestHandler from "../../../actions/httpClient";
import LoaderScreen from "../../../components/LoaderScreen";
import Onfido from "../../../components/Onfido";
import { addNotification } from "../../../components/Notification";

const directorDetails = [
  {
    fname: "Director",
    lname: "One",
    email: "director1@gmail.com",
    phone: "+91-8950652181"
  },
  {
    fname: "Director",
    lname: "Two",
    email: "director2@gmail.com",
    phone: "+91-8950652182"
  },
  {
    fname: "Director",
    lname: "Three",
    email: "director3@gmail.com",
    phone: "+91-8950652183"
  },
  {
    fname: "Director",
    lname: "Four",
    email: "director4@gmail.com",
    phone: "+91-8950652184"
  },
  {
    fname: "Director",
    lname: "Five",
    email: "director5@gmail.com",
    phone: "+91-8950652185"
  },
];

const item = {
  id: 0,
  product: "Bento Matte 3D Illustration",
  category: "UI design kit",
  image: "/images/content/product-pic-1.jpg",
  image2x: "/images/content/product-pic-1@2x.jpg",
  status: true,
  date: "13 Sep",
  man: "Kyler Kuphal",
  avatar: "/images/content/avatar-1.jpg",
  parameters: [
    {
      title: "Request send",
      content: "Aug 20, 2021",
    },
    {
      title: "Reason",
      content: "Download link is broken",
    },
    {
      title: "Product downloaded",
      downloadedStatus: true,
      downloadedValue: true,
    },
    {
      title: "Purchase date",
      content: "July 01, 2021",
    },
    {
      title: "Purchase code",
      content: "6373ads-hd73h-8373DS",
    },
    {
      title: "Request ID",
      content: "8975ads-hd73h-8974DS",
    },
    {
      title: "Market fee",
      tooltip: "Description Market fee",
      price: 7.28,
    },
    {
      title: "Price",
      tooltip: "Description Price",
      price: 72.88,
    },
  ],
};

const Director = ({ handlePageClick, pageNo, profileInfo, director, getCustomerDetails, superadmin }) => {

  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onfidoWindowModal, setOnfidoWindowModal] = useState(false);
  const [token, setToken] = useState("");
  const [workFlowRunId, setWorkFlowRunId] = useState("");
  const [userId, setUserId] = useState("");

  const checkDirectorKycHandler = async (corporate_id) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      corporate_id: corporate_id
    };
    try {
      const checkDirectorKycPayload = await requestHandler("startKycAgain", "post", data, "jwt_token");
      setLoading(false);
      setToken(checkDirectorKycPayload?.data?.data?.sdk_token);
      setWorkFlowRunId(checkDirectorKycPayload?.data?.data?.workflow_run_id);

      if (checkDirectorKycPayload && checkDirectorKycPayload.status === 200) {
        setOnfidoWindowModal(true);
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  const kybInProcessActionHandler = async (userId, kyb_status) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      userId: userId,
      kyb_status: kyb_status
    };
    try {
      const kybInProcessActionPayload = await requestHandler("actionInReviewKyb", "post", data, "jwt_token");
      setLoading(false);
      if (kybInProcessActionPayload && kybInProcessActionPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: kybInProcessActionPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
        getCustomerDetails();
      };
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title="Director Information"
      classTitle={cn("title-purple", styles.title)}
      head={
        superadmin &&
        <div className={styles.control}>
          <button className={cn("button", styles.button)} onClick={() => setVisibleModal(true)}>
            <Icon name="add" size="20" />
            <span>Add Director</span>
          </button>
        </div>
      }
    >
      <div className={styles.wrapper}>
        {loading && <LoaderScreen />}
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>First Name</div>
            <div className={styles.col}>Last Name</div>
            <div className={styles.col}>Email</div>
            <div className={styles.col}>Phone Number</div>
            <div className={styles.col}>KYB Status</div>
            {superadmin && <div className={styles.col}>Action</div>}
          </div>
          {
            director?.length > 0 ?
              <>
                {director?.map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                    checkDirectorKycHandler={checkDirectorKycHandler}
                    kybInProcessActionHandler={kybInProcessActionHandler}
                    setUserId={setUserId}
                    superadmin={superadmin}
                  />
                ))}
                {
                  director?.length === (10 * pageNo) && <div className={styles.customButton}>
                    <button className={cn("button-stroke button-small", styles.button)} onClick={() => { handlePageClick() }}>
                      <span>Load more</span>
                    </button>
                  </div>
                }
              </>
              :
              "No data found"
          }
        </div>
      </div>
      <Modal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Details profileInfo={profileInfo} setVisibleModal={setVisibleModal} getCustomerDetails={getCustomerDetails} />
      </Modal>

      <Modal
        visible={onfidoWindowModal}
        onClose={() => setOnfidoWindowModal(false)}
      >
        <Onfido
          userId={userId}
          token={token}
          workFlowRunId={workFlowRunId}
          getCustomerDetails={getCustomerDetails}
        />
      </Modal>
    </Card>
  );
};

export default Director;
