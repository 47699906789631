import React from "react";
import { toLocaleStringDateFormat } from "../../../../components/helper";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item, updateStatusHandler }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item?.userId}
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.account_number}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.preview} onClick={() => window.open(item?.image_url, '_blank')}>
            <img className={styles.image} src={item?.image_url} alt="proof" />
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {
          parseInt(item?.status) === parseInt(process.env.REACT_APP_BANKVERIFICATIONPENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            parseInt(item?.status) === parseInt(process.env.REACT_APP_BANKVERIFICATIONINPROCESS) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                In-process
              </div>
              :
              parseInt(item?.status) === parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE) ?
                <div className={cn("status-green-dark", styles.distribution)}>
                  Complete
                </div>
                :
                parseInt(item?.status) === parseInt(process.env.REACT_APP_BANKVERIFICATIONFAILURE) ?
                  <div className={cn("status-red-dark", styles.distribution)}>
                    Failure
                  </div>
                  :
                  parseInt(item?.status) === parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT) ?
                    <div className={cn("status-red-dark", styles.distribution)}>
                      Reject
                    </div>
                    :
                    ""
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.createdAt)}
      </div>
      <div className={styles.col}>
        {parseInt(item.status) === parseInt(process.env.REACT_APP_BANKVERIFICATIONPENDING) ?
          <div className={styles.row}>
            <div className={styles.buttonClass}>
              <div className={styles.settings}>
                <div>
                  <button className={cn("button-small", styles.acceptButton)} type="button"
                    onClick={() => {
                      updateStatusHandler(parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE), item.id, item?.account_number);
                    }}
                  >
                    <span>Accept</span>
                  </button>
                  <button className={cn("button-small", styles.rejectButton)} type="button"
                    onClick={() => {
                      updateStatusHandler(parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT), item.id, item?.account_number);
                    }}
                  >
                    <span>Reject</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          :
          parseInt(item.status) === parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT) ?
            <div className={styles.row}>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <div>
                    <button className={cn("button-small", styles.acceptButton)} type="button"
                      onClick={() => {
                        updateStatusHandler(parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE), item.id, item?.account_number);
                      }}
                    >
                      <span>Accept</span>
                    </button>
                  </div>
                </div>
              </div>
            </div> :
            parseInt(item.status) === parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE) ?
              <div className={styles.row}>
                <div className={styles.buttonClass}>
                  <div className={styles.settings}>
                    <div>
                      <button className={cn("button-small", styles.rejectButton)} type="button"
                        onClick={() => {
                          updateStatusHandler(parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT), item.id, item?.account_number);
                        }}
                      >
                        <span>Reject</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              :
              ""
        }
      </div>
    </div>
  );
};

export default Row;
