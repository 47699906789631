import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({
  item,
  botActionHandler,
  setVisibleModal,
  setModalData,
  visibleBotModal,
  setVisibleBotModal,
  botTypeValue,
}) => {

  return (
    <>
      <div className={styles.row} onClick={() => { setVisibleModal(true); setModalData(item) }}>
        <div className={styles.col}>
          {item.id}
        </div>
        <div className={styles.col}>
          {item.uid}
        </div>
        <div className={styles.col}>
          {item.slug}
        </div>
        {/* <div className={styles.col}>
          {item.buy_fee}
        </div>
        <div className={styles.col}>
          {item.sell_fee}
        </div>
        <div className={styles.col}>
          {item?.markets && item?.markets.length > 0 &&
            <>
              {item?.markets?.map((x, index) => {
                return (
                  <div className={styles.cell} key={index}>
                    <div className={styles.box}>
                      <div className={styles.number}>
                        {x.toUpperCase()}
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          }
        </div> */}
        <div className={styles.col}>
          {item.status === parseInt(process.env.REACT_APP_BOT_USER_ACTIVE) ?
            (<span className={cn("status-green-dark", styles.distribution)}>Active</span>) :
            item?.status === parseInt(process.env.REACT_APP_BOT_USER_DEACTIVE) ?
              (<span className={cn("status-red-dark", styles.distribution)}>Pending</span>) : ""}
        </div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(item.createdAt)}
        </div>
        <div className={styles.col}>
          {item.status === parseInt(process.env.REACT_APP_BOT_USER_ACTIVE) ? (
            <button className={cn("button button-small", styles.rejectButton)}
              onClick={(e) => { e.preventDefault(); e.stopPropagation(); botActionHandler(parseInt(process.env.REACT_APP_BOT_USER_DEACTIVE), item?.id) }}>
              <span>Deactivate</span>
            </button>)
            :
            item.status === parseInt(process.env.REACT_APP_BOT_USER_DEACTIVE) ? (
              <button className={cn("button button-small", styles.acceptButton)}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); botActionHandler(parseInt(process.env.REACT_APP_BOT_USER_ACTIVE), item?.id) }}>
                <span>Active</span>
              </button>) : "---"
          }
        </div>
        {botTypeValue === parseInt(process.env.REACT_APP_LIMIT_ORDER_BOT) &&
          <div className={styles.col}>
            <div className={styles.control}>
              <button className={cn("button", styles.button)} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setVisibleBotModal(true);
                setModalData(item);
              }}>
                <span>Recharge Bot</span>
              </button>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default Row;
