import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Cell from "./Cell";
import { disableMarketOrdersWithoutStatusColor, getDigitsAfterDecimal, getMarketStatusWithoutStatusColor, toLocaleStringDateFormat } from "../../../../components/helper";
import { Link } from "react-router-dom";

const Row = ({ item, currency, dragStart, dragEnter, drop, index, updateHistoryHandler }) => {

  function getISODifference(date1, date2) {
    const diffInMs = new Date(date2) - new Date(date1);
    const diffInSeconds = Math.floor(diffInMs / 1000);
    const days = Math.floor(diffInSeconds / (3600 * 24));
    return days;
  };

  return (
    <div className={styles.row} key={index}>
      <div className={styles.col}
        onDragStart={(e) => { dragStart(e, index) }}
        onDragEnter={(e) => { dragEnter(e, index) }}
        onDragEnd={drop}
        draggable
      >
        <div className={styles.item}>
          <div className={styles.details}>
            <div
              className={styles.post}
              dangerouslySetInnerHTML={{ __html: item.slug }}
            >
            </div>
          </div>
        </div>
        <div className={styles.text}><small>{item.name}</small></div>
      </div>
      <div className={styles.col}>
        {disableMarketOrdersWithoutStatusColor(item.disableMarketOrder) === "Yes" ?
          (
            <div className={cn("status-green-dark", styles.distribution)}> Yes </div>
          ) : (
            <div className={cn("status-red-dark", styles.distribution)}> No </div>
          )
        }
      </div>
      <div className={styles.col}>
        {item.currentMarketPrice} <small>{item.slug.split("-")[1]}</small>
        <Cell item={getDigitsAfterDecimal(item.dayChange, 2)} />
      </div>

      <div className={styles.col}>
        {getMarketStatusWithoutStatusColor(item.status) === "Enable" ?
          (<div className={cn("status-green-dark", styles.distribution)}> Enable </div>)
          :
          (<div className={cn("status-red-dark", styles.distribution)}> Disable </div>)
        }
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              Price: {item.priceDecimalPrecision}
            </div>
          </div>
        </div>

        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              Amount: {item.amountDecimalPrecision}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <Link to={{
          pathname: `/trade/market/${item.slug}`,
        }}
          state={item}
        >
          Edit
        </Link>
      </div>
      <div className={styles.col}>
        <div className={styles.details}>{getISODifference(item.updatedAt, new Date().toISOString()) + " days ago"}</div>
      </div>
      <div className={styles.col}>
        <button className={cn("button", styles.button)} onClick={(e) => { updateHistoryHandler(item?.id); }}>
          <span>Update <small>{item.slug}</small></span>
        </button>
      </div>
    </div>
  );
};

export default Row;
