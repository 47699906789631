import React, { useState, useEffect } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import LoaderScreen from "../LoaderScreen";
import Help from "./Help";
import Image from "../Image";
import { useDispatch, useSelector } from "react-redux";
import { userDetailsCreator } from "../../actions/userDetails";

const superAdminNavigation = [
  {
    title: "Dashboard",
    icon: "home",
    url: "/",
  },

  {
    title: "Customers",
    slug: "customers",
    icon: "profile-circle",
    dropdown: [
      {
        title: "Search Customers",
        url: "/customers/search-customers",
      },
      {
        title: "Complete Customers",
        url: "/customers/complete-customers",
      },
      {
        title: "In Review(KYC)",
        url: "/customers/kyc-in-review",
      },
      {
        title: "In Process(KYC)",
        url: "/customers/kyc-in-process",
      },
      {
        title: "Pending Customers",
        url: "/customers/pending-customers",
      },
      {
        title: "Reactivate Customers",
        url: "/customers/reactivate-customers",
      },
      {
        title: "Unverified Customers",
        url: "/customers/unverified-customers",
      },
      {
        title: "Freezed Customers",
        url: "/customers/freezed-customers",
      },
      {
        title: "Generate Monitor KYC Id",
        url: "/customers/generate-monitor-kyc-id",
      },
      {
        title: "Upload GetId Documents",
        url: "/customers/upload-getId-document",
      },
    ],
  },

  {
    title: "Corporate",
    slug: "corporate",
    icon: "profile-circle",
    dropdown: [
      // {
      //   title: "Search Corporate",
      //   url: "/corporate/search-corporate",
      // },

      {
        title: "Complete Corporate",
        url: "/corporate/complete-corporate",
      },
      {
        title: "Pending Corporate",
        url: "/corporate/pending-corporate",
      },
      {
        title: "Unverified Corporate",
        url: "/corporate/unverified-corporate",
      },
      // {
      //   title: "In Review(KYB)",
      //   url: "/corporate/kyc-in-process",
      // },

      {
        title: "Freezed Corporate",
        url: "/corporate/freezed-corporate",
      },
    ],
  },

  {
    title: "Orders Management",
    slug: "order",
    icon: "list",
    dropdown: [
      {
        title: "Search Orders",
        url: "/orders-search",
      },
      {
        title: "Orders",
        url: "/orders-management",
      },
    ],
  },
  {
    title: "Whitelist Address",
    icon: "ticket",
    url: "/whitelist-address",
  },
  // {
  //   title: "SEPA Deposit List",
  //   icon: "payment",
  //   url: "/sepa-deposits",
  // },

  // {
  //   title: "Crypto Deposit List",
  //   slug: "trade",
  //   icon: "pie-chart",
  //   dropdown: [
  //     {
  //       title: "Pending Withdraw",
  //       url: "/crypto-deposits/pending-deposits",
  //     },
  //     {
  //       title: "Approved Withdraw",
  //       url: "/crypto-deposits/approved-deposits",
  //     },
  //   ],
  // },

  {
    title: "Fiat Withdraw",
    icon: "diamond",
    url: "/fiat-withdraw",
  },
  {
    title: "Fiat Deposit",
    icon: "diamond",
    url: "/fiat-deposits",
  },
  {
    title: "Bitdenex Pay",
    icon: "shareNew",
    url: "/bitdenex-pay",
  },

  // {
  //   title: "Miracle Bank List",
  //   icon: "diamond",
  //   url: "/bank-list",
  // },

  {
    title: "Sepa Deposit",
    slug: "sepa",
    icon: "payment",
    dropdown: [
      {
        title: "Pending Deposits",
        url: "/sepa-deposits/pending-deposits",
      },
      {
        title: "Approved Deposits",
        url: "/sepa-deposits/approved-deposits",
      },
    ],
  },
  // {
  //   title: "Sepa Deposit(Miracle)",
  //   slug: "payment",
  //   icon: "payment",
  //   dropdown: [
  //     {
  //       title: "Pending Deposits",
  //       url: "/payment-deposits/pending-deposits",
  //     },
  //     {
  //       title: "Approved Deposits",
  //       url: "/payment-deposits/approved-deposits",
  //     },
  //   ],
  // },
  // {
  //   title: "Fiat Withdraw(Miracle)",
  //   icon: "diamond",
  //   url: "/payment-withdraw",
  // },
  {
    title: "Crypto Deposit",
    icon: "diamond",
    url: "/crypto-deposit",
  },

  {
    title: "Crypto Withdraw",
    slug: "crypto",
    icon: "payment",
    dropdown: [
      {
        title: "Pending Withdraw",
        url: "/crypto-withdraw/pending-withdraw",
      },
      {
        title: "Success Withdraw",
        url: "/crypto-withdraw/success-withdraw",
      },
      {
        title: "Cancel Withdraw",
        url: "/crypto-withdraw/cancel-withdraw",
      },
      {
        title: "Process Withdraw",
        url: "/crypto-withdraw/process-withdraw",
      },
      {
        title: "Declined Withdraw",
        url: "/crypto-withdraw/declined-withdraw",
      },
      {
        title: "Unverified Withdraw",
        url: "/crypto-withdraw/unverified-withdraw",
      },
    ],
  },
  {
    title: "Finance",
    slug: "finance",
    icon: "payment",
    dropdown: [
      {
        title: "Order Module",
        url: "/finance/order-module",
      },
      {
        title: "Crypto Deposit",
        url: "/finance/crypto-deposit",
      },
      {
        title: "Crypto Withdraw",
        url: "/finance/crypto-withdraw",
      },
      {
        title: "Fiat Deposit",
        url: "/finance/fiat-deposit",
      },
      {
        title: "Fiat Withdraw",
        url: "/finance/fiat-withdraw",
      },
    ],
  },
  // {
  //   title: "Miracle Crypto Withdraw",
  //   slug: "miracle",
  //   icon: "payment",
  //   dropdown: [
  //     {
  //       title: "Pending Withdraw",
  //       url: "/miracle-withdraw/pending-withdraw",
  //     },
  //     {
  //       title: "Success Withdraw",
  //       url: "/miracle-withdraw/success-withdraw",
  //     },
  //     {
  //       title: "Cancel Withdraw",
  //       url: "/miracle-withdraw/cancel-withdraw",
  //     },
  //     {
  //       title: "Process Withdraw",
  //       url: "/miracle-withdraw/process-withdraw",
  //     },
  //     // {
  //     //   title: "Declined Withdraw",
  //     //   url: "/miracle-withdraw/declined-withdraw",
  //     // },
  //     {
  //       title: "Unverified Withdraw",
  //       url: "/miracle-withdraw/unverified-withdraw",
  //     },
  //   ],
  // },

  {
    title: "Trade Setup",
    slug: "trade",
    icon: "pie-chart",
    dropdown: [
      {
        title: "Coins",
        url: "/trade/coins",
      },
      {
        title: "Markets",
        url: "/trade/market",
      },
      {
        title: "Coins Network",
        url: "/trade/coins-network",
      },
    ],
  },

  {
    title: "User Management",
    slug: "user",
    icon: "edit",
    dropdown: [
      {
        title: "Create User",
        icon: "add",
        url: "/user-management/create-user",
      },
      {
        title: "User List",
        icon: "add",
        url: "/user-management/user-list",
      },
    ],
  },
  {
    title: "Bots",
    slug: "bots",
    icon: "list",
    dropdown: [
      {
        title: "Active Bots",
        url: "/bots/active-bots",
      },
      {
        title: "Pending Bots",
        url: "/bots/pending-bots",
      },
      {
        title: "Bots Wallet",
        url: "/bots/wallet",
      },
    ],
  },
  {
    title: "Admin Balance Overview",
    slug: "admin",
    icon: "list",
    dropdown: [
      {
        title: "Admin Balance Update",
        url: "/admin-balance-overview/admin-balance-update",
      },
      {
        title: "Admin Balance History",
        url: "/admin-balance-overview/admin-balance-history",
      },
    ],
  },
  {
    title: "Vouchers",
    icon: "list",
    url: "/vouchers",
  },
  {
    title: "Rewards & Level",
    icon: "list",
    url: "/rewards",
  },
  {
    title: "Notification Center",
    icon: "setting",
    url: "/notification-management",
  },
  {
    title: "Transaction Monitoring EU",
    slug: "eu",
    icon: "pie-chart",
    dropdown: [
      {
        title: "TM List",
        url: "/transaction-monitoring-eu",
      },
      {
        title: "TM Values",
        url: "/transaction-monitoring-values-eu",
      },
      {
        title: "Download TM Data",
        url: "/download-transaction-monitoring-eu",
      },
    ],
  },
  {
    title: "Transaction Monitoring INT",
    slug: "int",
    icon: "pie-chart",
    dropdown: [
      {
        title: "TM List",
        url: "/transaction-monitoring-int",
      },
      {
        title: "TM Values",
        url: "/transaction-monitoring-values-int",
      },
      {
        title: "Download TM Data",
        url: "/download-transaction-monitoring-int",
      },
    ],
  },
  // {
  //   title: "Transaction Monitoring SUR",
  //   slug: "transaction",
  //   icon: "pie-chart",
  //   dropdown: [
  //     {
  //       title: "TM List",
  //       url: "/transaction-monitoring-sur",
  //     },
  //     {
  //       title: "TM Values",
  //       url: "/transaction-monitoring-values-sur",
  //     },
  //     {
  //       title: "Download TM Data",
  //       url: "/download-transaction-monitoring-sur",
  //     },
  //   ],
  // },

  // {
  //   title: "Update User Balance",
  //   icon: "list",
  //   url: "/update-user-balance",
  // },
  {
    title: "Onfido Match List Update",
    icon: "list",
    url: "/match-list-update",
  },
  {
    title: "App Banner",
    icon: "list",
    url: "/banner",
  },
  {
    title: "Staking",
    icon: "list",
    url: "/staking",
  },
];

const miracleSuperAdminNavigation = [
  {
    title: "Miracle Bank List",
    icon: "diamond",
    url: "/bank-list",
  },
  {
    title: "Sepa Deposit(Miracle)",
    slug: "payment",
    icon: "payment",
    dropdown: [
      {
        title: "Pending Deposits",
        url: "/payment-deposits/pending-deposits",
      },
      {
        title: "Approved Deposits",
        url: "/payment-deposits/approved-deposits",
      },
    ],
  },
  {
    title: "Fiat Withdraw(Miracle)",
    icon: "diamond",
    url: "/payment-withdraw",
  },
  {
    title: "Miracle Crypto Withdraw",
    slug: "miracle",
    icon: "payment",
    dropdown: [
      {
        title: "Pending Withdraw",
        url: "/miracle-withdraw/pending-withdraw",
      },
      {
        title: "Success Withdraw",
        url: "/miracle-withdraw/success-withdraw",
      },
      {
        title: "Cancel Withdraw",
        url: "/miracle-withdraw/cancel-withdraw",
      },
      {
        title: "Process Withdraw",
        url: "/miracle-withdraw/process-withdraw",
      },
      {
        title: "Unverified Withdraw",
        url: "/miracle-withdraw/unverified-withdraw",
      },
    ],
  },
];

const marketingUserNavigation = [
  {
    title: "Dashboard",
    icon: "home",
    url: "/",
  },

  {
    title: "Customers",
    slug: "customers",
    icon: "profile-circle",
    dropdown: [
      {
        title: "Search Customers",
        url: "/customers/search-customers",
      },
      {
        title: "Complete Customers",
        url: "/customers/complete-customers",
      },
      {
        title: "In Review(KYC)",
        url: "/customers/kyc-in-review",
      },
      {
        title: "In Process(KYC)",
        url: "/customers/kyc-in-process",
      },
      {
        title: "Pending Customers",
        url: "/customers/pending-customers",
      },
      {
        title: "Reactivate Customers",
        url: "/customers/reactivate-customers",
      },
      {
        title: "Unverified Customers",
        url: "/customers/unverified-customers",
      },
      {
        title: "Freezed Customers",
        url: "/customers/freezed-customers",
      },
      // {
      //   title: "Generate Monitor KYC Id",
      //   url: "/customers/generate-monitor-kyc-id",
      // },
      // {
      //   title: "Upload GetId Documents",
      //   url: "/customers/upload-getId-document",
      // },
    ],
  },

  {
    title: "Corporate",
    slug: "corporate",
    icon: "profile-circle",
    dropdown: [
      // {
      //   title: "Search Corporate",
      //   url: "/corporate/search-corporate",
      // },

      {
        title: "Complete Corporate",
        url: "/corporate/complete-corporate",
      },
      {
        title: "Pending Corporate",
        url: "/corporate/pending-corporate",
      },
      {
        title: "Unverified Corporate",
        url: "/corporate/unverified-corporate",
      },
      // {
      //   title: "In Review(KYB)",
      //   url: "/corporate/kyc-in-process",
      // },

      {
        title: "Freezed Corporate",
        url: "/corporate/freezed-corporate",
      },
    ],
  },
  {
    title: "Finance",
    slug: "finance",
    icon: "payment",
    dropdown: [
      {
        title: "Order Module",
        url: "/finance/order-module",
      },
      {
        title: "Crypto Deposit",
        url: "/finance/crypto-deposit",
      },
      {
        title: "Crypto Withdraw",
        url: "/finance/crypto-withdraw",
      },
      {
        title: "Fiat Deposit",
        url: "/finance/fiat-deposit",
      },
      {
        title: "Fiat Withdraw",
        url: "/finance/fiat-withdraw",
      },
    ],
  },
];

const user = [
  {
    title: "Home",
    icon: "home",
    url: "/",
  },

  {
    title: "Walletes Management",
    slug: "wallets-management",
    icon: "pie-chart",
    dropdown: [
      {
        title: "Coins",
        url: "/income/earning",
      },
      {
        title: "ERC 20",
        url: "/income/refunds",
      },
      {
        title: "TRC 20",
        url: "/income/payouts",
      },
      {
        title: "Statements",
        url: "/income/statements",
      },
    ],
  },

  {
    title: "Promote",
    icon: "promotion",
    url: "/promote",
  },
];

const Sidebar = ({ className, onClose, isToggled }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);
  const [menu, setMenu] = useState([]);
  const dispatch = useDispatch();

  const { usersDetails, loading } = useSelector((state) => state.userDetails);

  let superadmin = Object.values(usersDetails).includes(
    process.env.REACT_APP_SUPER_ADMIN_ROLE
  );
  let marketingUser = Object.values(usersDetails).includes(
    process.env.REACT_APP_MARKETEING_USER.toLowerCase()
  );
  let complianceOfficer = Object.values(usersDetails).includes(
    process.env.REACT_APP_COMPLIANCE_OFFICER.toLowerCase()
  );
  let rkmManager = Object.values(usersDetails).includes(
    process.env.REACT_APP_RKM_MANAGER.toLowerCase()
  );
  const storedSideMenu = localStorage.getItem("sideMenu");

  useEffect(() => {
    if (superadmin) {
      if (storedSideMenu && JSON.parse(storedSideMenu) === true) {
        setMenu(superAdminNavigation);
      } else {
        setMenu(miracleSuperAdminNavigation);
      }
    } else if (marketingUser || complianceOfficer || rkmManager) {
      setMenu(marketingUserNavigation);
    }
  }, [usersDetails, storedSideMenu]);

  useEffect(() => {
    if (Object.entries(usersDetails).length === 0) {
      dispatch(userDetailsCreator());
    }
  }, []);

  return (
    <>
      {loading && <LoaderScreen />}
      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        {/* <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button> */}

        {storedSideMenu && JSON.parse(storedSideMenu) === true ? (
          <Link className={styles.logo} to="/" onClick={onClose}>
            <Image
              className={styles.pic}
              src="/images/logo-light.svg"
              srcDark="/images/logo-dark.svg"
              alt="Core"
            />
          </Link>
        ) : (
          <Link className={styles.logo} to="/bank-list" onClick={onClose}>
            <Image
              className={styles.pic}
              src="/images/logo-light.svg"
              srcDark="/images/logo-dark.svg"
              alt="Core"
            />
          </Link>
        )}

        <div className={styles.menu}>
          {
            //superadmin ?
            menu.map((x, index) =>
              x.url ? (
                <NavLink
                  className={styles.item}
                  activeClassName={styles.active}
                  to={x.url}
                  key={index}
                  exact
                  onClick={onClose}
                >
                  <Icon name={x.icon} size="24" />
                  {x.title}
                </NavLink>
              ) : (
                <Dropdown
                  className={styles.dropdown}
                  visibleSidebar={visible}
                  setValue={setVisible}
                  key={index}
                  item={x}
                  onClose={onClose}
                />
              )
            )
            //: ""
          }
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
