import {
    ADD_NEW_MARKET_FAILED,
    ADD_NEW_MARKET_REQUEST,
    ADD_NEW_MARKET_SUCCESS
} from "../actions/addNewMarket/actionType";

const initialState = {
    loading: false,
    error: "",
    newMarketData: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_MARKET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_NEW_MARKET_SUCCESS:
            return {
                ...state,
                loading: false,
                newMarketData: action.payload,
            };
        case ADD_NEW_MARKET_FAILED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state
    };
};

export default reducer;