import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";
import Papa from 'papaparse';

const regionValues = [process.env.REACT_APP_EUROPE_REGION_TEXT.toUpperCase(), process.env.REACT_APP_DEFAULT_REGION_TEXT.toUpperCase()];
const listStatusValues = ["Pending", "Completed"];
const intervalValues = ["Single", "Days"];
const moduleValues = [
  "Fiat Deposit", "Crypto Deposit", "Crypto Withdraw", "Fiat Withdraw", "Fiat to Crypto", "Crypto to Fiat"
];

const Promote = ({ region }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [regionValue, setRegionValue] = useState(regionValues[0]);
  const [listStatus, setListStatus] = useState(listStatusValues[0]);
  const [intervalStatus, setIntervalStatus] = useState(intervalValues[0]);
  const [selectedTransaction, setSelectedTransaction] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [module, setModule] = useState(moduleValues[0]);

  useEffect(() => {
    getAllTransactionHandler();
  }, [listStatus, intervalStatus, module]);

  const getAllTransactionHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: listStatus.toLowerCase() === "pending" ? parseInt(process.env.REACT_APP_TRANSACTION_CHECKED_PENDING) : parseInt(process.env.REACT_APP_TRANSACTION_CHECKED_COMPLETED),
      region: region,
      interval: intervalStatus.toLowerCase() === "days" ? "hour" : "single",
    };
    try {
      const getAllTransactionPayload = await requestHandler("getTrasactionMonitoringList", "post", data, "jwt_token");
      setLoading(false);
      setTransactionData(getAllTransactionPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const downloadFileHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      module: module === "Fiat Deposit" ? process.env.REACT_APP_TRANSACTION_TYPE_FIAT_DEPOSIT_SINGLE
        : module === "Crypto Deposit" ? process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_DEPOSIT_SINGLE
          : module === "Fiat Withdraw" ? process.env.REACT_APP_TRANSACTION_TYPE_FIAT_WITHDRAW_SINGLE
            : module === "Crypto Withdraw" ? process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_WITHDRAW_SINGLE
              : module === "Fiat to Crypto" ? process.env.REACT_APP_TRANSACTION_TYPE_FIAT_TO_CRYPTO_SINGLE
                : module === "Crypto to Fiat" ? process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_TO_FIAT_SINGLE
                  : "",
      status: listStatus.toLowerCase() === "pending" ? parseInt(process.env.REACT_APP_TRANSACTION_CHECKED_PENDING) : parseInt(process.env.REACT_APP_TRANSACTION_CHECKED_COMPLETED),
      region: region,
      interval: intervalStatus.toLowerCase() === "days" ? "hour" : "single",
    };
    try {
      const fileDownloadPayload = await requestHandler("downloadTrasactionMonitoring", "post", data, "jwt_token");
      setLoading(false);

      setCsvData(fileDownloadPayload?.data);
      if (fileDownloadPayload && fileDownloadPayload.status === 200) {
        exportCSV();
      };
    }
    catch (error) {
      addNotification({
        title: "Error",
        message: "Something went wrong",
        type: "danger",
      });
    };
  };

  const exportCSV = () => {
    try {
      const parsedData = Papa.parse(csvData, { header: true }).data;
      if (!parsedData || parsedData.length === 0) {
        throw new Error("No data to export.");
      }
      const csvContent = Papa.unparse(parsedData);
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      addNotification({
        title: "Error",
        message: "Failed to export CSV",
        type: "danger",
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        {loading && <LoaderScreen />}
        <RecentPost
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          transactionData={transactionData}
          loading={loading}
          regionValue={regionValue}
          setRegionValue={setRegionValue}
          regionValues={regionValues}
          listStatus={listStatus}
          setListStatus={setListStatus}
          listStatusValues={listStatusValues}
          intervalValues={intervalValues}
          setIntervalStatus={setIntervalStatus}
          intervalStatus={intervalStatus}
          setSelectedTransaction={setSelectedTransaction}
          selectedTransaction={selectedTransaction}
          downloadFileHandler={downloadFileHandler}
          moduleValues={moduleValues}
          module={module}
          setModule={setModule}
        />
      </div>
    </>
  );
};

export default Promote;