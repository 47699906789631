import React, { useState } from "react";
import styles from "./Row.module.sass";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({ item, orderDetails }) => {

  return (
    <>
      <div className={styles.row} >
        <div className={styles.col}>
          <div className={styles.product}><small>{item.orderId}</small></div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}><small>{item.slug.toUpperCase()}</small></div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) && (
              <div className={styles.positive}><small>Buy</small></div>
            )}
            {(item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) && (
              <div className={styles.negative}><small>Sell</small></div>
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}><small>{getDigitsAfterDecimal(item.price, item.amountDecimalPrecision)} {item?.slug.split("-")[1].toUpperCase()}</small></div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}><small>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)}  <small>{item?.slug.split("-")[0].toUpperCase()} {item.crypto_exe_amount > 0 ? "(P.filled =" + item.crypto_exe_amount + ")" : null}  </small></small></div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}><small>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)}  <small>{item?.slug.split("-")[1].toUpperCase()} {item.fiat_exe_amount > 0 ? "(P.filled =" + item.fiat_exe_amount + ")" : null} </small></small></div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}><small>{item.exeCrypto > 0 ? "(P.filled =" + item.exeCrypto + ")" : 'Open'}</small></div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}><small>{toLocaleStringDateFormat(item.updatedAt)}</small></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
