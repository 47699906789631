import React, { useState } from "react";
import cn from "classnames";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import { addNotification } from "../../../components/Notification";
import LoaderScreen from "../../../components/LoaderScreen";

const Form = ({ className, addCoinNetwork, loading }) => {
  const statusOptions = ["Enable", "Disable"];
  const [status, setStatus] = useState(statusOptions[0]);
  const [networkName, setNetworkName] = useState("");
  const [networkSlug, setNetworkSlug] = useState("");
  const [networkDesc, setNetworkDesc] = useState("");
  const [networkMaxFee, setNetworkMaxFee] = useState();
  const [networkMinFee, setNetworkMinFee] = useState();
  const [fireblockNetworkId, setFireblockNetworkId] = useState("");

  return (
    <Card
      className={cn(styles.card, className)}
    >
      {loading && <LoaderScreen />}
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Fireblock Network Id"
            name="fireblockNetworkId"
            type="text"
            placeholder="Please enter a value"
            value={fireblockNetworkId}
            onChange={(e) => { setFireblockNetworkId(e.target.value) }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Network Name"
            name="name"
            type="text"
            placeholder="Please enter a value"
            value={networkName}
            onChange={(e) => { setNetworkName(e.target.value) }}
            required
          />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Network Slug"
            name="slug"
            type="text"
            placeholder="Please enter a value"
            value={networkSlug}
            onChange={(e) => { setNetworkSlug(e.target.value) }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Description"
            name="description"
            type="text"
            placeholder="Please enter a value"
            value={networkDesc}
            onChange={(e) => { setNetworkDesc(e.target.value) }}
            required
          />
        </div>
      </div>


      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Max Fee"
            name="maxFee"
            type="number"
            placeholder="Please enter a value"
            value={networkMaxFee}
            onChange={(e) => { setNetworkMaxFee(e.target.value) }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Min Fee"
            name="minFee"
            type="number"
            placeholder="Please enter a value"
            value={networkMinFee}
            onChange={(e) => { setNetworkMinFee(e.target.value) }}
            required
          />
        </div>
      </div>
      {/* <div className={styles.description}>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Status"
            value={status}
            setValue={setStatus}
            options={statusOptions}
          />
        </div>
      </div> */}
      <br />
      <button
        className={cn("button", styles.button)}
        onClick={(e) => {
          e.preventDefault();
          if (fireblockNetworkId !== "" && networkName !== "" && networkSlug !== "" && networkDesc !== "" && networkMaxFee >= 0 && networkMinFee >= 0) {
            addCoinNetwork(fireblockNetworkId, networkName, networkSlug, networkDesc, networkMaxFee, networkMinFee, status);
          }
          else {
            addNotification({
              title: 'Error',
              message: "Please fill all the fields and make sure the entered data is valid.",
              type: 'danger'
            });
          }
        }
        }
      >
        Save Changes
      </button>
    </Card >
  );
};

export default Form;
