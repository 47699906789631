import React from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import { getDigitsAfterDecimal } from "../../../../components/helper";

const Share = ({ selectedOrder }) => {

  return (
    <>
      <div className={styles.share} >
        <div className={styles.head}>
          <div className={cn("title-red", styles.title)}>Order Details</div>
        </div>
        {selectedOrder?.order_matching_history && selectedOrder?.order_matching_history?.length > 0 &&
          selectedOrder?.order_matching_history?.map((x, index) =>
            <>
              <div className={styles.customCard} key={index}>
                <div className={styles.wrapper}>
                  <div className={styles.text}>
                    Matched Order Id
                  </div>
                  <div className={styles.col}>
                    {x?.match_orderId}
                  </div>
                </div>
                <div className={styles.wrapper}>
                  <div className={styles.text}>Executed Crypto</div>
                  <div className={styles.col}>
                    {getDigitsAfterDecimal(x?.exeCrypto, selectedOrder?.amountDecimalPrecision)} <small>{selectedOrder?.coin.toUpperCase()}</small>
                  </div>
                </div>
                <div className={styles.wrapper}>
                  <div className={styles.text}>Executed Fiat</div>
                  <div className={styles.col}>
                    {getDigitsAfterDecimal(x?.exeFiat, selectedOrder?.priceDecimalPrecision)} <small>{selectedOrder?.currency.toUpperCase()}</small>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>
    </>
  );
};

export default Share;