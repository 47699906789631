import React from "react";
import BotBalanceHistory from "./BotBalanceHistory";
import styles from "./NewProduct.module.sass";

const BotBalanceHistoryPage = () => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <BotBalanceHistory className={styles.card} />
      </div>
    </div>
  );
};

export default BotBalanceHistoryPage;
