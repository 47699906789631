import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import OrderDropdown from "../../../components/OrderDropdown";
import Row from "./Row";
import { useEffect } from "react";
import requestHandler from "../../../actions/httpClient";
import Form from "../../../components/Form";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { getRegionCreator } from "../../../actions/getRegions";
import Dropdown from "../../../components/Dropdown";

const RecentPost = ({ className, status }) => {
  const dateFilter = process.env.REACT_APP_DATE_FILTER.split(',');
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [approvedCryptoWithdrawList, setApprovedCryptoWithdrawList] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [regionText, setRegionText] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const { getRegionData } = useSelector((state) => state.getRegion);
  const dispatch = useDispatch();
  const [date, setDate] = useState(dateFilter[0]);

  useEffect(() => {
    getApprovedCryptoWithdrawRequest(regionValue);
  }, [pageNo, regionValue, status, date]);

  useEffect(() => {
    if (getRegionData?.length === 0) {
      dispatch(getRegionCreator());
    }
    const newDropDownValue = getRegionData?.map(x => {
      return { "id": x.id, "name": x.name }
    });
    setRegionData([...newDropDownValue]);
    newDropDownValue.filter((x) => {
      if (x?.id === parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE)) {
        setRegionValue(x?.id);
        setRegionText(x?.name);
      }
    })
  }, [getRegionData]);

  const handlePageClick = () => {
    setPageNo(pageNo + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const getApprovedCryptoWithdrawRequest = async (regionValue) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      page: pageNo,
      status: status,
      region: regionValue ? regionValue : parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE),
      interval: date.toLowerCase() === "day" ? parseInt(process.env.REACT_APP_DAILY_DATA) :
        date.toLowerCase() === "week" ? parseInt(process.env.REACT_APP_WEEKLY_DATA) :
          date.toLowerCase() === "month" ? parseInt(process.env.REACT_APP_MONTHLY_DATA) :
            date.toLowerCase() === "year" ? parseInt(process.env.REACT_APP_ANNUALY_DATA) : null
    };
    try {
      const getApprovedCryptoWithdrawPayload = await requestHandler("actionWithdrawCryptoList", "post", data, "jwt_token");
      setLoading(false);
      setApprovedCryptoWithdrawList(getApprovedCryptoWithdrawPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Requests"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.dropdown}>
              <OrderDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={regionValue}
                setValue={setRegionValue}
                text={regionText}
                setText={setRegionText}
                options={regionData}
              />
            </div>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by Customer Id"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={date}
                setValue={setDate}
                options={dateFilter}
                small
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>C Id</div>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>U Id</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Address</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Network Slug</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={8} rowCount={12} />
            :
            approvedCryptoWithdrawList.length > 0 ?
              <>
                {
                  approvedCryptoWithdrawList.filter((data) => { return data.uid.toLowerCase().includes(searchField.toLowerCase()) })
                    .map((x, index) => (
                      <Row item={x} key={index} />
                    ))
                }
                {
                  approvedCryptoWithdrawList.length === (10 * pageNo) && (
                    <div className={styles.foot}>
                      <button className={cn("button-stroke button-small", styles.button)} onClick={() => { handlePageClick() }}>
                        <span>Load more</span>
                      </button>
                    </div>
                  )
                }
              </> :
              "No data found"
          }

        </div>
      </Card>
    </>
  );
};

export default RecentPost;
