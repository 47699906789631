import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";

const Promote = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    getAllNotificationHandler();
  }, []);

  const getAllNotificationHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const getAllNotificationPayload = await requestHandler("getPushNotification", "post", data, "jwt_token");
      setLoading(false);
      setNotificationData(getAllNotificationPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const resendNotificationHandler = async (id) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      notification_id: id,
    };
    try {
      const resendNotificationPayload = await requestHandler("resendPushNotification", "post", data, "jwt_token");
      setLoading(false);
      if (resendNotificationPayload && resendNotificationPayload.status === 200) {
        addNotification({
          title: "Success",
          message: resendNotificationPayload?.data?.message[0]?.msg,
          type: "success",
        });
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message[0]?.msg,
        type: "danger",
      });
    };
  };

  const addNotificationHandler = async (title, message) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      title: title,
      body: message
    };
    try {
      const addNotificationPayload = await requestHandler("pushNotification", "post", data, "jwt_token");
      setLoading(false);
      if (addNotificationPayload && addNotificationPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: addNotificationPayload?.data?.message[0]?.msg,
          type: "success",
        });
        getAllNotificationHandler();
        setVisibleModal(false);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message[0]?.msg,
        type: "danger",
      });
    }
  };

  return (
    <>
      <div className={styles.section}>
        {loading && <LoaderScreen />}
        <RecentPost
          addNotificationHandler={addNotificationHandler}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          notificationData={notificationData}
          loading={loading}
          resendNotificationHandler={resendNotificationHandler}
        />
      </div>
    </>
  );
};

export default Promote;
