import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Form from "../../../components/Form";
import requestHandler from "../../../actions/httpClient";
import { addNotification } from "../../../components/Notification";

const RecentPost = ({ className }) => {
  const [order, setOrder] = useState("DSC");
  const [usersData, setUsersData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);

  useEffect(() => {
    getCustomersWithoutMonitorId();
  }, [pageRefreshFlag]);

  const getCustomersWithoutMonitorId = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const getCustomerWithoutMonitorIdPayload = await requestHandler("getCustomerWithoutMonitorId", "post", data, "jwt_token");
      setLoading(false);
      setUsersData(getCustomerWithoutMonitorIdPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const generateMonitorKycIdHandler = async (applicant_kyc_id, userId) => {
    setLoading(true);
    let data = {
      applicant_kyc_id: applicant_kyc_id,
      userId: userId,
      signature: localStorage.getItem("signature"),
    };
    try {
      const generateMonitorKycIdPayload = await requestHandler("generteMonitorKycId", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      if (generateMonitorKycIdPayload && generateMonitorKycIdPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: generateMonitorKycIdPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    };
  };

  const handleOrder = (e) => {
    const sorted =
      order && order === "ASC"
        ? usersData.sort((a, b) =>
          a["region"] > b["region"] ? 1 : -1
        )
        : usersData.sort((a, b) =>
          a["region"] < b["region"] ? 1 : -1
        );
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setUsersData(sorted);
    setOrder(sortingType);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Customers"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by name, email or customer id"
              type="text"
              name="search"
              icon="search"
            />
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>C Id</div>
            <div className={styles.col}>Customer</div>
            <div className={styles.col}>U Id</div>
            <div className={(styles.col, styles.sortIcon)} onClick={() => handleOrder()}>{order === "DSC" ? "Region ▲" : "Region ▼"}</div>
            <div className={styles.col}>Bank Status</div>
            <div className={styles.col}>KYC Status</div>
            <div className={styles.col}>Residence Status</div>
            <div className={styles.col}>Registration Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={9} rowCount={12} />
            : usersData?.filter((data) => {
              let fullName = data.fname + " " + data.lname;
              return (data.email.toLowerCase().includes(searchField.toLowerCase())) ||
                (data.uid.toLowerCase().includes(searchField.toLowerCase())) ||
                (data.fname !== null && data.fname.toLowerCase().includes(searchField.toLowerCase())) || (data.lname !== null && data.lname.toLowerCase().includes(searchField.toLowerCase())) ||
                ((data.fname !== null && data.lname !== null) && fullName.toLowerCase().includes(searchField.toLowerCase()))
            })
              .map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  order={order}
                  generateMonitorKycIdHandler={generateMonitorKycIdHandler} />
              ))
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
