import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";

// data
import Card from "../../../../components/Card";
import Row from "./Row";

const intervals = ["Last 7 days", "This month", "All time"];

const RecentPost = ({ className, cryptoWithdrawData, updateCryptoWithdrawHandler, superadmin }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Crypto Withdraw"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            {superadmin && <div className={styles.col}>Action</div>}
          </div>
          {cryptoWithdrawData.map((x, index) => (
            <Row
              item={x}
              key={index}
              updateCryptoWithdrawHandler={updateCryptoWithdrawHandler}
              superadmin={superadmin}
            />
          ))}
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
