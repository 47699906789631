import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../../components/helper";

const Row = ({ item, updateCryptoWithdrawHandler }) => {

  return (
    <div className={styles.row}>
      {/* <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.preview}>
            <img srcSet={`${item.image2x} 2x`} src={process.env.REACT_APP_IMAGE_URL + "/" + item?.icon} alt="Coin" />
          </div>
          <div className={styles.details}>
            <div
              className={styles.post}
              dangerouslySetInnerHTML={{ __html: item?.name.toUpperCase() }}
            >
            </div>
            <div className={styles.text}>
              {item?.symbol.toUpperCase()}
            </div>
          </div>
        </div>
      </div> */}
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.preview}>
            <img
              srcSet={`${item.image2x} 2x`}
              src={process.env.REACT_APP_IMAGE_URL + "/" + item?.icon}
              alt="Coin"
            />
          </div>
          <div className={styles.details}>
            <div className={styles.product}>{item.name.toUpperCase()}</div>
            <div className={styles.text}>{item.symbol.toUpperCase()}</div>
          </div>
        </div>
      </div>
      {/* <div className={styles.col}>
        {getDigitsAfterDecimal(item?.amount, item?.decimalPrecision)}
      </div> */}
      <div className={styles.col}>
        <div className={styles.details}>
          {getDigitsAfterDecimal(item?.amount, item?.decimalPrecision)}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.details}>
          <div className={styles.product}>
            {item.address}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.details}>
          {item.address_tag === undefined || item.address_tag === null || item.address_tag === "" ? "---" : item.address_tag}
        </div>
      </div>
      <div className={styles.col}>
        {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_UNVERIFIED) && (
          <div className={cn("status-red-dark", styles.distribution)}>Unverified</div>
        )}
        {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_PENDING) && (
          <div className={cn("status-red-dark", styles.distribution)}>Pending</div>
        )}
        {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO__TRANSACTION_STATUS_PROCESS) && (
          <div className={cn("status-red-dark", styles.distribution)}>Process</div>
        )}
        {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_SUCCESS) && (
          <div className={cn("status-green-dark", styles.distribution)}>Success</div>
        )}
        {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_CANCEL) && (
          <div className={cn("status-red-dark", styles.distribution)}>Cancelled</div>
        )}
        {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_DECLINE) && (
          <div className={cn("status-red-dark", styles.distribution)}>Declined</div>
        )}
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.createdAt)}
      </div>
      <div className={styles.col}>
        <div className={styles.product}>
          {item?.txid ? item?.txid : "---"}
        </div>
      </div>
      {/* <div className={styles.col}>
        <div className={styles.row}>
          <div className={styles.buttonClass}>
            <div className={styles.settings}>
              {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_PENDING) &&
                <div>
                  <button className={cn("button", styles.acceptButton)} type="button"
                    onClick={() => {
                      updateCryptoWithdrawHandler(parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO__TRANSACTION_STATUS_PROCESS), item.id, item.userId);
                    }}
                  >
                    <span>Process</span>
                  </button>
                  <button className={cn("button", styles.rejectButton)} type="button"
                    onClick={() => {
                      updateCryptoWithdrawHandler(parseInt(process.env.REACT_APP_DEPOSIT_CRYPTO_TRANSACTION_STATUS_DECLINE), item.id, item.userId);
                    }}
                  >
                    <span>Decline</span>
                  </button>
                </div>
              }
              {item?.status !== parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_PENDING) &&
                "---"
              }
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
};

export default Row;
