import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import requestHandler from "../../../actions/httpClient";
import Form from "../../../components/Form";
import { addNotification } from "../../../components/Notification";
import { useHistory } from "react-router";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({ className }) => {
  const [userData, setUserData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    getUsersList();
  }, [pageRefreshFlag]);

  const getUsersList = async () => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
      };
      const userPayload = await requestHandler("getUserList", "post", data, "jwt_token");
      setLoading(false);
      setUserData(userPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const updateUserHandler = async (userId, fname, lname, email_verified, kyc_verification, residence_verification) => {
    setLoading(true);
    try {
      let data = {
        userId: userId,
        fname: fname,
        lname: lname,
        email_verified: email_verified,
        kyc_verification: kyc_verification,
        residence_verification: residence_verification,
        signature: localStorage.getItem("signature"),
      };
      const updateUserPayload = await requestHandler("updateUser", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      if (updateUserPayload && updateUserPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateUserPayload?.data?.message[0]?.msg,
          type: 'success'
        });
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Users"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by name, email, role, customer id"
              type="text"
              name="search"
              icon="search"
            />
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Email</div>
            <div className={styles.col}>User Id</div>
            <div className={styles.col}>Role</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={5} rowCount={12} />
            : userData?.filter((data) => {
              let fullName = data.fname + " " + data.lname;
              return (data.email.toLowerCase().includes(searchField.toLowerCase())) ||
                (data.uid.toLowerCase().includes(searchField.toLowerCase())) ||
                (data.role.toLowerCase().includes(searchField.toLowerCase())) ||
                (data.fname !== null && data.fname.toLowerCase().includes(searchField.toLowerCase())) || (data.lname !== null && data.lname.toLowerCase().includes(searchField.toLowerCase())) ||
                ((data.fname !== null && data.lname !== null) && fullName.toLowerCase().includes(searchField.toLowerCase()))
            })
              .map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  updateUserHandler={updateUserHandler}
                />
              ))}
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
