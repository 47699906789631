import React from 'react'
import styles from "./Row.module.sass";
import Icon from '../../../../components/Icon';
import { getDigitsAfterDecimal } from '../../../../components/helper';

const index = ({ item }) => {

    return (
        <div className={styles.list}>
            {/* <div className={styles.name}>{item?.name?.toUpperCase()}</div> */}
            <div className={styles.item}>
                <div
                    className={styles.icon}
                    style={{ backgroundColor: "#B5E4CA" }}
                >
                    <Icon name={"arrows-up-down"} size="24" />
                </div>
                <div className={styles.details}>
                    <div className={styles.label}>
                        Total Volume
                    </div>
                    <div className={styles.counter}>{getDigitsAfterDecimal(item?.amount, 2)} <small>{item?.currency}</small></div>
                </div>
            </div>
            <div className={styles.item}>
                <div
                    className={styles.icon}
                    style={{ backgroundColor: "#CABDFF" }}
                >
                    <Icon name={"arrows-up-down"} size="24" />
                </div>
                <div className={styles.details}>
                    <div className={styles.label}>
                        Total Earnings
                    </div>
                    <div className={styles.counter}>{getDigitsAfterDecimal(item?.profit, 2)} <small>{item?.currency}</small></div>
                </div>
            </div>
            <div className={styles.item}>
                <div
                    className={styles.icon}
                    style={{ backgroundColor: "#FFBC99" }}
                >
                    <Icon name={"arrows-up-down"} size="24" />
                </div>
                <div className={styles.details}>
                    <div className={styles.label}>
                        Total Orders
                    </div>
                    <div className={styles.counter}>{item?.total_request}</div>
                </div>
            </div>
            <div className={styles.item}>
                <div
                    className={styles.icon}
                    style={{ backgroundColor: "#B1E5FC" }}
                >
                    <Icon name={"arrows-up-down"} size="24" />
                </div>
                <div className={styles.details}>
                    <div className={styles.label}>
                        Completed Orders
                    </div>
                    <div className={styles.counter}>{item?.success_request}</div>
                </div>
            </div>
        </div>
    )
};

export default index;
