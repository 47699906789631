import {
    CUSTOMERLIST_FAILED,
    CUSTOMERLIST_REQUEST,
    CUSTOMERLIST_SUCCESS
} from "../actions/customerList/actionType";

const intialState = {
    loading: false,
    err: "",
    usersData: [],
    status: ""
};

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case CUSTOMERLIST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CUSTOMERLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                meta: action.payload?.meta,
                usersData: action.payload?.users,
                status: action.payload
            };

        case CUSTOMERLIST_FAILED:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};


export default reducer;
