import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import CustomModal from "../../../components/CustomModal";
import CustomDropdown from "../../../components/CustomDropdown";
import Row from "./Row";
import Details from "../RecentPost/Row/Details";
import RechargeBot from "../RecentPost/Row/RechargeBot";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";

const RecentPost = ({
  className,
  usersData,
  loading,
  botActionHandler,
  botTypeValue,
  botTypeText,
  setBotTypeText,
  setBotTypeValue,
  handleBotTypeSelection,
  botTypeOtion,
  rechargeBothandler
}) => {
  const [searchField, setSearchField] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [visibleBotModal, setVisibleBotModal] = useState(false);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Bots"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.dropdown}>
              <CustomDropdown
                className={styles.field}
                options={botTypeOtion}
                text={botTypeText}
                setText={setBotTypeText}
                value={botTypeValue}
                setValue={setBotTypeValue}
                handleClick={handleBotTypeSelection}
              />
            </div>
            <div className={styles.control}>
              <Link className={cn("button", styles.button)} to="/bot/add-bot">
                <Icon name="add" size="20" />
                <span>Add Bot</span>
              </Link>
            </div>
          </>
        }
      >
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Id</div>
              <div className={styles.col}>Unique Id</div>
              <div className={(styles.col, styles.sortIcon)}>Slug</div>
              {/* <div className={styles.col}>Buy Fee</div>
              <div className={styles.col}>Sell Fee</div>
              <div className={styles.col}>Markets</div> */}
              <div className={styles.col}>Status</div>
              <div className={styles.col}>Date</div>
              <div className={styles.col}>Action</div>
              {botTypeValue === parseInt(process.env.REACT_APP_LIMIT_ORDER_BOT) && <div className={styles.col}>Recharge</div>}
            </div>
            {loading ? <CustomerListSkeleton colCount={7} rowCount={12} />
              : usersData?.filter((data) => {
                return (data.uid.toLowerCase().includes(searchField.toLowerCase())) ||
                  (data.fname !== null && data.fname.toLowerCase().includes(searchField.toLowerCase())) || (data.lname !== null && data.lname.toLowerCase().includes(searchField.toLowerCase()))
              })
                .map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                    botActionHandler={botActionHandler}
                    setVisibleModal={setVisibleModal}
                    setModalData={setModalData}
                    setVisibleBotModal={setVisibleBotModal}
                    visibleBotModal={visibleBotModal}
                    botTypeValue={botTypeValue}
                  />
                ))
            }
          </div>
        </div>
      </Card>
      <CustomModal
        visible={visibleModal}
        onClose={() => { setVisibleModal(false) }}
      >
        <Details botTypeValue={botTypeValue} item={modalData} />
      </CustomModal>
      <CustomModal
        visible={visibleBotModal}
        onClose={() => { setVisibleBotModal(false) }}
      >
        <RechargeBot
          setVisibleBotModal={setVisibleBotModal}
          item={modalData}
          rechargeBothandler={rechargeBothandler}
          loading={loading}
        />
      </CustomModal>
    </>
  );
};

export default RecentPost;
