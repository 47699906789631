import styles from "./Details.module.sass";
import cn from "classnames";

const DirectorDetails = ({ item }) => {

  return (
    <>
      <div className={styles.share}>
        <div className={styles.head}>
          <div className={cn("title-red", styles.title)}>Details</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>
            Applicant Kyc Id
          </div>
          <div className={styles.col}>
            {item?.applicant_kyc_id}
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>
            Monitor Kyc Id
          </div>
          <div className={styles.col}>
            {item?.monitor_kyc_id ? item?.monitor_kyc_id : "---"}
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>
            Workflowrun Kyc Id
          </div>
          <div className={styles.col}>
            {item?.workflowrun_kyc_id ? item?.workflowrun_kyc_id : "---"}
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectorDetails;
