import React, { useState } from "react";
import cn from "classnames";
import styles from "./Refunds.module.sass";
import Card from "../../components/Card";
import Row from "./Row";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import CustomerListSkeleton from "../../components/Skeleton/CutomerListSkeleton";
import Modal from "../../components/Modal";
import UpdateCoin from "./Row/UpdateCoin"
import { addNotification } from "../../components/Notification";

const Refunds = () => {
  const [visible, setVisible] = useState(false);
  const [coinsNetwork, setCoinsNetwork] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [coinNetworkData, setCoinsNetworkData] = useState([]);

  useEffect(() => {
    getCoinsNetwork();
  }, []);

  const getCoinsNetwork = async () => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
      };
      const coinsNetworkPayload = await requestHandler("get_coin_network", "post", data, "jwt_token");
      setLoading(false);
      setCoinsNetwork(coinsNetworkPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const updateCoinNetworkHandler = async (id, maxFee, minFee) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      minFee: parseInt(minFee),
      maxFee: parseInt(maxFee),
      id: id
    };
    try {
      const updateCoinNetworkPayload = await requestHandler("update_coin_network", "post", data, "jwt_token");
      setLoading(false);
      if (updateCoinNetworkPayload && updateCoinNetworkPayload.status === 200) {
        addNotification({
          title: "Success",
          message: updateCoinNetworkPayload?.data.message[0].msg,
          type: "success"
        });
        setVisibleModal(false);
        getCoinsNetwork();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Success",
        message: e?.data.message[0].msg,
        type: "success"
      });
    };
  };

  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title="Coins Network"
      classTitle={cn("title-purple", styles.title)}
      head={
        <div className={styles.control} onClick={() => setVisible(false)}>
          <Link className={cn("button", styles.button)} to="/trade/coins-network/add-coin-network">
            <Icon name="add" size="24" />
            <span>Add Coin Network</span>
          </Link>
        </div>
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Id</div>
            <div className={styles.col}>Network Name</div>
            <div className={styles.col}>Network Slug</div>
            <div className={styles.col}>Fireblock Id</div>
            <div className={styles.col}>Description</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Max Fee</div>
            <div className={styles.col}>Min Fee</div>
            <div className={styles.col}>Updated at</div>
            <div className={styles.col}>Action</div>
          </div>
          {/* {loading && <LoaderScreen />} */}
          {loading ? <CustomerListSkeleton colCount={10} rowCount={12} />
            : coinsNetwork.map((x, index) => (
              <Row
                item={x}
                key={index}
                setVisibleModal={setVisibleModal}
                setCoinsNetworkData={setCoinsNetworkData}
              />
            ))}
        </div>
      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <UpdateCoin
          coinNetworkData={coinNetworkData}
          updateCoinNetworkHandler={updateCoinNetworkHandler}
        />
      </Modal>
    </Card>
  );
};

export default Refunds;
