import React, { useRef, useState } from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import TextInput from "../../../../components/TextInput";
import LoaderScreen from "../../../../components/LoaderScreen";
import SimpleReactValidator from "simple-react-validator";

const NewPost = ({
  loading,
  timeInterval,
  addStakeHandler
}) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [stakeAmount, setStakeAmount] = useState("");
  const [percentage, setPercentage] = useState("");
  const [tier, setTier] = useState("");
  const [penalty, setPenalty] = useState("");

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.post}>
        <div className={styles.details}>
          <div className={cn("title-purple", styles.title)}>Add Stake</div>
          <div className={styles.description}>
            <TextInput
              className={styles.field}
              label="Stake Amount"
              name="stakeAmount"
              type="text"
              value={stakeAmount}
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                if (regex.test(e.target.value) || e.target.value === '') {
                  setStakeAmount(e.target.value)
                }
              }}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("stakeAmount")}
            />
            <span className={styles.danger}>
              {simpleValidator.current.message("stakeAmount", stakeAmount, "required")}
            </span>
          </div>
          <div className={styles.description}>
            <TextInput
              className={styles.field}
              label="APY(Annual percentage yield)"
              name="apy"
              type="text"
              value={percentage}
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                if (regex.test(e.target.value) || e.target.value === '') {
                  setPercentage(e.target.value)
                }
              }}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("apy")}
            />
            <span className={styles.danger}>
              {simpleValidator.current.message("apy", percentage, "required")}
            </span>
          </div>
          <div className={styles.description}>
            <TextInput
              className={styles.field}
              label="Tier"
              name="tier"
              type="text"
              value={tier}
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                if (regex.test(e.target.value) || e.target.value === '') {
                  setTier(e.target.value)
                }
              }}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("tier")}
            />
            <span className={styles.danger}>
              {simpleValidator.current.message("tier", tier, "required")}
            </span>
          </div>
          <div className={styles.description}>
            <TextInput
              className={styles.field}
              label="Penalty"
              name="penalty"
              type="text"
              value={penalty}
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                if (regex.test(e.target.value) || e.target.value === '') {
                  setPenalty(e.target.value)
                }
              }}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("penalty")}
            />
            <span className={styles.danger}>
              {simpleValidator.current.message("penalty", penalty, "required")}
            </span>
          </div>

          <div className={styles.control}>
            <button className={cn("button", styles.button)} onClick={(e) => {
              e.preventDefault();
              if (simpleValidator.current.allValid()) {
                addStakeHandler(stakeAmount, percentage, tier, penalty);
              } else {
                simpleValidator.current.showMessages();
                forceUpdate(1);
              }
            }
            }>
              <span>Continue</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPost;
