import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";
import Balance from "../../../../components/Balance";

const Row = ({
  item,
  value,
  onChange,
  activeTable,
  setActiveTable,
  activeId,
  setActiveId,
  bankDetails,
  updateUserBankHandler,
  profileInfo,
  superadmin
}) => {

  const handleClick = (id) => {
    setActiveTable(true);
    setActiveId(id);
  };

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div className={styles.col}>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            {item.account_number}
          </div>
        </div>
        <div className={styles.col}>
          {item.bank_name}
        </div>
        <div className={styles.col}>
          {/* <div className={styles.image} onClick={() => window.open(process.env.REACT_APP_IMAGE_URL + "/" + item.bankProof, '_blank')}> */}
          {/* <img
              className={styles.customImagesClass}
              src={process.env.REACT_APP_IMAGE_URL + "/" + item.bankProof}
              alt="Proof"
            /> */}
          {profileInfo?.olduser === "N" ?
            <a href={process.env.REACT_APP_IMAGE_URL + "/" + item.bankProof} target="_blank">Click here</a>
            :
            <a href={process.env.REACT_APP_IMAGE_URL_OLD + "/" + item.bankProof} target="_blank">Click here</a>
          }
          {/* </div> */}
        </div>
        <div className={styles.col}>
          <div className={styles.lifetime}>
            <div className={styles.price}>{item.payment_mode}</div>
          </div>
        </div>
        <div className={styles.col}>
          {item.status === parseInt(process.env.REACT_APP_BANKVERIFICATIONINPROCESS) ? <span>In-process</span> :
            item.status === parseInt(process.env.REACT_APP_BANKVERIFICATIONPENDING) ? <span style={{ color: "orange" }}>Pending</span> :
              item.status === parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE) ? <span style={{ color: "green" }}>Completed</span> :
                item.status === parseInt(process.env.REACT_APP_BANKVERIFICATIONFAILURE) ? <span>Failed</span> :
                  item.status === parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT) ? <span style={{ color: "red" }}>Rejected</span> :
                    "---"
          }
        </div>
        {superadmin &&
          <div className={styles.col}>
            {item.status === parseInt(process.env.REACT_APP_BANKVERIFICATIONPENDING) ?
              <div className={styles.row}>
                <div className={styles.buttonClass}>
                  <div className={styles.settings}>
                    <div>
                      <button className={cn("button-small", styles.acceptButton)} type="button"
                        onClick={() => {
                          updateUserBankHandler(item.id, parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE));
                        }}
                      >
                        <span>Accept</span>
                      </button>
                      <button className={cn("button-small", styles.rejectButton)} type="button"
                        onClick={() => {
                          updateUserBankHandler(item.id, parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT));
                        }}
                      >
                        <span>Reject</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              :
              item.status === parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT) ?
                <div className={styles.row}>
                  <div className={styles.buttonClass}>
                    <div className={styles.settings}>
                      <div>
                        <button className={cn("button-small", styles.acceptButton)} type="button"
                          onClick={() => {
                            updateUserBankHandler(item.id, parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE));
                          }}
                        >
                          <span>Accept</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> :
                item.status === parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE) ?
                  <div className={styles.row}>
                    <div className={styles.buttonClass}>
                      <div className={styles.settings}>
                        <div>
                          <button className={cn("button-small", styles.rejectButton)} type="button"
                            onClick={() => {
                              updateUserBankHandler(item.id, parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT));
                            }}
                          >
                            <span>Reject</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  ""
            }
            {/* {item.status === parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE) &&
          <div className={styles.row}>
            <div className={styles.buttonClass}>
              <div className={styles.settings}>
                <div>
                  <button className={cn("button", styles.rejectButton)} type="button"
                    onClick={() => {
                      updateUserBankHandler(item.id, parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT));
                    }}
                  >
                    <span>Reject</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        } */}
          </div>}

      </div>
    </>
  );
};

export default Row;
