import React, { useState } from "react";
import styles from "./Row.module.sass";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";
import CustomModal from '../../../../components/CustomModal'
import Details from '../Details'
const Row = ({ item, orderDetails }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  return (
    <>
      <div className={styles.row} onClick={() => { setVisibleModal(true); setSelectedItem(item) }}>
        <div className={styles.col}>
          <div className={styles.product}><small>{item.orderId}</small></div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}><small>{item.slug.toUpperCase()}</small></div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) && (
              <div className={styles.positive}><small>Buy</small></div>
            )}
            {(item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) && (
              <div className={styles.negative}><small>Sell</small></div>
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}><small>{getDigitsAfterDecimal(item.price, item.priceDecimalPrecision)} {item?.currency?.toUpperCase()}</small></div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>
              {item?.status === parseInt(process.env.REACT_APP_CANCELLED_ORDERS) ?
                <small>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)} {item?.coin?.toUpperCase()} {item.exeCrypto > 0 ? "(P.filled =" + item.exeCrypto + ")" : null}</small>
                :
                <small>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)} {item?.coin?.toUpperCase()}</small>
              }
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>
              {item?.status === parseInt(process.env.REACT_APP_CANCELLED_ORDERS) ?
                <small>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)} {item?.currency?.toUpperCase()} {item.exeFiat > 0 ? "(P.filled =" + item.exeFiat + ")" : null}</small>
                :
                <small>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)} {item?.currency?.toUpperCase()}</small>
              }
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}><small>{toLocaleStringDateFormat(item.updatedAt)}</small></div>
          </div>
        </div>
      </div>
      <CustomModal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Details item={selectedItem} />
      </CustomModal>
    </>
  );
};

export default Row;
