import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import TextInput from "../../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import Editor from "../../../../../components/Editor";

const Share = ({ addNotificationHandler }) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  return (
    <>
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Add Notification</div>
        <div className={styles.description}>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Title"
              name="title"
              type="text"
              value={title}
              onChange={(e) => {
                if (e.target.value.length <= 100) {
                  setTitle(e.target.value);
                }
              }
              }
              placeholder="Please enter a value (100 letters allowed)"
              required
              onBlur={() => simpleValidator.current.showMessageFor("title")}
            />
            <span className={styles.errorMessage}>
              {simpleValidator.current.message("title", title, "required")}
            </span>
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Message"
              name="message"
              type="text"
              onChange={(e) => {
                if (e.target.value.length <= 500) {
                  setMessage(e.target.value)
                }
              }
              }
              placeholder="Please enter a value (500 letters allowed)"
              required
              onBlur={() => simpleValidator.current.showMessageFor("message")}
            />
            <span className={styles.errorMessage}>
              {simpleValidator.current.message("message", message, "required")}
            </span>
          </div>
        </div>
        <div className={styles.control}>
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              addNotificationHandler(title, message);
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }
          }>
            <span>Continue</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Share;
