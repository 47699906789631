import React, { useState } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import { addNotification } from "../../../../../components/Notification";
import Icon from "../../../../../components/Icon";

const Details = ({ item, walletDetails }) => {

  return (

    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>{item.symbol.toUpperCase()} Wallet Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              <img className={styles.img} src={process.env.REACT_APP_IMAGE_URL + "/" + item.image} alt="Coin" />
            </div>
            <div className={styles.details}>
              <div
                className={styles.post}
                dangerouslySetInnerHTML={{ __html: item.name.toUpperCase() }}
              ></div>
              <div className={styles.text}>
                {item.symbol}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.item}>
          {Object.keys(item?.address).length > 0 &&
            Object.entries(item?.address).map((x, index) => (
              <div className={styles.wrapper} key={index}>
                <div className={styles.col} title={x[1]}>
                  {x[0].toUpperCase() + " - " + x[1]}
                  <button className={styles.copy} onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(x[1]).then(() => {
                      addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                    })
                  }}>
                    <Icon name="copy" size="18" />
                  </button>
                </div>
                <br />
              </div>
            ))}
        </div>
      </div>

    </div>
  );
};

export default Details;
