import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({ item,
  setUpdateRewardModalVisible,
  setRewardData
}) => {

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {item?.level}
        </div>
        <div className={styles.col}>
          {item?.name}
        </div>
        <div className={styles.col}>
          {item?.min_invites}
        </div>
        <div className={styles.col}>
          {item?.max_invites}
        </div>
        <div className={styles.col}>
          {item?.min_volume}
        </div>
        <div className={styles.col}>
          {item?.max_volume}
        </div>
        <div className={styles.col}>
          {item?.percentage}
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            {item.status === 1 && (<div className={cn("status-green-dark", styles.distribution)}>Enabled</div>)}
            {item.status === 0 && (<div className={cn("status-red-dark", styles.distribution)}>Disabled</div>)}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            {toLocaleStringDateFormat(item?.updatedAt)}
          </div>
        </div>
        <div className={styles.col}>
          <button className={cn("button", styles.button)} onClick={() => {
            setUpdateRewardModalVisible(true);
            setRewardData(item);
          }
          }>
            <span>Update</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Row;
