import React, { useState } from "react";
import styles from "./NewProduct.module.sass";
import Form from "./Form";
import requestHandler from "../../actions/httpClient";
import { useNavigate } from "react-router";
import { addNotification } from "../../components/Notification";

const NewProduct = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const addCoinNetwork = async (fireblockNetworkId, networkName, networkSlug, networkDesc, networkMaxFee, networkMinFee, status) => {
    setLoading(true);
    let data = {
      fireblock_network_id: fireblockNetworkId,
      network_name: networkName,
      network_slug: networkSlug,
      description: networkDesc,
      maxFee: networkMaxFee,
      minFee: networkMinFee,
      status: status.toLowerCase() === "enable" ? 1 : 0,
      signature: localStorage.getItem("signature"),
    };
    try {
      const addCoinNetworkPayload = await requestHandler("new_coin_network", "post", data, "jwt_token");
      setLoading(false);
      if (addCoinNetworkPayload && addCoinNetworkPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: addCoinNetworkPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
        navigate("/trade/coins-network");
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    }
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Form
            loading={loading}
            addCoinNetwork={addCoinNetwork}
            className={styles.card} />
        </div>
      </div>
    </>
  );
};

export default NewProduct;
