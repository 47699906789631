import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({ item,
  setUpdateCoupanModalVisible,
  updateCoupanModalVisible,
  setVoucherData
}) => {

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              <img src={item?.image} alt="coupon" />
            </div>
            <div className={styles.details}>
              <div
                className={styles.post}
                dangerouslySetInnerHTML={{ __html: item.name.toUpperCase() }}
              ></div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          {item?.amount}
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            {item.status === parseInt(process.env.REACT_APP_COUPON_ENABLE) && (<div className={cn("status-green-dark", styles.distribution)}>Enabled</div>)}
            {item.status === parseInt(process.env.REACT_APP_COUPON_DISBALE) && (<div className={cn("status-red-dark", styles.distribution)}>Disabled</div>)}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            {item?.validity}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            {item?.validity_after_activate}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            {toLocaleStringDateFormat(item?.updatedAt)}
          </div>
        </div>
        <div className={styles.col}>
          <button className={cn("button", styles.button)} onClick={() => {
            setUpdateCoupanModalVisible(true);
            setVoucherData(item);
          }
          }>
            <span>Update</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Row;
