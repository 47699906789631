import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";
import Modal from "../../../../components/Modal";
import Form from "./Form";

const Row = ({ item,
  uploadDocumentsHandler,
  loading,
  documents,
  status,
  approveCorporateAccountHandler,
  directors,
  superadmin
}) => {
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {item.id}
        </div>
        <Link className={styles.col}
          to={{
            pathname: `/corporate-profile/${item.id}`,
            state: item,
            document: documents,
            director: directors,
          }}>
          {item.fname === null || item.fname === undefined ? "---" : item.fname}
          <div className={styles.text}>
            {item.email}
          </div>
        </Link>

        <div className={styles.col}>
          {item.uid}
        </div>
        <div className={styles.col}>
          {item.region == parseInt(process.env.REACT_APP_INTERNATIONAL_REGION_VALUE) ? "International" :
            item.region === parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE) ? "Europe" :
              ""}
        </div>

        <div className={styles.col}>
          {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONINPROCESS) && (
            <div className={cn("status-red-dark", styles.distribution)}>
              In-process
            </div>
          )}

          {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONPENDING) && (
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
          )}

          {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE) && (
            <div className={cn("status-green-dark", styles.distribution)}>
              Completed
            </div>
          )}

          {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONFAILURE) && (
            <div className={cn("status-red-dark", styles.distribution)}>
              Failed
            </div>
          )}

          {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT) && (
            <div className={cn("status-red-dark", styles.distribution)}>
              Rejected
            </div>
          )}
        </div>

        <div className={styles.col}>
          {item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONUSEREXIT) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                User Exit
              </div>
              :
              item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONINPROCESS) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  In-process
                </div>
                :
                item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONONERROR) ?
                  <div className={cn("status-red-dark", styles.distribution)}>
                    Token Expired
                  </div>
                  :
                  item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE) ?
                    <div className={cn("status-green-dark", styles.distribution)}>
                      Completed
                    </div>
                    :
                    "---"
          }
        </div>
        <div className={styles.col}>
          {item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_PENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_INPROCESS) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                In-process
              </div>
              :
              item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) ?
                <div className={cn("status-green-dark", styles.distribution)}>
                  Completed
                </div>
                :
                item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) ?
                  <div className={cn("status-red-dark", styles.distribution)}>
                    Rejected
                  </div>
                  :
                  "---"
          }
        </div>
        <div className={styles.col}>
          {item.createdAt}
        </div>
        <div className={styles.col}>
          {item.risk_profile === parseInt(process.env.REACT_APP_RISK_PROFILE_LOW) ? <span className={cn("status-green-dark", styles.distribution)}>Low</span> :
            item.risk_profile === parseInt(process.env.REACT_APP_RISK_PROFILE_MEDIUM) ? <span className={cn("status-yellow", styles.distribution)}>Medium</span> :
              item.risk_profile === parseInt(process.env.REACT_APP_RISK_PROFILE_HIGH) ? <span className={cn("status-red-dark", styles.distribution)}>High</span> :
                "---"
          }
        </div>
        {superadmin && <div className={styles.col}>
          <button className={cn("button button-small")}
            onClick={(e) => {
              e.stopPropagation();
              setVisibleModal(true);
            }}>
            <span>Upload Document</span>
          </button>
        </div>}

        {status === process.env.REACT_APP_KYCVERIFICATIONPENDINGSTRING && (
          <div className={styles.col}>
            <button className={cn("button button-small")}
              onClick={(e) => {
                e.stopPropagation();
                approveCorporateAccountHandler(item?.id);
              }}>
              <span>Approve</span>
            </button>
          </div>
        )}

      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Form
          item={item}
          uploadDocumentsHandler={uploadDocumentsHandler}
          loading={loading}
        />
      </Modal>
    </>
  );
};

export default Row;
