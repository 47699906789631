import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import requestHandler from "../../../actions/httpClient";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Icon from "../../../components/Icon";
import CreateVoucher from "./Row/CreateVoucher";
import UpdateVoucher from "./Row/UpdateVoucher";

const RecentPost = ({ className }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [updateCoupanModalVisible, setUpdateCoupanModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [voucherList, setVoucherList] = useState([]);
  const [voucherData, setVoucherData] = useState([]);

  useEffect(() => {
    getVoucherList();
  }, []);

  const getVoucherList = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const getVoucherPayload = await requestHandler("getReferralCoupan", "post", data, "jwt_token");
      setVoucherList(getVoucherPayload?.data?.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recently Added"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.control}>
              <button className={cn("button", styles.button)} onClick={() => setVisibleModal(true)}>
                <Icon name="add" size="20" />
                <span>Add Voucher</span>
              </button>
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Voucher</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Validity</div>
            <div className={styles.col}>Validity after activation</div>
            <div className={styles.col}>Last Updated</div>
            <div className={styles.col}>Edit</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={7} rowCount={12} />
            :
            voucherList.length > 0 ?
              <>
                {
                  voucherList?.map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      setVoucherData={setVoucherData}
                      setUpdateCoupanModalVisible={setUpdateCoupanModalVisible}
                      updateCoupanModalVisible={updateCoupanModalVisible}
                    />
                  ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <CreateVoucher
          loading={loading}
          setLoading={setLoading}
          setVisibleModal={setVisibleModal}
          getVoucherList={getVoucherList}
        />
      </Modal>

      <Modal
        outerClassName={styles.outer}
        visible={updateCoupanModalVisible}
        onClose={() => setUpdateCoupanModalVisible(false)}
      >
        <UpdateVoucher
          loading={loading}
          setLoading={setLoading}
          item={voucherData}
          setUpdateCoupanModalVisible={setUpdateCoupanModalVisible}
          getVoucherList={getVoucherList}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
