import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({ item, updateStatusHandler }) => {

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {item?.user_id}
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.box}>
              <div className={styles.number}>
                {item?.account_no}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          {getDigitsAfterDecimal(item?.amount, 2)}
          <div className={styles.text}>
            {item?.currency}
          </div>
        </div>
        <div className={styles.col}>
          {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PENDING) && (
            <div className={cn("status-red-dark", styles.distribution)}>Pending</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_APPROVED) && (
            <div className={cn("status-green-dark", styles.distribution)}>Success</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_CANCELLED) && (
            <div className={cn("status-red-dark", styles.distribution)}>Cancelled</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_DECLINED) && (
            <div className={cn("status-red-dark", styles.distribution)}>Declined</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PROCESS) && (
            <div className={cn("status-red-dark", styles.distribution)}>In Process</div>
          )}
          {item?.status === null || item?.status === undefined && "---"}
        </div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(item?.createdAt)}
        </div>
        <div className={styles.col}>
          {item.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PENDING) &&
            <div className={styles.row}>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <button className={cn("button", styles.acceptButton)} type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      updateStatusHandler(parseInt(process.env.REACT_APP_FIAT_WITHDRAW_APPROVED), item?.id)
                    }}
                  >
                    <span>Accept</span>
                  </button>
                  <button className={cn("button", styles.rejectButton)} type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      updateStatusHandler(parseInt(process.env.REACT_APP_FIAT_WITHDRAW_DECLINED), item?.id)
                    }}
                  >
                    <span>Reject</span>
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default Row;
