import React from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Row from "./Row"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Overview = ({
  className,
  regionValues,
  date,
  setDate,
  dateFilter,
  setRegionValue,
  regionValue,
  financeData,
  fromDate,
  toDate,
  handleToDateChange,
  handleFromDateChange,
  currentDate
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Finance"
        classTitle="title-red"
        head={
          <>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={regionValue}
              setValue={setRegionValue}
              options={regionValues}
              small
            />
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={date}
                setValue={setDate}
                options={dateFilter}
                small
              />
            </div>
            {
              date.toLowerCase() === "custom" &&
              <>
                <div className={styles.datePickerContainer}>
                  <label className={styles.datePickerLabel}></label>
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => handleFromDateChange(date)}
                    className={styles.customDatePicker}
                    maxDate={currentDate}
                  />
                </div>
                <div className={styles.dropdown}>
                  <label className={styles.datePickerLabel}></label>
                  <DatePicker
                    selected={toDate}
                    onChange={(date) => handleToDateChange(date)}
                    maxDate={currentDate}
                    className={styles.customDatePicker}
                  />
                </div>
              </>
            }
          </>
        }
      >
        <div className={styles.table}>
          {financeData?.map((x, index) => (
            <Row item={x} key={index} />
          ))}
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
