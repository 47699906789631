import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item?.firstName == null || item?.firstName == undefined ? "---" : item?.firstName}
      </div>
      <div className={styles.col}>
        {item?.lastName == null || item?.lastName == undefined ? "---" : item?.lastName}
      </div>
      <div className={styles.col}>
        <div className={styles.text}>
          {item?.uid}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.text}>
          {item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONUSEREXIT) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                User Exit
              </div>
              :
              item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONINPROCESS) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  In-process
                </div>
                :
                item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONONERROR) ?
                  <div className={cn("status-red-dark", styles.distribution)}>
                    Token Expired
                  </div>
                  :
                  item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE) ?
                    <div className={cn("status-green-dark", styles.distribution)}>
                      Completed
                    </div>
                    :
                    "---"
          }
        </div>
      </div>
    </div>
  );
};

export default Row;
