import React, { useEffect, useState } from "react";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { useDispatch, useSelector } from "react-redux";
import { getRegionCreator } from "../../actions/getRegions";
import { addNotification } from "../../components/Notification";
import { userDetailsCreator } from "../../actions/userDetails";

// const usersData = [
//   {
//     bank_account_count: 0,
//     bank_verification: 3,
//     country_code: "31",
//     createdAt: "2023-03-24",
//     email: "nxtgenvirtue1@gmail.com",
//     fname: "Business",
//     id: 78,
//     kyc_verification: 5,
//     lname: "one",
//     mobile_no: "9494990292",
//     monitor_kyc_id: "21d49822-7e3c-4f0c-97d0-222884c777be",
//     region: 2,
//     residence_verification: 2,
//     risk_profile: 1,
//     uid: "326904908035",
//     type: "pvt ltd.",
//     role: "Corporate",
//     user_level: 1,
//     fireBlock_vault_id: 12,
//     email_verified: 1,
//   },
//   {
//     bank_account_count: 0,
//     bank_verification: 3,
//     country_code: null,
//     createdAt: "2023-03-15",
//     email: "nxtgenvirtue2@gmail.com",
//     fname: "Business",
//     id: 75,
//     kyc_verification: 5,
//     lname: "Two",
//     mobile_no: null,
//     monitor_kyc_id: null,
//     region: 2,
//     residence_verification: 1,
//     risk_profile: 1,
//     uid: "653546958442",
//     type: "pvt ltd.",
//     role: "Corporate",
//     user_level: 1,
//     email_verified: 1,
//     fireBlock_vault_id: 13,
//   },
//   {
//     bank_account_count: 0,
//     bank_verification: 3,
//     country_code: null,
//     createdAt: "2023-03-07",
//     email: "nxtgenvirtue3@gmail.com",
//     fname: "Business",
//     id: 67,
//     kyc_verification: 5,
//     lname: "Three",
//     mobile_no: "9988501191",
//     monitor_kyc_id: "7bf8f0ec-d68f-4e9c-99ae-4ec0e91bd2c5",
//     region: 2,
//     residence_verification: 3,
//     risk_profile: 1,
//     uid: "814903705424",
//     type: "pvt ltd.",
//     role: "Corporate",
//     user_level: 1,
//     email_verified: 1,
//     fireBlock_vault_id: 14,
//   },
//   {
//     bank_account_count: 0,
//     bank_verification: 3,
//     country_code: "60",
//     createdAt: "2023-02-03",
//     email: "nxtgenvirtue4@gmail.com",
//     fname: "Business",
//     id: 23,
//     kyc_verification: 5,
//     lname: "Four",
//     mobile_no: "8168637394",
//     monitor_kyc_id: "this is test monitor kyc id",
//     region: 2,
//     residence_verification: 2,
//     risk_profile: 3,
//     uid: "535279904494",
//     type: "pvt ltd.",
//     role: "Corporate",
//     user_level: 1,
//     email_verified: 1,
//     fireBlock_vault_id: 15,
//   },
//   {
//     bank_account_count: 0,
//     bank_verification: 3,
//     country_code: "1",
//     createdAt: "2023-02-01",
//     email: "nxtgenvirtue5@gmail.com",
//     fname: "Business",
//     id: 13,
//     kyc_verification: 5,
//     lname: "Five",
//     mobile_no: "85888888858",
//     monitor_kyc_id: null,
//     region: 2,
//     residence_verification: 3,
//     risk_profile: 2,
//     uid: "388942522507",
//     type: "pvt ltd.",
//     fireBlock_vault_id: 16,
//     role: "Corporate",
//     email_verified: 1,
//     user_level: 1,
//   }
// ]

const Promote = ({ status }) => {
  const [loading, setLoading] = useState(false);
  const [usersData, setUserData] = useState([]);
  const { getRegionData } = useSelector((state) => state.getRegion);
  const dispatch = useDispatch();
  const [regionData, setRegionData] = useState([]);
  const [regionText, setRegionText] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);
  const { usersDetails } = useSelector((state) => state.userDetails);
  let superadmin = Object.values(usersDetails).includes(process.env.REACT_APP_SUPER_ADMIN_ROLE);

  useEffect(() => {
    if (Object.entries(usersDetails).length === 0) {
      dispatch(userDetailsCreator());
    }
  }, []);

  useEffect(() => {
    if (getRegionData?.length === 0) {
      dispatch(getRegionCreator());
    }
    const newDropDownValue = getRegionData?.map(x => {
      return { "id": x.id, "name": x.name }
    });
    setRegionData([...newDropDownValue]);
    newDropDownValue.filter((x) => {
      if (x?.id === parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE)) {
        setRegionValue(x?.id);
        setRegionText(x?.name);
      }
    })
    getCorporateList();
  }, [getRegionData]);

  useEffect(() => {
    getCorporateList();
  }, [regionValue, pageRefreshFlag, status]);

  const getCorporateList = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      customer_type_status: status,
      region: regionValue ? regionValue : parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE),
    };
    try {
      const corporateListPayload = await requestHandler("corporateUserList", "post", data, "jwt_token");
      setLoading(false);
      setUserData(corporateListPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const uploadDocumentsHandler = async (name, document, userId) => {
    setLoading(true);
    let data = {
      name: name,
      signature: localStorage.getItem("signature"),
      document: document,
      userId: userId,
    };
    try {
      const uploadDocumentsPayload = await requestHandler("corporateUserDocumentUpload", "post", data, "jwt_token", "file");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      if (uploadDocumentsPayload && uploadDocumentsPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: uploadDocumentsPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
      }
    }
    catch (e) {
      setPageRefreshFlag(!pageRefreshFlag);
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    };
  };

  const approveCorporateAccountHandler = async (userId) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      userId: userId,
    };
    try {
      const approveCorporateAccountPayload = await requestHandler("activateCorporateUser", "post", data, "jwt_token");
      setLoading(false);
      if (approveCorporateAccountPayload && approveCorporateAccountPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: approveCorporateAccountPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
        setPageRefreshFlag(!pageRefreshFlag);
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          regionData={regionData}
          usersData={usersData}
          loading={loading}
          setRegionText={setRegionText}
          regionText={regionText}
          regionValue={regionValue}
          setRegionValue={setRegionValue}
          uploadDocumentsHandler={uploadDocumentsHandler}
          status={status}
          approveCorporateAccountHandler={approveCorporateAccountHandler}
          superadmin={superadmin}
        />
      </div>
    </>
  );
};

export default Promote;
