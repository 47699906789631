import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import LoaderScreen from "../../../../components/LoaderScreen";
import SimpleReactValidator from "simple-react-validator";
import TextInput from "../../../../components/TextInput";

const Share = ({ loading, coinNetworkData, updateCoinNetworkHandler }) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [minFee, seMinFee] = useState("");
  const [maxFee, seMaxFee] = useState("");

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Update Coin Network</div>
        <div className={styles.description}>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Max Fee"
              name="maxFee"
              type="text"
              value={maxFee}
              // onChange={(e) => seMaxFee(e.target.value)}
              onChange={(e) => {
                const re = /^\d*\.?\d*$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  seMaxFee(e.target.value)
                }
              }}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("maxFee")}
            />
            {simpleValidator.current.message("maxFee", maxFee, "required")}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Min Fee"
              name="minFee"
              type="text"
              value={minFee}
              // onChange={(e) => seMinFee(e.target.value)}
              onChange={(e) => {
                const re = /^\d*\.?\d*$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  seMinFee(e.target.value)
                }
              }}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("minFee")}
            />
            {simpleValidator.current.message("minFee", minFee, "required")}
          </div>
        </div>
        <div className={styles.control}>
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              updateCoinNetworkHandler(coinNetworkData?.id, maxFee, minFee);
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }
          }>
            <span>Continue</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Share;
