import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { useSelector } from "react-redux";
import { addNotification } from "../../components/Notification";

const Promote = () => {
  const [loading, setLoading] = useState(false);
  const [walletData, setWalletData] = useState([]);
  const [coinData, setCoinData] = useState([]);
  const { getRegionData } = useSelector((state) => state.getRegion);
  const [visibleModal, setVisibleModal] = useState(false);
  const [searchField, setSearchField] = useState("");

  useEffect(() => {
    getBotWalletHandler();
  }, []);

  const getBotWalletHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const getBotWalletPayload = await requestHandler("getBotWallet", "post", data, "jwt_token");
      setLoading(false);
      setWalletData(getBotWalletPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const rechargeCoinHandler = async (coinId, coinSymbol, amount, regionValue) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      coinId: coinId,
      coinSymbol: coinSymbol,
      amount: amount,
      region: regionValue
    };
    try {
      const rechargeCoinPayload = await requestHandler("fuelBotWallet", "post", data, "jwt_token");
      setLoading(false);
      if (rechargeCoinPayload && rechargeCoinPayload.status === 200) {
        addNotification({
          title: "Success",
          message: rechargeCoinPayload?.data?.message[0].msg,
          type: "success",
        });
        setVisibleModal(false);
        getBotWalletHandler();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message[0].msg,
        type: "danger",
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          setLoading={setLoading}
          walletData={walletData}
          coinData={coinData}
          setCoinData={setCoinData}
          rechargeCoinHandler={rechargeCoinHandler}
          getRegionData={getRegionData}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          searchField={searchField}
          setSearchField={setSearchField}
        />
      </div>
    </>
  );
};

export default Promote;
