import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";

// data
import { posts } from "../../../../mocks/posts";
import Card from "../../../../components/Card";
import Row from "../RecentPost/Row";
import Loader from "../../../../components/Loader";

const intervals = ["Last 7 days", "This month", "All time"];

const RecentPost = ({ className, fiatDepositData }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Fiat Deposit"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Bank</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Region</div>
            <div className={styles.col}>Reference Id</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {fiatDepositData.map((x, index) => (
            <Row item={x} key={index} />
          ))}
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
