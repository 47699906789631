import React from "react";
import { toLocaleStringDateFormat } from "../../../../../components/helper";
import styles from "./Row.module.sass";

const Row = ({ item }) => {

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {capitalize(item?.from)}
      </div>
      <div className={styles.col}>
        {capitalize(item?.to)}
      </div>
      <div className={styles.col}>
        {item?.amount}
        <div className={styles.text}>
          <small>{item?.currency.toUpperCase()}</small>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.txid}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.createdAt)}
      </div>
    </div>
  );
};

export default Row;
