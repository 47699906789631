import React from "react";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";

const Promote = ({ }) => {

  return (
    <>
      <div className={styles.section}>
        <RecentPost />
      </div>
    </>
  );
};

export default Promote;
