import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({
  item,
}) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item?.tier}
      </div>
      <div className={styles.col}>
        {/* {getDigitsAfterDecimal(parseFloat(item?.stakeAmount)?.toLocaleString(), 2)} <small>BDNX</small> */}
        {parseFloat(item?.stakeAmount)?.toLocaleString()} <small>BDNX</small>
      </div>
      <div className={styles.col}>
        {item?.annualPercentage}
      </div>
      <div className={styles.col}>
        {item?.amountLockedDays} days
      </div>
      <div className={styles.col}>
        {item?.earlyRedeemPlenty}%
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.createdAt)}
      </div>
    </div>
  );
};

export default Row;
