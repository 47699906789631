import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import TextInput from "../../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import LoaderScreen from "../../../../../components/LoaderScreen";

const Form = ({ item, uploadDocumentsHandler, loading }) => {
  const [icon, setIcon] = useState();
  const [documentName, setDocumentName] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();

  return (
    <div className={styles.share}>
      {loading && <LoaderScreen />}
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Upload Document</div>
      </div>
      <TextInput
        className={styles.field}
        label="Name of Document"
        name="document"
        type="text"
        placeholder="Please enter document name"
        onChange={(e) => { setDocumentName(e.target.value) }}
        required
        onBlur={() => simpleValidator.current.showMessageFor("document")}
      />
      <span> {simpleValidator.current.message("document", documentName, "required")}</span>
      <TextInput
        className={styles.field}
        label="Document"
        name="icon"
        type="file"
        placeholder="Please enter a value"
        onChange={(e) => { setIcon(e.target.files[0]) }}
        required
        onBlur={() => simpleValidator.current.showMessageFor("icon")}
      />
      <span> {simpleValidator.current.message("icon", icon, "required")}</span>
      <button className={cn("button button-small", styles.button)}
        onClick={(e) => {
          e.stopPropagation();
          if (simpleValidator.current.allValid()) {
            uploadDocumentsHandler(documentName, icon, item?.id);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(1);
          }
        }}>
        <span>Submit Document</span>
      </button>
    </div>
  );
};

export default Form;
