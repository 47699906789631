import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import OrderManagement from "./screens/OrderManagement";
import LogIn from "./screens/LogIn";
import Profile from "./screens/Profile";
import AddCoin from "./screens/AddCoin";
import UpdateCoin from "./screens/UpdateCoin";
import UpdateMarket from "./screens/UpdateMarket";
import AddMarket from "./screens/AddMarket";
import CoinsNetworkList from "./screens/CoinsNetworkList";
import AddCoinNetwork from "./screens/AddCoinNetwork";
import CoinsList from "./screens/CoinsList";
import CustomerList from "./screens/CustomerList";
import SearchCustomers from "./screens/SearchCustomers";
import WhitelistAddress from "./screens/WhitelistAddress";
import { ProtectedRoute } from "./utils";
import PendingSepaDepositList from "./screens/PendingSepaDepositList";
import ApprovedSepaDepositList from "./screens/ApprovedSepaDepositList";
import MarketList from "./screens/MarketList";
import ApprovedCryptoWithdrawList from "./screens/ApprovedCryptoWithdrawList";
import CreateUser from "./screens/CreateUser";
import UsersList from "./screens/UsersList";
import PageNotFound from "./screens/PageNotFound";
import FiatDepositList from "./screens/FiatDepositList";
import FiatWithdrawList from "./screens/FiatWithdrawList";
import PendingCryptoWithdrawList from "./screens/PendingCryptoWithdrawList";
import InProcessKycUser from "./screens/InProcessKycUser";
import BotBalanceUpdate from "./screens/BotBalanceUpdate";
import BotBalanceHistoryPage from "./screens/BotBalanceHistoryPage";
import GenerateMonitorKycId from "./screens/GenerateMonitorKycId";
import UploadGetIdDocument from "./screens/UploadGetIdDocument";
import CorporateList from "./screens/CorporateList";
import AddCorporate from "./screens/AddCorporate";
import CorporateProfile from "./screens/CorporateProfile";
import ChangePassword from "./screens/ChangePassword";
import BotList from "./screens/BotList";
import AddBot from "./screens/AddBot";
import BitdenexPayDeposit from "./screens/BitdenexPayDeposit";
import CryptoDepositList from "./screens/CryptoDepositList";
import VouchersManagement from "./screens/VouchersManagement";
import RewardsManagement from "./screens/RewardsManagement";
import UpdateUserBalance from "./screens/UpdateUserBalance";
import BotWallet from "./screens/BotWallet";
import NotificationManagement from "./screens/NotificationManagement";
import MiracleCryptoWithdrawList from "./screens/MiracleCryptoWithdrawList";
import TransactionMonitoring from "./screens/TransactionMonitoring";
import TransactionMonitoringValues from "./screens/TransactionMonitoringValues";
import SearchOrders from "./screens/SearchOrders";
import DownloadTransactionMonitoringData from "./screens/DownloadTransactionMonitoringData";
import MiraclePendingSepaDepositList from "./screens/MiraclePendingSepaDepositList";
import MiracleApprovedSepaDepositList from "./screens/MiracleApprovedSepaDepositList";
import MiracleBankList from "./screens/MiracleBankList";
import MiracleFiatWithdrawList from "./screens/MiracleFiatWithdrawList";
import FiatWithdrawFinance from "./screens/FiatWithdrawFinance";
import FiatDepositFinance from "./screens/FiatDepositFinance";
import CryptoDepositFinance from "./screens/CryptoDepositFinance";
import OrderFinanceModule from "./screens/OrderFinanceModule";
import CryptoWithdrawFinance from "./screens/CryptoWithdrawFinance";
import MatchListUpdate from "./screens/MatchListUpdate";
import AppBanner from "./screens/AppBanner";
import Staking from "./screens/Staking";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/notification-management"
          element={
            <ProtectedRoute>
              <Page title="Notification Management">
                <NotificationManagement />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/transaction-monitoring-eu"
          element={
            <ProtectedRoute>
              <Page title="Transaction Monitoring(Eu)">
                <TransactionMonitoring region={process.env.REACT_APP_EUROPE_REGION_TEXT} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/transaction-monitoring-int"
          element={
            <ProtectedRoute>
              <Page title="Transaction Monitoring(Int)">
                <TransactionMonitoring region={process.env.REACT_APP_DEFAULT_REGION_TEXT} />
              </Page>
            </ProtectedRoute>
          }
        />
        {/* <Route
          exact
          path="/transaction-monitoring-sur"
          isAuth={localStorage.getItem('signature')}
          element={
            <Page title="Transaction Monitoring(Sur)">
              <TransactionMonitoring region={process.env.REACT_APP_EUROPE_REGION_TEXT} />
            </Page>
          )}
        /> */}
        <Route
          exact
          path="/transaction-monitoring-values-eu"
          element={
            <ProtectedRoute>
              <Page title="Transaction Monitoring Values(Eu)">
                <TransactionMonitoringValues region={process.env.REACT_APP_EUROPE_REGION_TEXT} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/transaction-monitoring-values-int"
          element={
            <ProtectedRoute>
              <Page title="Transaction Monitoring Values(Int)">
                <TransactionMonitoringValues region={process.env.REACT_APP_DEFAULT_REGION_TEXT} />
              </Page>
            </ProtectedRoute>
          }
        />
        {/* <Route
          exact
          path="/transaction-monitoring-values-sur"
          isAuth={localStorage.getItem('signature')}
          element={
            <Page title="Transaction Monitoring Values(Sur)">
              <TransactionMonitoringValues region={process.env.REACT_APP_DEFAULT_REGION_TEXT}/>
            </Page>
          )}
        /> */}
        <Route
          exact
          path="/download-transaction-monitoring-eu"
          element={
            <ProtectedRoute>
              <Page title="Download Monitoring Data(Eu)">
                <DownloadTransactionMonitoringData region={process.env.REACT_APP_EUROPE_REGION_TEXT} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/download-transaction-monitoring-int"
          element={
            <ProtectedRoute>
              <Page title="Download Monitoring Data(Int)">
                <DownloadTransactionMonitoringData region={process.env.REACT_APP_DEFAULT_REGION_TEXT} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/download-transaction-monitoring-sur"
          element={
            <ProtectedRoute>
              <Page title="Download Monitoring Data(Sur)">
                <DownloadTransactionMonitoringData />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/orders-search"
          element={
            <ProtectedRoute>
              <Page title="Search Orders">
                <SearchOrders />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/"
          element={
            <ProtectedRoute>
              <Page title="Dashboard">
                <Home />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/user-management/create-user"
          element={
            <ProtectedRoute>
              <Page title="">
                <CreateUser />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/update-user-balance"
          element={
            <ProtectedRoute>
              <Page title="">
                <UpdateUserBalance />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/user-management/user-list"
          element={
            <ProtectedRoute>
              <Page title="User List">
                <UsersList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/corporate/unverified-corporate"
          element={
            <ProtectedRoute>
              <Page title="Unverified Corporate">
                <CorporateList status={process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS_STRING}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/corporate/complete-corporate"
          element={
            <ProtectedRoute>
              <Page title="Complete Corporate">
                <CorporateList status={process.env.REACT_APP_KYCVERIFICATIONCOMPLETESTRING}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/corporate/pending-corporate"
          element={
            <ProtectedRoute>
              <Page title="Pending Corporate">
                <CorporateList status={process.env.REACT_APP_KYCVERIFICATIONPENDINGSTRING}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/corporate/freezed-corporate"
          element={
            <ProtectedRoute>
              <Page title="Freezed Corporate">
                <CorporateList status={process.env.REACT_APP_FREEZE_EMAIL_CUSTOMERS_STRING}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/corporate/add-corporate"
          element={
            <ProtectedRoute>
              <Page title="Add Corporate">
                <AddCorporate />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/corporate-profile/:id"
          element={
            <ProtectedRoute>
              <Page title="Corporate Profile">
                <CorporateProfile />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/customers/unverified-customers"
          element={
            <ProtectedRoute>
              <Page title="Unverified Customers">
                <CustomerList status={process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS_STRING}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/customers/pending-customers"
          element={
            <ProtectedRoute>
              <Page title="Pending Customers">
                <CustomerList status={process.env.REACT_APP_KYCVERIFICATIONPENDINGSTRING}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/vouchers"
          element={
            <ProtectedRoute>
              <Page title="Vouchers">
                <VouchersManagement />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/rewards"
          element={
            <ProtectedRoute>
              <Page title="Rewards & Level">
                <RewardsManagement />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/customers/kyc-in-review"
          element={
            <ProtectedRoute>
              <Page title="In Review(Kyc)">
                <InProcessKycUser />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/customers/kyc-in-process"
          element={
            <ProtectedRoute>
              <Page title="In Process(Kyc)">
                <CustomerList status={process.env.REACT_APP_KYCVERIFICATIONINPROCESSSTRING}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/customers/freezed-customers"
          element={
            <ProtectedRoute>
              <Page title="Freezed Customers">
                <CustomerList status={process.env.REACT_APP_FREEZE_EMAIL_CUSTOMERS_STRING}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/customers/complete-customers"
          element={
            <ProtectedRoute>
              <Page title="Complete Customers">
                <CustomerList status={process.env.REACT_APP_KYCVERIFICATIONCOMPLETESTRING}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/customers/reactivate-customers"
          element={
            <ProtectedRoute>
              <Page title="Reactivate Customers">
                <CustomerList status={process.env.REACT_APP_REACTIVATE_CUSTOMERS_STRING}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/customers/search-customers"
          element={
            <ProtectedRoute>
              <Page title="Search Customers">
                <SearchCustomers />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/customers/generate-monitor-kyc-id"
          element={
            <ProtectedRoute>
              <Page title="Generate Monitor Kyc Id">
                <GenerateMonitorKycId />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/customers/upload-getId-document"
          element={
            <ProtectedRoute>
              <Page title="Upload Get Id Documents">
                <UploadGetIdDocument />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin-balance-overview/admin-balance-history"
          element={
            <ProtectedRoute>
              <Page title="Admin Balance History">
                <BotBalanceHistoryPage />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin-balance-overview/admin-balance-update"
          element={
            <ProtectedRoute>
              <Page title="Admin Balance Update">
                <BotBalanceUpdate />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/trade/market"
          element={
            <ProtectedRoute>
              <Page title="Markets">
                <MarketList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/orders-management"
          element={
            <ProtectedRoute>
              <Page title="Orders Management">
                <OrderManagement />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/whitelist-address"
          element={
            <ProtectedRoute>
              <Page title="Whitelist Address">
                <WhitelistAddress />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/trade/coins"
          element={
            <ProtectedRoute>
              <Page title="Coins">
                <CoinsList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/sepa-deposits/pending-deposits"
          element={
            <ProtectedRoute>
              <Page title="Pending Sepa Deposits">
                <PendingSepaDepositList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/sepa-deposits/approved-deposits"
          element={
            <ProtectedRoute>
              <Page title="Approved Sepa Deposits">
                <ApprovedSepaDepositList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/payment-deposits/pending-deposits"
          element={
            <ProtectedRoute>
              <Page title="Pending Sepa Deposits(Miracle)">
                <MiraclePendingSepaDepositList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/payment-deposits/approved-deposits"
          element={
            <ProtectedRoute>
              <Page title="Approved Sepa Deposits(Miracle)">
                <MiracleApprovedSepaDepositList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/bank-list"
          element={
            <ProtectedRoute>
              <Page title="Miracle Bank List">
                <MiracleBankList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/payment-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Fiat Withdraw(Miracle)">
                <MiracleFiatWithdrawList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/fiat-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Fiat Withdraw">
                <FiatWithdrawList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/finance/order-module"
          element={
            <ProtectedRoute>
              <Page title="Orders">
                <OrderFinanceModule />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/finance/crypto-deposit"
          element={
            <ProtectedRoute>
              <Page title="Crypto Deposit">
                <CryptoDepositFinance />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/finance/crypto-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Crypto Withdraw">
                <CryptoWithdrawFinance />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/finance/fiat-deposit"
          element={
            <ProtectedRoute>
              <Page title="Fiat Deposit">
                <FiatDepositFinance />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/finance/fiat-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Fiat Withdraw">
                <FiatWithdrawFinance />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/fiat-deposits"
          element={
            <ProtectedRoute>
              <Page title="Fiat Deposits">
                <FiatDepositList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/bitdenex-pay"
          element={
            <ProtectedRoute>
              <Page title="Bitdenex Pay">
                <BitdenexPayDeposit />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/crypto-deposit"
          element={
            <ProtectedRoute>
              <Page title="Crypto Deposit">
                <CryptoDepositList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/crypto-withdraw/pending-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Pending Crypto Withdraw">
                <PendingCryptoWithdrawList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/miracle-withdraw/pending-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Pending Crypto Withdraw">
                <MiracleCryptoWithdrawList status={parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_PENDING)} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/bots/wallet"
          element={
            <ProtectedRoute>
              <Page title="Wallet Overview">
                <BotWallet />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/crypto-withdraw/success-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Success Crypto Withdraw">
                <ApprovedCryptoWithdrawList status={parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_SUCCESS)} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/crypto-withdraw/cancel-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Cancel Crypto Withdraw">
                <ApprovedCryptoWithdrawList status={parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_CANCEL)} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/crypto-withdraw/process-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Process Crypto Withdraw">
                <ApprovedCryptoWithdrawList status={parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO__TRANSACTION_STATUS_PROCESS)} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/crypto-withdraw/declined-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Declined Crypto Withdraw">
                <ApprovedCryptoWithdrawList status={parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_DECLINE)} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/crypto-withdraw/unverified-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Unverified Crypto Withdraw">
                <ApprovedCryptoWithdrawList status={parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_UNVERIFIED)} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/miracle-withdraw/success-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Success Crypto Withdraw">
                <MiracleCryptoWithdrawList status={parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_SUCCESS)} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/miracle-withdraw/cancel-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Cancel Crypto Withdraw">
                <MiracleCryptoWithdrawList status={parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_CANCEL)} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/miracle-withdraw/process-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Process Crypto Withdraw">
                <MiracleCryptoWithdrawList
                  status={parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO__TRANSACTION_STATUS_PROCESS)} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/miracle-withdraw/declined-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Declined Crypto Withdraw">
                <MiracleCryptoWithdrawList status={parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_DECLINE)} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/miracle-withdraw/unverified-withdraw"
          element={
            <ProtectedRoute>
              <Page title="Unverified Crypto Withdraw">
                <MiracleCryptoWithdrawList status={parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_UNVERIFIED)} />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/trade/coins-network"
          element={
            <ProtectedRoute>
              <Page title="Coins Network">
                <CoinsNetworkList />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/trade/coins-network/add-coin-network"
          element={
            <ProtectedRoute>
              <Page title="Add Coin Network">
                <AddCoinNetwork />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/profile/:id"
          element={
            <ProtectedRoute>
              <Page title="Profile">
                <Profile />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/trade/coins/add-coin"
          element={
            <ProtectedRoute>
              <Page title="Add Coin">
                <AddCoin />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/trade/coins/:symbol"
          element={
            <ProtectedRoute>
              <Page title="Update Coin">
                <UpdateCoin />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/trade/market/add-market"
          element={
            <ProtectedRoute>
              <Page title="Add Market">
                <AddMarket />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/trade/market/:slug"
          element={
            <ProtectedRoute>
              <Page title="Update Market">
                <UpdateMarket />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/change-password"
          element={
            <ProtectedRoute>
              <Page title="Change Password">
                <ChangePassword />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/bots/active-bots"
          element={
            <ProtectedRoute>
              <Page title="Active Bots">
                <BotList
                  status={parseInt(process.env.REACT_APP_BOT_USER_ACTIVE)}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/bots/pending-bots"
          element={
            <ProtectedRoute>
              <Page title="Pending Bots">
                <BotList
                  status={parseInt(process.env.REACT_APP_BOT_USER_DEACTIVE)}
                />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/bot/add-bot"
          element={
            <ProtectedRoute>
              <Page title="Add Bot">
                <AddBot />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/match-list-update"
          element={
            <ProtectedRoute>
              <Page title="Match List Update">
                <MatchListUpdate />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          path="/banner"
          element={
            <ProtectedRoute>
              <Page title="App Banner">
                <AppBanner />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route
          path="/staking"
          element={
            <ProtectedRoute>
              <Page title="Stake Values">
                <Staking />
              </Page>
            </ProtectedRoute>
          }
        />
        <Route exact path="/sign-in" element={<LogIn />} />
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
