import React from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import TextInput from "../../../components/TextInput";
import Item from "../Item";
import { addNotification } from "../../../components/Notification";

const Table = ({ className, residenceDetails, updateUserResidenceAddressHandler,
  profileInfo, setResidenceDetails, superadmin }) => {

  return (
    <Item
      className={cn(styles.card, className)}
      title="Residence information"
      classTitle="title-green"
    >
      <div className={styles.profile}>
        <div className={styles.file}>
        </div>
      </div>
      <div>
        <div className={styles.fieldset}>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Address 1"
              name="address1"
              value={residenceDetails?.address1 === undefined || null ? "" : residenceDetails?.address1}
              type="text"
              required
              onChange={(e) => setResidenceDetails(residenceDetails => ({ ...residenceDetails, address1: e.target.value }))}
              disabled={!superadmin}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Address 2"
              name="address2"
              value={residenceDetails?.address2 === undefined || null ? "" : residenceDetails?.address2}
              type="text"
              required
              onChange={(e) => setResidenceDetails(residenceDetails => ({ ...residenceDetails, address2: e.target.value }))}
              disabled={!superadmin}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="City"
              name="city"
              value={residenceDetails?.city === undefined || null ? "" : residenceDetails?.city}
              type="text"
              required
              onChange={(e) => setResidenceDetails(residenceDetails => ({ ...residenceDetails, city: e.target.value }))}
              disabled={!superadmin}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Country"
              name="country"
              value={residenceDetails?.country === undefined || null ? "" : residenceDetails?.country}
              type="text"
              required
              onChange={(e) => setResidenceDetails(residenceDetails => ({ ...residenceDetails, country: e.target.value }))}
              disabled={!superadmin}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="State"
              name="state"
              value={residenceDetails?.state === undefined || null ? "" : residenceDetails?.state}
              type="text"
              required
              onChange={(e) => setResidenceDetails(residenceDetails => ({ ...residenceDetails, state: e.target.value }))}
              disabled={!superadmin}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Zip code"
              name="zipcode"
              value={residenceDetails?.zip_code === undefined || null ? "" : residenceDetails?.zip_code}
              type="text"
              required
              onChange={(e) => setResidenceDetails(residenceDetails => ({ ...residenceDetails, zip_code: e.target.value }))}
              disabled={!superadmin}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Status"
              name="status"
              value={
                residenceDetails?.status === undefined || null ? "" : residenceDetails?.status === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_PENDING) ? "Pending" :
                  residenceDetails?.status === undefined || null ? "" : residenceDetails?.status === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_INPROCESS) ? "In-process" :
                    residenceDetails?.status === undefined || null ? "" : residenceDetails?.status === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) ? "Completed" :
                      residenceDetails?.status === undefined || null ? "" : residenceDetails?.status === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) ? "Rejected" : null} type="text"
              required
              disabled={true}
            />
          </div>
          {superadmin &&
            <div className={styles.customField}>
              {
                residenceDetails?.status === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_INPROCESS) ?
                  <div className={styles.customRow}>
                    <div className={styles.buttonClass}>
                      <div className={styles.settings}>
                        <button className={cn("button-small",)} style={{ background: 'green' }} type="button"
                          onClick={(e) => {
                            setResidenceDetails(residenceDetails => ({ ...residenceDetails, status: parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) }))
                          }}
                        >
                          <span>Accept</span>
                        </button>
                      </div>
                    </div>
                    <div className={styles.buttonClass}>
                      <div className={styles.settings}>
                        <button className={cn("button-small",)} style={{ background: 'red' }} type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setResidenceDetails(residenceDetails => ({ ...residenceDetails, status: parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) }))
                          }}
                        >
                          <span>Reject</span>
                        </button>
                      </div>
                    </div>
                  </div> :
                  residenceDetails?.status === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) ?
                    <div className={styles.buttonClass}>
                      <div className={styles.settings}>
                        <button className={cn("button-small",)} style={{ background: 'red' }} type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setResidenceDetails(residenceDetails => ({ ...residenceDetails, status: parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) }))
                          }
                          }
                        >
                          <span>Reject</span>
                        </button>
                      </div>
                    </div> :
                    residenceDetails?.status === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) ?
                      <div className={styles.buttonClass}>
                        <div className={styles.settings}>
                          <button className={cn("button-small",)} style={{ background: 'green' }} type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setResidenceDetails(residenceDetails => ({ ...residenceDetails, status: parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) }))
                            }
                            }
                          >
                            <span>Accept</span>
                          </button>
                        </div>
                      </div>
                      : '---'
              }
            </div>}

          <div>
            Proof
          </div>
          {profileInfo?.olduser === "N" ?
            <div className={styles.image} onClick={() => window.open(process.env.REACT_APP_IMAGE_URL + "/" + residenceDetails.proof, '_blank')}>
              <img
                className={styles.customImagesClass}
                src={process.env.REACT_APP_IMAGE_URL + "/" + residenceDetails.proof}
                alt="Proof"
              />
            </div>
            :
            <div className={styles.image} onClick={() => window.open(process.env.REACT_APP_IMAGE_URL_OLD + "/" + residenceDetails.proof, '_blank')}>
              <img
                className={styles.customImagesClass}
                src={process.env.REACT_APP_IMAGE_URL_OLD + "/" + residenceDetails.proof}
                alt="Proof"
              />
            </div>
          }
          {/* {
            residenceDetails?.status === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_INPROCESS) ?
              <div className={styles.customRow}>
                <div className={styles.buttonClass}>
                  <div className={styles.settings}>
                    <button className={cn("button",)} style={{ background: 'green' }} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (residenceDetails?.address1 !== "" && residenceDetails?.address2 !== "" && residenceDetails?.city !== "" &&
                          residenceDetails?.country !== "" && residenceDetails?.state !== "" && residenceDetails?.zip_code !== "") {
                          updateUserResidenceAddressHandler(residenceDetails.userId, parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE),
                            residenceDetails.id,
                            residenceDetails)
                        } else {
                          addNotification({
                            title: 'Error',
                            message: "Please fill all the fields.",
                            type: 'danger'
                          });
                        }
                      }}
                    >
                      <span>Accept</span>
                    </button>
                  </div>
                </div>
                <div className={styles.buttonClass}>
                  <div className={styles.settings}>
                    <button className={cn("button",)} style={{ background: 'red' }} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (residenceDetails?.address1 !== "" && residenceDetails?.address2 !== "" && residenceDetails?.city !== "" &&
                          residenceDetails?.country !== "" && residenceDetails?.state !== "" && residenceDetails?.zip_code !== "") {
                          updateUserResidenceAddressHandler(residenceDetails.userId, parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT),
                            residenceDetails.id, residenceDetails)
                        } else {
                          addNotification({
                            title: 'Error',
                            message: "Please fill all the fields.",
                            type: 'danger'
                          });
                        }
                      }}
                    >
                      <span>Reject</span>
                    </button>
                  </div>
                </div>
              </div> :
              residenceDetails?.status === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) ?
                <div className={styles.buttonClass}>
                  <div className={styles.settings}>
                    <button className={cn("button",)} style={{ background: 'red' }} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (residenceDetails?.address1 !== "" && residenceDetails?.address2 !== "" && residenceDetails?.city !== "" &&
                          residenceDetails?.country !== "" && residenceDetails?.state !== "" && residenceDetails?.zip_code !== "") {
                          updateUserResidenceAddressHandler(residenceDetails.userId, parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT),
                            residenceDetails.id, residenceDetails)
                        } else {
                          addNotification({
                            title: 'Error',
                            message: "Please fill all the fields.",
                            type: 'danger'
                          });
                        }
                      }
                      }
                    >
                      <span>Reject</span>
                    </button>
                  </div>
                </div> :
                residenceDetails?.status === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) ?
                  <div className={styles.buttonClass}>
                    <div className={styles.settings}>
                      <button className={cn("button",)} style={{ background: 'green' }} type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          if (residenceDetails?.address1 !== "" && residenceDetails?.address2 !== "" && residenceDetails?.city !== "" &&
                            residenceDetails?.country !== "" && residenceDetails?.state !== "" && residenceDetails?.zip_code !== "") {
                            updateUserResidenceAddressHandler(residenceDetails.userId, parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE),
                              residenceDetails.id, residenceDetails)
                          } else {
                            addNotification({
                              title: 'Error',
                              message: "Please fill all the fields.",
                              type: 'danger'
                            });
                          }
                        }
                        }
                      >
                        <span>Accept</span>
                      </button>
                    </div>
                  </div>
                  : '---'
          } */}
          {superadmin && <button
            onClick={(e) => {
              e.preventDefault();
              updateUserResidenceAddressHandler(residenceDetails);
            }}
            className={cn("button-small", styles.button)}
          >
            Save Changes
          </button>}

        </div>
      </div>
    </Item>
  );
};

export default Table;
