import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({ item }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item?.userId}
      </div>
      <div className={styles.col}>
        {item?.amount}
        <div className={styles.text}>
          {item?.currency}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.m_user?.uid}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.reference}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {
          item?.payment_status === "Success" ? (
            <div className={cn("status-green-dark", styles.distribution)}>
              Success
            </div>
          ) :
            item?.payment_status === null || item?.payment_status === undefined ? "---" :
              <div className={cn("status-red-dark", styles.distribution)}>
                {item?.payment_status}
              </div>
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.createdAt)}
      </div>
    </div>
  );
};

export default Row;
