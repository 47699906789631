import {
    REFERRAL_LIST_FAILED,
    REFERRAL_LIST_REQUEST,
    REFERRAL_LIST_SUCCESS
} from "../actions/referralList/actionType";

const initialState = {
    loading: false,
    referralData: [],
    err: "",
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REFERRAL_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case REFERRAL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                referralData: action.payload,
            }
        case REFERRAL_LIST_FAILED:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    };
};
export default reducer;