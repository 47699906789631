import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";
import CustomModal from "../../../../components/CustomModal";
import Details from '../Details'

const Row = ({ item, updateAddressHandler, superadmin }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  return (
    <>
      <div className={styles.row} onClick={() => { setVisibleModal(true); setSelectedItem(item) }}>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              <img
                srcSet={`${item.image2x} 2x`}
                src={item.icon}
                alt="Product"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.name.toUpperCase()}</div>
              <div className={styles.text}>{item.symbol.toUpperCase()}</div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{item.address}</div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{item?.region}</div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{(item?.ownership === undefined || item?.ownership === null) ? "---" : item?.ownership}</div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              {item?.type === parseInt(process.env.REACT_APP_INTERNAL_CRYPTO_ADDRESS) ?
                "NA"
                :
                <img className={styles.image} srcSet={`${process.env.REACT_APP_IMAGE_URL + "/" + item.scanOwnership} 2x`} src={process.env.REACT_APP_IMAGE_URL + "/" + item.scanOwnership} alt="proof" />
              }
            </div>
          </div>
        </div>
        {/* <div className={styles.col}>
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PENDING) && (<div style={{ color: "rgb(142, 89, 255)" }}>Pending</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PROCESSING) && (<div style={{ color: "rgb(142, 89, 255)" }}>Processing</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED) && (<div style={{ color: "green" }}>Verified</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_DECLINED) && (<div style={{ color: "red" }}>Declined</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_CANCELLED) && (<div style={{ color: "red" }}>Cancelled</div>)}
        </div> */}
        <div className={styles.col}>
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PENDING) && (<div className={cn("status-red-dark", styles.distribution)}>Pending</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PROCESSING) && (<div className={cn("status-red-dark", styles.distribution)}>Processing</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED) && (<div className={cn("status-green-dark", styles.distribution)}>Verified</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_DECLINED) && (<div className={cn("status-red-dark", styles.distribution)}>Declined</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_CANCELLED) && (<div className={cn("status-red-dark", styles.distribution)}>Cancelled</div>)}
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{toLocaleStringDateFormat(item.createdAt)}</div>
          </div>
        </div>
        {/* {superadmin &&
          <div className={styles.col}>
            <div className={styles.row}>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <div>
                    {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PENDING) &&
                      <>
                        <button className={cn("button", styles.acceptButton)} type="button"
                          onClick={(e) => {
                            updateAddressHandler(e, item.id, parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED));
                          }}
                        >
                          <span>Accept</span>
                        </button>
                        <button className={cn("button", styles.rejectButton)} type="button"
                          onClick={(e) => {
                            updateAddressHandler(e, item.id, parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_DECLINED));
                          }}
                        >
                          <span>Reject</span>
                        </button>
                      </>
                    }
                    {item.status !== parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PENDING) &&
                      "---"
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>} */}
      </div>
      <CustomModal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Details item={selectedItem} />

      </CustomModal>
    </>
  );
};

export default Row;
