import React, { useState } from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import Icon from "../../../../../src/components/Icon";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../../src/components/helper";

const Share = ({ item }) => {

  return (
    <>
      <div className={styles.share} >
        <div className={styles.head}>
          <div className={cn("title-red", styles.title)}>Order Details</div>
        </div>
        {item?.order_matching_history && item?.order_matching_history.length > 0 && item?.order_matching_history?.map((x, ind) =>
          <>
            <div className={styles.customCard} key={ind}>
              <div className={styles.wrapper} >
                <div className={styles.text} key={ind}>
                  Matched Order Id
                </div>
                <div className={styles.col}>
                  {x?.match_orderId}
                </div>
              </div>
              <div className={styles.wrapper}>
                <div className={styles.text}>Executed Crypto</div>
                <div className={styles.col}>
                  {getDigitsAfterDecimal(x?.exeCrypto, item?.amountDecimalPrecision)} <small>{item?.coin.toUpperCase()}</small>
                </div>
              </div>
              <div className={styles.wrapper}>
                <div className={styles.text}>Executed Fiat</div>
                <div className={styles.col}>
                  {getDigitsAfterDecimal(x?.exeFiat, item?.priceDecimalPrecision)} <small>{item?.currency.toUpperCase()}</small>
                  {/* {getDigitsAfterDecimal(x?.exeFiat, item?.amountDecimalPrecision)} */}
                </div>
              </div>
            </div>
          </>)
        }
      </div>
    </>
  );
};

export default Share;
