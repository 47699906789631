import {
    USER_DETAILS_FAILED,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
} from "../actions/userDetails/actionType";

const intialState = {
    loading: false,
    error: "",
    usersDetails: [],
    status: ""
};

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case USER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                usersDetails: action.payload,
                status: action.payload
            };

        case USER_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};


export default reducer;
