import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";

const regionValues = [process.env.REACT_APP_EUROPE_REGION_TEXT.toUpperCase(), process.env.REACT_APP_DEFAULT_REGION_TEXT.toUpperCase()];
const listStatusValues = ["Pending", "Completed"];
const intervalValues = ["Single", "Days"];

const Promote = ({ region }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [regionValue, setRegionValue] = useState(regionValues[0]);
  const [listStatus, setListStatus] = useState(listStatusValues[0]);
  const [intervalStatus, setIntervalStatus] = useState(intervalValues[0]);
  const [selectedTransaction, setSelectedTransaction] = useState([]);

  useEffect(() => {
    getAllTransactionHandler();
  }, [listStatus, intervalStatus]);

  const getAllTransactionHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: listStatus.toLowerCase() === "pending" ? parseInt(process.env.REACT_APP_TRANSACTION_CHECKED_PENDING) : parseInt(process.env.REACT_APP_TRANSACTION_CHECKED_COMPLETED),
      region: region,
      interval: intervalStatus.toLowerCase() === "days" ? "hour" : "single",
    };
    try {
      const getAllTransactionPayload = await requestHandler("getTrasactionMonitoringList", "post", data, "jwt_token");
      setLoading(false);
      setTransactionData(getAllTransactionPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const markAsCheckedHandler = async (id) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      monitoring_id: id,
    };
    try {
      const markAsCheckedPayload = await requestHandler("actionTrasactionMonitoring", "post", data, "jwt_token");
      setLoading(false);
      if (markAsCheckedPayload && markAsCheckedPayload.status === 200) {
        addNotification({
          title: "Success",
          message: markAsCheckedPayload?.data?.message?.msg,
          type: "success",
        });
        getAllTransactionHandler();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong",
        type: "danger"
      });
    }
  };

  return (
    <>
      <div className={styles.section}>
        {loading && <LoaderScreen />}
        <RecentPost
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          transactionData={transactionData}
          loading={loading}
          regionValue={regionValue}
          setRegionValue={setRegionValue}
          regionValues={regionValues}
          listStatus={listStatus}
          setListStatus={setListStatus}
          listStatusValues={listStatusValues}
          intervalValues={intervalValues}
          setIntervalStatus={setIntervalStatus}
          intervalStatus={intervalStatus}
          setSelectedTransaction={setSelectedTransaction}
          selectedTransaction={selectedTransaction}
          markAsCheckedHandler={markAsCheckedHandler}
        />
      </div>
    </>
  );
};

export default Promote;
