import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const Promote = () => {
  const [loading, setLoading] = useState(false);
  const [stakeData, setStakeData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    getStakeListHandler();
  }, []);

  const getStakeListHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const getStakeListPayload = await requestHandler("get_stake_values", "post", data, "jwt_token");
      setStakeData(getStakeListPayload?.data?.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const addStakeHandler = async (amount, percentage, tier, penalty) => {
    setLoading(true);
    let data = {
      stakeAmount: amount,
      annualPercentage: percentage,
      signature: localStorage.getItem("signature"),
      tier: tier,
      earlyRedeemPlenty: penalty,
    };
    try {
      const addStakePayload = await requestHandler("create_stake_value", "post", data, "jwt_token");
      setLoading(false);
      if (addStakePayload?.status === 200) {
        addNotification({
          title: "Success",
          message: addStakePayload?.data?.message[0]?.msg,
          type: "success",
        });
        await getStakeListHandler();
        setVisibleModal(false);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          stakeData={stakeData}
          addStakeHandler={addStakeHandler}
        />
      </div>
    </>
  );
};

export default Promote;
