import React, { useEffect, useState } from "react";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const botTypeOtion = [
  { "id": parseInt(process.env.REACT_APP_VOLUME_BOT), "name": "VOLUME BOT" },
  { "id": parseInt(process.env.REACT_APP_LIMIT_ORDER_BOT), "name": "LIMIT ORDER BOT" },
];

const Promote = ({ status }) => {
  const [loading, setLoading] = useState(false);
  const [usersData, setUserData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [regionText, setRegionText] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);
  const [botTypeValue, setBotTypeValue] = useState(botTypeOtion[0].id);
  const [botTypeText, setBotTypeText] = useState(botTypeOtion[0].name);

  useEffect(() => {
    getBotList();
  }, [status, pageRefreshFlag, botTypeValue]);

  const getBotList = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: status,
      type: botTypeValue,
    };
    try {
      const botListPayload = await requestHandler("getBotUserList", "post", data, "jwt_token");
      setLoading(false);
      setUserData(botListPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const botActionHandler = async (status, id) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: status,
      id: id,
    };
    try {
      const botActionPayload = await requestHandler("actionBotStatus", "post", data, "jwt_token");
      setLoading(false);
      if (botActionPayload && botActionPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: botActionPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
        setPageRefreshFlag(!pageRefreshFlag);
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    };
  };

  const handleBotTypeSelection = (x) => {
    setBotTypeText(x.name);
    setBotTypeValue(x.id);
  };

  const rechargeBothandler = async (uid, amount, setVisibleBotModal) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      uid: uid,
      amount: amount,
    };
    try {
      const rechargeBotPayload = await requestHandler("rechargeBot", "post", data, "jwt_token");
      setLoading(false);
      if (rechargeBotPayload && rechargeBotPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: rechargeBotPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
      }
      setPageRefreshFlag(!pageRefreshFlag);
      setVisibleBotModal(false);
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          regionData={regionData}
          usersData={usersData}
          loading={loading}
          botActionHandler={botActionHandler}
          botTypeValue={botTypeValue}
          botTypeText={botTypeText}
          setBotTypeText={setBotTypeText}
          setBotTypeValue={setBotTypeValue}
          handleBotTypeSelection={handleBotTypeSelection}
          botTypeOtion={botTypeOtion}
          rechargeBothandler={rechargeBothandler}
        />
      </div>
    </>
  );
};

export default Promote;
