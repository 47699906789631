import CreateUser from './ProfileInformation'
import styles from "./Create.module.sass";
import requestHandler from '../../actions/httpClient';
import { addNotification } from '../../components/Notification';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const CreateUsers = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const createUser = async (email, password, role, firstName, lastName) => {
        setLoading(true);
        try {
            let data = {
                email: email,
                password: password,
                role: role,
                fname: firstName,
                lname: lastName,
                signature: localStorage.getItem('signature'),
                region: 1
            };
            const newUserPayload = await requestHandler("createNewUser", "post", data, 'jwt_token');
            setLoading(false);
            if (newUserPayload.status === 200) {
                addNotification({
                    title: 'Success',
                    message: newUserPayload?.data?.message[0]?.msg,
                    type: 'success'
                });
                navigate('/user-management/user-list');
            }
        }
        catch (e) {
            setLoading(false);

            addNotification({
                title: 'Error',
                message: e?.data?.message[0]?.msg,
                type: 'danger'
            });
        };
    }

    return (
        <div className={styles.customClass}>
            <CreateUser createUser={createUser} className={styles.card} loading={loading} />
        </div>
    )
}

export default CreateUsers