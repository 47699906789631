import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Main.module.sass";

const Main = () => {

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>Page Not Found!
          </h2>
          <Link className={cn("button", styles.button)} to="/">
            Go to Home page.
          </Link>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/page-not-found@2x.png"
            src="/images/content/page-not-found.png"
            alt="Cards"
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
