import React, { useEffect, useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRegionCreator } from "../../../../actions/getRegions";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({ item, order, generateMonitorKycIdHandler }) => {
  const { getRegionData } = useSelector((state) => state.getRegion);
  const dispatch = useDispatch();
  const [regionText, setRegionText] = useState("");

  useEffect(() => {
    if (getRegionData?.length === 0) {
      dispatch(getRegionCreator());
    }
    getRegionData?.filter(x => {
      if (x.id === item.region) {
        setRegionText(x.name);
      };
    });
  }, [getRegionData, order]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item.id}
      </div>
      <Link className={styles.col}
        to={{
          pathname: `/profile/${item.id}`,
          state: item,
        }}>
        {item.fname === null || undefined || item.lname === null || undefined ? "---" : item.fname + " " + item.lname}
        <div className={styles.text}>
          {item.email}
        </div>
      </Link>

      <div className={styles.col}>
        {item.uid}
      </div>

      <div className={styles.col}>
        {capitalizeFirstLetter(regionText.toLowerCase())}
      </div>

      <div className={styles.col}>
        {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONINPROCESS) && (
          <div className={cn("status-red-dark", styles.distribution)}>
            In-process
          </div>
        )}

        {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONPENDING) && (
          <div className={cn("status-red-dark", styles.distribution)}>
            Pending
          </div>
        )}

        {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE) && (
          <div className={cn("status-green-dark", styles.distribution)}>
            Completed
          </div>
        )}

        {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONFAILURE) && (
          <div className={cn("status-red-dark", styles.distribution)}>
            Failed
          </div>
        )}

        {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT) && (
          <div className={cn("status-red-dark", styles.distribution)}>
            Rejected
          </div>
        )}
      </div>

      <div className={styles.col}>
        {item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING) ?
          <div className={cn("status-red-dark", styles.distribution)}>
            Pending
          </div>
          :
          item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONUSEREXIT) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              User Exit
            </div>
            :
            item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONINPROCESS) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                In-process
              </div>
              :
              item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONONERROR) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  Token Expired
                </div>
                :
                item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE) ?
                  <div className={cn("status-green-dark", styles.distribution)}>
                    Completed
                  </div>
                  :
                  "---"
        }
      </div>
      <div className={styles.col}>
        {item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_PENDING) ?
          <div className={cn("status-red-dark", styles.distribution)}>
            Pending
          </div>
          :
          item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_INPROCESS) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              In-process
            </div>
            :
            item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) ?
              <div className={cn("status-green-dark", styles.distribution)}>
                Completed
              </div>
              :
              item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  Rejected
                </div>
                :
                "---"
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item.createdAt)}
      </div>
      <div className={styles.col}>
        <button className={cn("button button-small", styles.button)}
          onClick={(e) => {
            e.stopPropagation();
            generateMonitorKycIdHandler(item?.applicant_kyc_id, item?.id);
          }}>
          <span>Generate Monitor KYC Id</span>
        </button>
      </div>
    </div>

  );
};

export default Row;
