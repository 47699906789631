import React, { useState } from "react";
import styles from "./Row.module.sass";
import { toLocaleStringDateFormat } from "../../../components/helper";
import Icon from "../../../components/Icon";
import cn from "classnames";

const Row = ({ item, setVisibleModal, setCoinsNetworkData }) => {

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.details}>
              <div className={styles.product}>{item.id}</div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.product}>{item.network_name}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.product}>{item.network_slug}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.product}>{item.fireblock_network_id}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.product}>{item.description}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.product}>{parseInt(item.status) === parseInt(process.env.REACT_APP_COIN_NETWORK_ENABLE) ? "Enable" :
            parseInt(item.status) === parseInt(process.env.REACT_APP_COIN_NETWORK_DISABLE) ? "Disabled" : "---"
          }
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.product}>{item.maxFee}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.product}>{item.minFee}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.product}>{toLocaleStringDateFormat(item.updatedAt)}</div>
        </div>
        <div className={styles.col}>
          <button
            className={cn("button", styles.button)}
            onClick={() => {
              setVisibleModal(true);
              setCoinsNetworkData(item);
            }}
          >
            <span>Update</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Row;
