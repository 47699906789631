import {
    WALLET_DETAILS_FAILED,
    WALLET_DETAILS_REQUEST,
    WALLET_DETAILS_SUCCESS
} from "../actions/userWalletDetails/actionType"

const initialState = {
    loading: false,
    walletData: [],
    message: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case WALLET_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case WALLET_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                walletData: action.payload,
            }
        case WALLET_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}
export default reducer