import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import SimpleReactValidator from "simple-react-validator";
import OrderDropdown from "../../../../../components/OrderDropdown"
import requestHandler from "../../../../../actions/httpClient";
import LoaderScreen from "../../../../../components/LoaderScreen";
import { addNotification } from "../../../../../components/Notification";

const Share = ({ item, loading, setLoading, setUpdateRewardModalVisible, getRewardsList, levelData }) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [statusText, setStatusText] = useState(item?.status === 1 ? "Enable" : "Disable");
  const [statusValue, setStatusValue] = useState(item?.status);

  const updateRewardHandler = async (id) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: statusValue,
      id: id
    };
    try {
      const updateRewardPayload = await requestHandler("updateRewardLevel", "post", data, "jwt_token");
      setLoading(false);
      if (updateRewardPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateRewardPayload?.data?.message[0].msg,
          type: 'success'
        });
        setUpdateRewardModalVisible(false);
        getRewardsList();
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Update Reward</div>
        <div className={styles.description}>
          <div className={styles.customField}>
            <OrderDropdown
              label="Status"
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={statusValue}
              setValue={setStatusValue}
              text={statusText}
              setText={setStatusText}
              options={levelData}
            />
          </div>
        </div>
        <div className={styles.control}>
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              updateRewardHandler(item?.id);
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }
          }>
            <span>Continue</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Share;
