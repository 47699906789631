import React from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../../actions/httpClient";
import { addNotification } from "../../../components/Notification";

const Promote = ({ cryptoWithdrawData }) => {

  const updateCryptoWithdrawHandler = async (action_status, withdraw_id, userId) => {
    let data = {
      signature: localStorage.getItem("signature"),
      action_status: action_status,
      withdraw_id: withdraw_id,
      userId: userId
    };
    try {
      const updateCryptoWithdrawPayload = await requestHandler("actionWithdrawCrypto", "post", data, "jwt_token");
      if (updateCryptoWithdrawPayload && updateCryptoWithdrawPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateCryptoWithdrawPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
        window.location.reload();
      }
    }
    catch (e) {
    }
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          cryptoWithdrawData={cryptoWithdrawData}
          updateCryptoWithdrawHandler={updateCryptoWithdrawHandler} />
      </div>
    </>
  );
};

export default Promote;
