import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Row from "./Row"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import requestHandler from "../../../actions/httpClient";

const regionValues = [process.env.REACT_APP_EUROPE_REGION_TEXT.toUpperCase(), process.env.REACT_APP_DEFAULT_REGION_TEXT.toUpperCase(), process.env.REACT_APP_SURINAME_REGION.toUpperCase()];

const Overview = ({
  className,
  setLoading,
}) => {
  const dateFilter = process.env.REACT_APP_DATE_WITH_CUSTOM_FILTER.split(',');
  const [date, setDate] = useState(dateFilter[0]);
  const [regionValue, setRegionValue] = useState(regionValues[0]);
  const [coinsData, setCoinsData] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const currentDate = new Date();
  const [coinValue, setCoinValue] = useState("Select coin");
  const [financeData, setFinanceData] = useState([]);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  useEffect(() => {
    getCoins();
  }, []);

  useEffect(() => {
    if (coinValue !== "Select coin") {
      getOrdersDataByMarket();
    }
  }, [coinValue, regionValue, date, fromDate, toDate]);

  const getCoins = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const coinsPayload = await requestHandler("getCoins", "post", data, "jwt_token");
      setLoading(false);
      let newArr = coinsPayload?.data?.data?.filter((x) => { return x?.name?.toLowerCase() !== "euro" }).map((y) => { return y?.symbol?.toUpperCase() });
      setCoinsData(newArr);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const getOrdersDataByMarket = async () => {
    setLoading(true);
    let data = {
      coin: coinValue?.toLowerCase(),
      signature: localStorage.getItem("signature"),
      region: regionValue,
      interval: date.toLowerCase() === "day" ? parseInt(process.env.REACT_APP_DAILY_DATA) :
        date.toLowerCase() === "week" ? parseInt(process.env.REACT_APP_WEEKLY_DATA) :
          date.toLowerCase() === "month" ? parseInt(process.env.REACT_APP_MONTHLY_DATA) :
            date.toLowerCase() === "year" ? parseInt(process.env.REACT_APP_ANNUALY_DATA) :
              date.toLowerCase() === "hour" ? parseInt(process.env.REACT_APP_HOURLY_DATA) :
                date.toLowerCase() === "custom" ? parseInt(process.env.REACT_APP_CUSTOM_DATE_DATA) : null,
      from: fromDate,
      to: toDate,
    };
    try {
      const orderDataPayload = await requestHandler("finance_order_by_market", "post", data, "jwt_token");
      setLoading(false);
      setFinanceData(orderDataPayload?.data?.message);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Orders By Markets"
        classTitle="title-red"
        head={
          <>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={regionValue}
                setValue={setRegionValue}
                options={regionValues}
                small
              />
            </div>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={coinValue}
                setValue={setCoinValue}
                options={coinsData}
                small
              />
            </div>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={date}
                setValue={setDate}
                options={dateFilter}
                small
              />
            </div>
            {
              date.toLowerCase() === "custom" &&
              <>
                <div className={styles.datePickerContainer}>
                  <label className={styles.datePickerLabel}></label>
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => handleFromDateChange(date)}
                    className={styles.customDatePicker}
                    maxDate={currentDate}
                  />
                </div>
                <div className={styles.dropdown}>
                  <label className={styles.datePickerLabel}></label>
                  <DatePicker
                    selected={toDate}
                    onChange={(date) => handleToDateChange(date)}
                    maxDate={currentDate}
                    className={styles.customDatePicker}
                  />
                </div>
              </>
            }
          </>
        }
      >
        <div className={styles.table}>
          {financeData?.map((x, index) => (
            <Row item={x} key={index} />
          ))}
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
