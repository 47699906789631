import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Row from "./Row";
import requestHandler from "../../../actions/httpClient";
import { useEffect } from "react";
import { addNotification } from "../../../components/Notification";
import Pagination from "../../../components/Pagination";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import axios from "axios";

const intervals = ["Pending", "Complete"];

const RecentPost = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [allAddressData, setAllAddressData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    getAllAddress();
  }, [sorting, pageRefreshFlag, pageCount]);
  const getAllAddress = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status:
        sorting.toLowerCase() === "pending"
          ? parseInt(process.env.REACT_APP_WATCHLIST_UPDATE_STATUS_PENDING)
          : sorting.toLowerCase() === "complete"
            ? parseInt(process.env.REACT_APP_WATCHLIST_UPDATE_STATUS_COMPLETED)
            : null,
    };
    try {
      const allAddressPayload = await requestHandler(
        "getMatchListUpdate",
        "post",
        data,
        "jwt_token"
      );
      setLoading(false);
      setAllAddressData(allAddressPayload.data.data);
      setTotalPages(allAddressPayload.data.count || 1);
    } catch (e) {
      setLoading(false);
    }
  };
  const updateAddressHandler = async (id, status) => {
    setLoading(true);
    try {
      const updatedAddressPayload = await requestHandler(
        "updateMatchListUpdate",
        "post",
        {
          signature: localStorage.getItem("signature"),
          id: id,
          status: status,
        },
        "jwt_token"
      );
      setLoading(false);
      getAllAddress();
      const successMessage = updatedAddressPayload.data.message[0].msg;
      addNotification({
        title: "Success",
        message: successMessage || "something went wrong!",
        type: "success",
      });
    } catch (error) {
      const errorMessage = error.data.message[0].msg;
      addNotification({
        title: "Error",
        message: errorMessage || "something went wrong!",
        type: "danger",
      });
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setPageCount(page.selected + 1);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Address"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={intervals}
                small
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>ID</div>
            <div className={styles.col}>User ID</div>
            <div className={styles.col}>Monitor ID</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Created At</div>

            {sorting && sorting.toLowerCase() === "pending" && (
              <div className={styles.col}>Action</div>
            )}
          </div>
          {loading ? (
            <CustomerListSkeleton colCount={8} rowCount={12} />
          ) : allAddressData?.length > 0 ? (
            <>
              {allAddressData?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  sorting={sorting}
                  updateAddressHandler={updateAddressHandler}
                />
              ))}
            </>
          ) : (
            "No data found"
          )}
        </div>
        {allAddressData?.length > 0 && (
          <Pagination
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        )}
      </Card>
    </>
  );
};

export default RecentPost;
