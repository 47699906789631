import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  text,
  setText,
  handleClick,
  toUpperCase
}) => {
  const [visible, setVisible] = useState(false);
  // const handleClick = (cId, cValue) => {
  //   setText(cValue)
  //   setValue(cId);
  //   setVisible(false);
  // };


  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>{toUpperCase ? capitalizeFirstLetter(text) : text}</div>
        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          {options.map((x, index) => (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: x.id === value,
              })}
              onClick={() => {
                setVisible(false); handleClick(x)
              }}
              key={index}
            >
              {toUpperCase ? `${capitalizeFirstLetter(x.name)} (${(x.symbol.toUpperCase())})` : x.name}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
