import React from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";

const UserDocument = ({ className, document }) => {

  function splitLastOccurrence(str, substring) {
    const lastIndex = str.lastIndexOf(substring);
    const after = str.slice(lastIndex + 1);
    return after;
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="KYB Documents"
      classTitle="title-green"
    >
      <div className={styles.fieldset}>
        <div className={styles.main}>
          {document?.map((x, index) =>
          (<div className={styles.images} key={index}>
            {splitLastOccurrence(x?.document_url, ".") === "image/png" ?
              <div className={styles.avatar} onClick={() => { window.open(process.env.REACT_APP_IMAGE_URL + "/" + x?.document_url, '_blank') }}>
                <img src="/images/content/image-placeholder.png" alt="image" />
              </div>
              :
              splitLastOccurrence(x?.document_url, ".") === "image/jpg" ?
                <div className={styles.avatar} onClick={() => { window.open(process.env.REACT_APP_IMAGE_URL + "/" + x?.document_url, '_blank') }}>
                  <img src="/images/content/image-placeholder.png" alt="image" />
                </div>
                :
                splitLastOccurrence(x?.document_url, ".") === "image/jpeg" ?
                  <div className={styles.avatar} onClick={() => { window.open(process.env.REACT_APP_IMAGE_URL + "/" + x?.document_url, '_blank') }}>
                    <img src="/images/content/image-placeholder.png" alt="image" />
                  </div>
                  :
                  splitLastOccurrence(x?.document_url, ".") === "application/pdf" ?
                    <div className={styles.avatar} onClick={() => { window.open(process.env.REACT_APP_IMAGE_URL + "/" + x?.document_url, '_blank') }}>
                      <img src="/images/content/pdf_placeholder.png" alt="pdf" />
                    </div>
                    :
                    <div className={styles.avatar} onClick={() => { window.open(process.env.REACT_APP_IMAGE_URL + "/" + x?.document_url, '_blank') }}>
                      <img src="/images/content/image-placeholder.png" alt="image" />
                    </div>
            }
            <div className={styles.item}>{x?.name}</div>
          </div>)
          )}
        </div>
      </div>
    </Item >
  );
};

export default UserDocument;
