import React from "react";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";

const Promote = ({ id }) => {

  return (
    <>
      <div className={styles.section}>
        <RecentPost id={id} />
      </div>
    </>
  );
};

export default Promote;
