import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import NewPost from "./NewPost";
import CutomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Form from "../../../components/Form";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({
  className,
  setLoading,
  loading,
  walletData,
  setCoinData,
  coinData,
  rechargeCoinHandler,
  getRegionData,
  setVisibleModal,
  visibleModal,
  setSearchField,
  searchField
}) => {

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Coins"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by name or symbol"
              type="text"
              name="search"
              icon="search"
            />
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>Symbol</div>
            <div className={styles.col}>Region Balance</div>
            <div className={styles.col}>Int Balance</div>
            <div className={styles.col}>Recharge</div>
          </div>
          {
            loading ? <CutomerListSkeleton colCount={9} rowCount={12} />
              : walletData?.balances?.filter((data) => {
                return (data.name.toLowerCase().includes(searchField.toLowerCase())) ||
                  (data.symbol.toLowerCase().includes(searchField.toLowerCase()))
              })
                .map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                    setVisibleModal={setVisibleModal}
                    walletData={walletData}
                    setCoinData={setCoinData}
                  />
                ))
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost
          setLoading={setLoading}
          coinData={coinData}
          rechargeCoinHandler={rechargeCoinHandler}
          getRegionData={getRegionData}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
