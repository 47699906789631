import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Row from "./Row";
import requestHandler from "../../../actions/httpClient";
import Form from "../../../components/Form";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import { addNotification } from "../../../components/Notification";

const intervals = [
  "Pending", "Process", "Verified", "Declined"
];

const RecentPost = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [loading, setLoading] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [fiatWithdrawList, setFiatWithdrawList] = useState([]);

  useEffect(() => {
    getFiatWithdrawDataHandler();
  }, [sorting]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const getFiatWithdrawDataHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: sorting.toLowerCase() === "pending" ? parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PENDING) :
        sorting.toLowerCase() === "process" ? parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PROCESS) :
          sorting.toLowerCase() === "verified" ? parseInt(process.env.REACT_APP_FIAT_WITHDRAW_APPROVED) :
            sorting.toLowerCase() === "declined" ? parseInt(process.env.REACT_APP_FIAT_WITHDRAW_DECLINED) :
              sorting.toLowerCase() === "cancelled" ? parseInt(process.env.REACT_APP_FIAT_WITHDRAW_CANCELLED) :
                null,
    };
    try {
      const fiatWithdrawPayload = await requestHandler("getFiatWithdrawList", "post", data, "jwt_token");
      setLoading(false);
      setFiatWithdrawList(fiatWithdrawPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const updateStatusHandler = async (status, id) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: status,
      id: id,
    };
    try {
      const updateStatusPayload = await requestHandler("actionFiatWithdrawList", "post", data, "jwt_token");
      setLoading(false);
      if (updateStatusPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: updateStatusPayload?.data,
          type: "success",
        });
        getFiatWithdrawDataHandler();
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Requests"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by Account number"
              type="text"
              name="search"
              icon="search"
            />
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={sorting}
              setValue={setSorting}
              options={intervals}
              small
            />
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>C Id</div>
            <div className={styles.col}>Account Number</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            {sorting && sorting.toLowerCase() === "pending" ? <div className={styles.col}>Action</div> : ""}
          </div>
          {loading ? <CustomerListSkeleton colCount={6} rowCount={12} />
            :
            fiatWithdrawList.length > 0 ?
              <>
                {
                  fiatWithdrawList?.filter((data) => {
                    return data.account_no.toLowerCase().includes(searchField.toLowerCase())
                  })
                    .map((x, index) => (
                      <Row
                        item={x}
                        key={index}
                        updateStatusHandler={updateStatusHandler} />
                    ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
