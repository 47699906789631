import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";

const Promote = ({ region }) => {
  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    getTransactionListHandler();
  }, []);

  const getTransactionListHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      region: region.toLowerCase(),
    };
    try {
      const getTransactionPayload = await requestHandler("transactionMonitoringValueList", "post", data, "jwt_token");
      setLoading(false);
      setTransactionData(getTransactionPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong",
        type: "danger",
      });
    }
  };

  const enableTransactionHandler = async (id) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      id: id,
    };
    try {
      const enableTransactionPayload = await requestHandler("enableTrasactionMonitoring", "post", data, "jwt_token");
      setLoading(false);
      if (enableTransactionPayload && enableTransactionPayload.status === 200) {
        addNotification({
          title: "Success",
          message: enableTransactionPayload?.data?.message?.msg,
          type: "success",
        });
        getTransactionListHandler();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong",
        type: "danger",
      });
    };
  };

  const disableTransactionHandler = async (id) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      id: id,
    };
    try {
      const disableTransactionPayload = await requestHandler("disableTrasactionMonitoring", "post", data, "jwt_token");
      setLoading(false);
      if (disableTransactionPayload && disableTransactionPayload.status === 200) {
        addNotification({
          title: "Success",
          message: disableTransactionPayload?.data?.message?.msg,
          type: "success",
        });
      }
      getTransactionListHandler();
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong",
        type: "danger",
      });
    };
  };

  const addTransactionHandler = async (module, low, medium, high, interval, duration) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      module: module === "Fiat Deposit" ? process.env.REACT_APP_TRANSACTION_TYPE_FIAT_DEPOSIT_SINGLE :
        module === "Crypto Deposit" ? process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_DEPOSIT_SINGLE :
          module === "Fiat Withdraw" ? process.env.REACT_APP_TRANSACTION_TYPE_FIAT_WITHDRAW_SINGLE :
            module === "Crypto Withdraw" ? process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_WITHDRAW_SINGLE :
              module === "Fiat to Crypto" ? process.env.REACT_APP_TRANSACTION_TYPE_FIAT_TO_CRYPTO_SINGLE :
                module === "Crypto to Fiat" ? process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_TO_FIAT_SINGLE :
                  "",
      low: low,
      medium: medium,
      high: high,
      duration: duration,
      interval: interval?.toLowerCase(),
      region: region?.toLowerCase(),
    };
    try {
      const addTransactionPayload = await requestHandler("addNewTrasactionMonitoring", "post", data, "jwt_token");
      setLoading(false);
      if (addTransactionPayload && addTransactionPayload.status === 200) {
        addNotification({
          title: "Success",
          message: addTransactionPayload?.data?.message?.msg,
          type: "success",
        });
        setVisibleModal(false);
        getTransactionListHandler();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        {loading && <LoaderScreen />}
        <RecentPost
          transactionData={transactionData}
          loading={loading}
          enableTransactionHandler={enableTransactionHandler}
          disableTransactionHandler={disableTransactionHandler}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          addTransactionHandler={addTransactionHandler}
        />
      </div>
    </>
  );
};

export default Promote;
