import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import ProfileInformation from "./ProfileInformation";
import ResidenceInformation from "./ResidenceInformation";
import OrdersInformation from "./OrderHistory";
import WhiteListAddressInfo from "./WhiteListAddressInfo";
import BankInformation from "./BankInformation";
import FiatDepositInfo from "./FiatDepositInfo";
import FiatWithdrawInfo from "./FiatWithdrawInfo";
import CryptoDepositInfo from "./CryptoDepositInfo";
import CryptoWithdrawInfo from "./CryptoWithdrawInfo";
import WalletInformation from "./WalletInformation";
import OpenOrder from './OpenOrder'
import { useHistory, useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";
import LoaderScreen from "../../components/LoaderScreen";
import UserDocuments from './UserDocuments'
import DirectorInfo from './DirectorInfo'
import BitdenexPayDepositInfo from './BitdenexPayDepositInfo'
import BitdenexPayWithdrawInfo from './BitdenexPayWithdrawInfo'
import InternalTransferInfo from './InternalTransferInfo'
import { userDetailsCreator } from "../../actions/userDetails";

const Profile = ({ }) => {
  const navigation = [
    {
      title: "Basic Information",
      status: "profile_info"
    },
    {
      title: "Bank Account Details",
      status: "bank_info"
    },
    {
      title: "Residence Details",
      status: "residence_info"
    },
    {
      title: "Wallet Details",
      status: "wallet_info"
    },
    {
      title: "Order History",
      status: "order_history_info",
    },
    {
      title: "Open Order",
      status: "pending_order_info",
    },
    {
      title: "Addresses",
      status: "address_info"
    },
    {
      title: "Fiat Deposit Details",
      status: process.env.REACT_APP_USER_FIAT_DEPOSIT_INFO
    },
    {
      title: "Fiat Withdraw Details",
      status: process.env.REACT_APP_USER_FIAT_WITHDRAW_INFO
    },
    {
      title: "Crypto Deposit Details",
      status: process.env.REACT_APP_USER_CRYPTO_DEPOSIT_INFO
    },
    {
      title: "Crypto Withdraw Details",
      status: process.env.REACT_APP_USER_CRYPTO_WITHDRAW_INFO
    },
    {
      title: "Kyb Documents",
      status: "user_documents"
    },
    {
      title: "Directors",
      status: "director_detail"
    },
    {
      title: "Bitdenex Pay(Received)",
      status: process.env.REACT_APP_USER_CRYPTO_BITDENEX_PAY_DEPOSIT_INFO
    },
    {
      title: "Bitdenex Pay(Sent)",
      status: process.env.REACT_APP_USER_CRYPTO_BITDENEX_PAY_WITHDRAW_INFO
    },
    {
      title: "USDT Transfer Details",
      status: process.env.REACT_APP_INTERNAL_TRANSFER_INFO
    },
  ];

  const marketingNavigation = [
    {
      title: "Basic Information",
      status: "profile_info"
    },
    {
      title: "Bank Account Details",
      status: "bank_info"
    },
    {
      title: "Residence Details",
      status: "residence_info"
    },
  ];

  const complianceNavigation = [
    {
      title: "Basic Information",
      status: "profile_info"
    },
    {
      title: "Bank Account Details",
      status: "bank_info"
    },
    {
      title: "Residence Details",
      status: "residence_info"
    },
    {
      title: "Wallet Details",
      status: "wallet_info"
    },
    {
      title: "Order History",
      status: "order_history_info",
    },
    {
      title: "Open Order",
      status: "pending_order_info",
    },
    {
      title: "Addresses",
      status: "address_info"
    },
    {
      title: "Fiat Deposit Details",
      status: process.env.REACT_APP_USER_FIAT_DEPOSIT_INFO
    },
    {
      title: "Fiat Withdraw Details",
      status: process.env.REACT_APP_USER_FIAT_WITHDRAW_INFO
    },
    {
      title: "Crypto Deposit Details",
      status: process.env.REACT_APP_USER_CRYPTO_DEPOSIT_INFO
    },
    {
      title: "Crypto Withdraw Details",
      status: process.env.REACT_APP_USER_CRYPTO_WITHDRAW_INFO
    },
    {
      title: "Kyb Documents",
      status: "user_documents"
    },
    {
      title: "Directors",
      status: "director_detail"
    },
    {
      title: "Bitdenex Pay(Received)",
      status: process.env.REACT_APP_USER_CRYPTO_BITDENEX_PAY_DEPOSIT_INFO
    },
    {
      title: "Bitdenex Pay(Sent)",
      status: process.env.REACT_APP_USER_CRYPTO_BITDENEX_PAY_WITHDRAW_INFO
    },
    {
      title: "USDT Transfer Details",
      status: process.env.REACT_APP_INTERNAL_TRANSFER_INFO
    },
  ];

  const options = [];
  navigation.map((x) => options.push(x.title));
  const { id } = useParams();
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0);
  const [profileInfo, setProfileInfo] = useState({});
  const [status, setStatus] = useState("profile_info");
  const { state, document, director } = useLocation();
  const [bankDetails, setBankDetails] = useState([]);
  const [residenceDetails, setResidenceDetails] = useState({});
  const [gatewayList, setGatewayList] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [walletDetails, setWalletDetails] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [fiatDepositData, setFiatDepositData] = useState([]);
  const [fiatWithdrawData, setFiatWithdrawData] = useState([]);
  const [cryptoDepositData, setCryptoDepositData] = useState([]);
  const [cryptoWithdrawData, setCryptoWithdrawData] = useState([]);
  const [openOrderDetails, setOpenOrderDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);
  const [regionData, setRegionData] = useState([]);
  const [wwftDetailsData, setWwftDetailsData] = useState([]);
  const [directorData, setDirectorData] = useState([]);
  const [bitdenexPayDepositData, setBitdenexPayDepositData] = useState([]);
  const [bitdenexPayWithdrawData, setBitdenexPayWithdrawData] = useState([]);
  const [internalTransferData, setInternalTransferData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [active, setActive] = useState(true);
  const { usersDetails } = useSelector((state) => state.userDetails);

  let superadmin = Object.values(usersDetails).includes(process.env.REACT_APP_SUPER_ADMIN_ROLE);
  let complianceOfficer = Object.values(usersDetails).includes((process.env.REACT_APP_COMPLIANCE_OFFICER).toLowerCase());

  useEffect(() => {
    if (Object.entries(usersDetails).length === 0) {
      dispatch(userDetailsCreator());
    }
  }, []);

  const handleMarketOrderClick = () => {
    setActive(!active);
  };

  const handleClick = (x, index) => {
    setStatus(x.status);
    setActiveIndex(index);
  };

  const handlePageClick = () => {
    setPageNo(pageNo + 1);
  };

  useEffect(() => {
    getRegion();
  }, []);

  useEffect(() => {
    getCustomerDetails();
  }, [activeIndex, pageNo, pageRefreshFlag, active]);

  const getCustomerDetails = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      userId: id,
      info_type: status,
      type: active ? parseInt(process.env.REACT_APP_LIMIT_ORDERS)
        : !active ? parseInt(process.env.REACT_APP_MARKET_ORDERS)
          : null,
    };
    try {
      if (data.info_type === "profile_info") {
        const profilePayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setProfileInfo(profilePayload?.data?.data);
      }
      else if (data.info_type === "bank_info") {
        const bankDetailsPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setBankDetails(bankDetailsPayload?.data?.data);

        data.info_type = "wwft_regulation"
        const wwftDetailsPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setWwftDetailsData(wwftDetailsPayload?.data?.data);

        // const gatewayListPayload = await requestHandler("gateway_list", "post", signatureData, "jwt_token");
        // setGatewayList(gatewayListPayload?.data?.data);

      }
      else if (data.info_type === "residence_info") {
        const residencePayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setResidenceDetails(residencePayload?.data?.data);
      }
      else if (data.info_type === "wallet_info") {
        const walletPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setWalletDetails(walletPayload?.data?.data);
      }
      else if (data.info_type === "order_history_info") {
        data["page"] = pageNo;
        const ordersPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setOrderDetails(ordersPayload.data.data);
      }
      else if (data.info_type === "pending_order_info") {
        data["page"] = pageNo;
        const ordersPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setOpenOrderDetails(ordersPayload.data.data);
      }
      else if (data.info_type === "address_info") {
        const addressPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setAddressData(addressPayload.data.data);
      }
      else if (data.info_type === process.env.REACT_APP_USER_FIAT_DEPOSIT_INFO) {
        const fiatDepositPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setFiatDepositData(fiatDepositPayload.data.data);
      }
      else if (data.info_type === process.env.REACT_APP_USER_FIAT_WITHDRAW_INFO) {
        const fiatWithdrawPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setFiatWithdrawData(fiatWithdrawPayload.data.data);
      }
      else if (data.info_type === process.env.REACT_APP_USER_CRYPTO_WITHDRAW_INFO) {
        const cryptoWithdrawPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setCryptoWithdrawData(cryptoWithdrawPayload.data.data);
      }
      else if (data.info_type === process.env.REACT_APP_USER_CRYPTO_DEPOSIT_INFO) {
        const cryptoDepositPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setCryptoDepositData(cryptoDepositPayload.data.data);
      }
      else if (data.info_type === "director_detail") {
        const directorListPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setDirectorData(directorListPayload.data.data);
      }
      else if (data.info_type === process.env.REACT_APP_USER_CRYPTO_BITDENEX_PAY_DEPOSIT_INFO) {
        const bitdenexPayDepositPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setBitdenexPayDepositData(bitdenexPayDepositPayload.data.data);
      }
      else if (data.info_type === process.env.REACT_APP_USER_CRYPTO_BITDENEX_PAY_WITHDRAW_INFO) {
        const bitdenexWithdrawPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setBitdenexPayWithdrawData(bitdenexWithdrawPayload.data.data);
      }
      else if (data.info_type === process.env.REACT_APP_INTERNAL_TRANSFER_INFO) {
        const internalTransferPayload = await requestHandler("get_customer_detail", "post", data, "jwt_token");
        setLoading(false);
        setInternalTransferData(internalTransferPayload.data.data);
      }
      else {
        setLoading(false);
      }
    }
    catch (e) {
      setLoading(false);
    }
  };

  const updateUserHandler = async (profileInfo, region, risk_profile, authText, monitor_kyc_id, mobile_no, country_code, type, buy_fee, sell_fee) => {
    setLoading(true);
    let dummyNumber = mobile_no;
    let finalNumber = dummyNumber.slice(country_code.length);
    const data = {
      userId: id,
      fname: profileInfo.fname,
      lname: profileInfo.lname,
      email_verified: profileInfo.email_verified,
      kyc_verification: profileInfo.kyc_verification,
      residence_verification: profileInfo.residence_verification,
      signature: localStorage.getItem("signature"),
      region: region,
      risk_profile: risk_profile,
      auth_enable: authText,
      monitor_kyc_id: monitor_kyc_id,
      mobile_no: finalNumber,
      country_code: country_code,
      type: type,
      buy_fee: buy_fee,
      sell_fee: sell_fee
    };
    try {
      const updateUserPayload = await requestHandler("updateCustomer", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      if (updateUserPayload && updateUserPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateUserPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    }
  };

  const updateUserBankHandler = async (bankId, bankVerification) => {
    setLoading(true);
    const data = {
      userId: id,
      bankId: bankId,
      bank_verification: bankVerification,
      signature: localStorage.getItem("signature"),
    }
    try {
      const updateUserBankPayload = await requestHandler("updateCustomerBank", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      if (updateUserBankPayload && updateUserBankPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateUserBankPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    }
  };

  const updateAddressHandler = async (e, addressId, address_verification) => {
    setLoading(true);
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    const data = {
      signature: localStorage.getItem("signature"),
      userId: id,
      address_verification: address_verification,
      addressId: addressId
    };
    try {
      const updateAddressPayload = await requestHandler("updateCustomerAddress", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      if (updateAddressPayload && updateAddressPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateAddressPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    }
  };

  const getRegion = async () => {
    setLoading(true);
    try {
      const regionPayload = await requestHandler("region", "post");
      const newDropDownValue = regionPayload?.data?.data?.map((x) => { return { "id": x.id, "name": x.name, "slug": x.slug } });
      setLoading(false);
      setRegionData([...newDropDownValue]);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const updateUserResidenceAddressHandler = async (residenceDetails) => {
    setLoading(true);
    const data = {
      userId: residenceDetails.userId,
      status: residenceDetails.status,
      residenceId: residenceDetails.id,
      signature: localStorage.getItem("signature"),
      address1: residenceDetails.address1,
      address2: residenceDetails.address2,
      city: residenceDetails.city,
      state: residenceDetails.state,
      country: residenceDetails.country,
      zip_code: residenceDetails.zip_code
    };
    try {
      const updateUserResidencePayload = await requestHandler("updateCustomerResidence", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      if (updateUserResidencePayload && updateUserResidencePayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateUserResidencePayload?.data?.message[0]?.msg,
          type: 'Success'
        });
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    }
  };

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.menu}>
          {
            superadmin ?
              navigation.map((x, index) => (
                <button
                  className={cn(styles.button, {
                    [styles.active]: activeIndex === index,
                  })}
                  key={index}
                  onClick={() => handleClick(x, index)}
                >
                  {x.title}
                </button>
              ))
              :
              complianceOfficer ?
                complianceNavigation.map((x, index) => (
                  <button
                    className={cn(styles.button, {
                      [styles.active]: activeIndex === index,
                    })}
                    key={index}
                    onClick={() => handleClick(x, index)}
                  >
                    {x.title}
                  </button>
                ))
                :
                marketingNavigation.map((x, index) => (
                  <button
                    className={cn(styles.button, {
                      [styles.active]: activeIndex === index,
                    })}
                    key={index}
                    onClick={() => handleClick(x, index)}
                  >
                    {x.title}
                  </button>
                ))
          }
        </div>
        <div className={styles.wrapper}>
          {loading && <LoaderScreen />}
          <div className={styles.list}>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 0 && (
                <ProfileInformation
                  superadmin={superadmin}
                  state={state}
                  profileInfo={profileInfo}
                  setProfileInfo={setProfileInfo}
                  updateUserHandler={updateUserHandler}
                  regionData={regionData}
                />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 1 && (
                <BankInformation
                  superadmin={superadmin}
                  profileInfo={profileInfo}
                  gatewayList={gatewayList}
                  bankDetails={bankDetails}
                  updateUserBankHandler={updateUserBankHandler}
                  wwftDetailsData={wwftDetailsData}
                />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 2 && (
                <ResidenceInformation
                  superadmin={superadmin}
                  profileInfo={profileInfo}
                  residenceDetails={residenceDetails}
                  updateUserResidenceAddressHandler={updateUserResidenceAddressHandler}
                  setResidenceDetails={setResidenceDetails}
                />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 3 && (
                <WalletInformation
                  walletDetails={walletDetails}
                />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex === 4 && (
                <OrdersInformation
                  orderDetails={orderDetails}
                  handlePageClick={handlePageClick}
                  setOrderDetails={setOrderDetails}
                  pageNo={pageNo}
                  loading={loading}
                  setLoading={setLoading}
                  handleMarketOrderClick={handleMarketOrderClick}
                  active={active}
                />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 5 && (
                <OpenOrder
                  orderDetails={openOrderDetails}
                  handlePageClick={handlePageClick}
                  pageNo={pageNo}
                />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 6 && (
                <WhiteListAddressInfo
                  superadmin={superadmin}
                  addressData={addressData}
                  updateAddressHandler={updateAddressHandler}
                />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 7 && (
                <FiatDepositInfo fiatDepositData={fiatDepositData} />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 8 && (
                <FiatWithdrawInfo fiatWithdrawData={fiatWithdrawData} />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 9 && (
                <CryptoDepositInfo cryptoDepositData={cryptoDepositData} />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 10 && (
                <CryptoWithdrawInfo
                  superadmin={superadmin}
                  cryptoWithdrawData={cryptoWithdrawData} />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 11 && (
                <UserDocuments
                  profileInfo={profileInfo}
                  document={document}
                />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex == 12 && (
                <DirectorInfo
                  superadmin={superadmin}
                  profileInfo={profileInfo}
                  director={directorData}
                  getCustomerDetails={getCustomerDetails}
                />)}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex === 13 && (
                <BitdenexPayDepositInfo
                  bitdenexPayDepositData={bitdenexPayDepositData}
                />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex === 14 && (
                <BitdenexPayWithdrawInfo
                  bitdenexPayWithdrawData={bitdenexPayWithdrawData}
                />
              )}
            </div>
            <div>
              <div className={styles.anchor} ></div>
              {activeIndex === 15 && (
                <InternalTransferInfo
                  internalTransferData={internalTransferData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Profile;
