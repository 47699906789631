import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import OrderDropdown from "../../../components/OrderDropdown";
import TextInput from "../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import requestHandler from "../../../actions/httpClient";
import { addNotification } from "../../../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { getRegionCreator } from "../../../actions/getRegions";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router";
import LoaderScreen from "../../../components/LoaderScreen";

const Form = ({ className }) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [buyFee, setBuyFee] = useState("");
  const [sellFee, setSellFee] = useState("");
  const [businessAddressOne, setBusinessAddressOne] = useState("");
  const [businessAddressTwo, setBusinessAddressTwo] = useState("");
  const [businessCity, setBusinessCity] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [businessCountry, setBusinessCountry] = useState("");
  const [businessZipCode, setBusinessZipCode] = useState("");
  const [businessAddressProof, setBusinessAddressProof] = useState();
  const [regionData, setRegionData] = useState([]);
  const [regionText, setRegionText] = useState("Please select a value");
  const [regionValue, setRegionValue] = useState("");
  const { getRegionData } = useSelector((state) => state.getRegion);
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState("");
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (getRegionData?.length === 0) {
      dispatch(getRegionCreator());
    }
    const newDropDownValue = getRegionData?.map(x => {
      return { "id": x.id, "name": x.name }
    });
    setRegionData([...newDropDownValue]);
  }, [getRegionData]);

  const submitHandler = async () => {
    setLoading(true);
    let dummyNumber = businessPhoneNumber;
    let finalNumber = dummyNumber.slice(countryCode.length);
    let data = {
      signature: localStorage.getItem("signature"),
      email: businessEmail,
      business_name: businessName,
      country_code: countryCode,
      mobile_no: finalNumber,
      region: regionValue,
      business_type: businessType,
      buy_fee: buyFee,
      sell_fee: sellFee,
      address1: businessAddressOne,
      address2: businessAddressTwo,
      city: businessCity,
      state: businessState,
      country: businessCountry,
      zip_code: businessZipCode,
      proof: businessAddressProof,
    };
    try {
      const addCorporatePayload = await requestHandler("createNewCorporateUser", "post", data, "jwt_token", "file");
      setLoading(false);
      if (addCorporatePayload && addCorporatePayload.status === 200) {
        addNotification({
          title: 'Success',
          message: addCorporatePayload?.data?.message[0]?.msg,
          type: 'success'
        });
        navigate("/corporate/pending-corporate");
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    }
  };

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Business Name"
            name="name"
            type="text"
            onChange={(e) => setBusinessName(e.target.value)}
            placeholder="Please enter business name"
            required
            onBlur={() => simpleValidator.current.showMessageFor("name")}
          />
          {simpleValidator.current.message("name", businessName, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Business type"
            name="type"
            type="text"
            onChange={(e) => setBusinessType(e.target.value)}
            placeholder="Please enter business type"
            required
            onBlur={() => simpleValidator.current.showMessageFor("type")}
          />
          {simpleValidator.current.message("type", businessType, "required")}
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Business Email"
            name="businessEmail"
            type="email"
            onChange={(e) => setBusinessEmail(e.target.value)}
            placeholder="Please enter email"
            required
            onBlur={() => simpleValidator.current.showMessageFor("businessEmail")}
          />
          {simpleValidator.current.message("businessEmail", businessEmail, "required|email")}
        </div>
        <div className={styles.customField}>
          <div className={styles.box}>
            <p>Business Mobile Number</p>
            <PhoneInput
              className={styles.field}
              ref={inputRef}
              country={'us'}
              value={businessPhoneNumber}
              name="phone"
              countryCode
              inputProps={{
                name: 'phone',
                required: true,
              }}
              onChange={(phone, country) => {
                setBusinessPhoneNumber(phone)
                setCountryCode(country.dialCode);
              }}
              onBlur={() => simpleValidator.current.showMessageFor("phone")}
            />
            {simpleValidator.current.message("phone", businessPhoneNumber, "required|min:8")}
          </div>
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Buy Fee"
            name="buyFee"
            type="text"
            value={buyFee}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBuyFee(e.target.value)
              }
            }}
            placeholder="Please enter buy fee"
            required
            onBlur={() => simpleValidator.current.showMessageFor("buyFee")}
          />
          {simpleValidator.current.message("buyFee", buyFee, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Sell Fee"
            name="sellFee"
            type="text"
            value={sellFee}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setSellFee(e.target.value)
              }
            }}
            placeholder="Please enter sell fee"
            required
            onBlur={() => simpleValidator.current.showMessageFor("sellFee")}
          />
          {simpleValidator.current.message("sellFee", sellFee, "required")}
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <div className={styles.dropdown}>
            <OrderDropdown
              label="Region"
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={regionValue}
              setValue={setRegionValue}
              text={regionText}
              setText={setRegionText}
              options={regionData}
            />
          </div>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Business Address 1"
            name="businessAddress"
            type="text"
            placeholder="Please enter address"
            onChange={(e) => setBusinessAddressOne(e.target.value)}
            required
            onBlur={() => simpleValidator.current.showMessageFor("businessAddress")}
          />
          {simpleValidator.current.message("businessAddress", businessAddressOne, "required")}
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Business Address 2"
            name="businessAddress"
            placeholder="Please enter address"
            type="text"
            required
            onChange={(e) => setBusinessAddressTwo(e.target.value)}
            onBlur={() => simpleValidator.current.showMessageFor("businessAddress")}
          />
          {simpleValidator.current.message("businessAddress", businessAddressTwo, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="City"
            name="city"
            placeholder="Please enter city"
            type="text"
            onChange={(e) => setBusinessCity(e.target.value)}
            required
            onBlur={() => simpleValidator.current.showMessageFor("city")}
          />
          {simpleValidator.current.message("city", businessCity, "required")}
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="State"
            name="state"
            placeholder="Please enter state"
            type="text"
            required
            onChange={(e) => setBusinessState(e.target.value)}
            onBlur={() => simpleValidator.current.showMessageFor("state")}
          />
          {simpleValidator.current.message("state", businessState, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Country"
            name="country"
            placeholder="Please enter country"
            type="text"
            onChange={(e) => setBusinessCountry(e.target.value)}
            required
            onBlur={() => simpleValidator.current.showMessageFor("country")}
          />
          {simpleValidator.current.message("country", businessCountry, "required")}
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Zip code"
            name="zipCode"
            placeholder="Please enter zip code"
            type="text"
            value={businessZipCode}
            required
            onChange={(e) => {
              const re = /^\d+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBusinessZipCode(e.target.value)
              }
            }}
            onBlur={() => simpleValidator.current.showMessageFor("zipCode")}
          />
          {simpleValidator.current.message("zipCode", businessZipCode, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Business Address Proof"
            name="businessAddressProof"
            type="file"
            onChange={(e) => setBusinessAddressProof(e.target.files[0])}
            required
            onBlur={() => simpleValidator.current.showMessageFor("businessAddressProof")}
          />
          {simpleValidator.current.message("businessAddressProof", businessAddressProof, "required")}
        </div>
      </div>
      <div className={styles.control}>
        <button className={cn("button", styles.button)} onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (regionText !== "Please select a value") {
              submitHandler();
            }
            else {
              addNotification({
                title: 'Error',
                message: "Please select region",
                type: 'danger'
              });
            }
          } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
          }
        }
        }>
          <span>Save Changes</span>
        </button>
      </div>
    </Card >
  );
};

export default Form;
