import React from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../../components/helper";

const Share = ({ selectedTransaction }) => {

  function convertSnakeCaseToTitleCase(str) {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <>
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Transaction Details</div>
        {
          selectedTransaction?.fiat_reference?.length > 0 &&
          <div className={styles.wrapper}>
            <div className={styles.text}>
              {convertSnakeCaseToTitleCase(selectedTransaction?.module)}
            </div>
            <div className={styles.row}>
              <div className={styles.col}>Amount</div>
              <div className={styles.col}>Reference</div>
              <div className={styles.col}>Date</div>
            </div>
            {
              selectedTransaction?.transactionDetail?.map((x, index) => {
                return (
                  <div className={styles.row} key={index}>
                    <div className={styles.col}>
                      {`${x?.amount} ${x?.currency}`}
                    </div>
                    <div className={styles.col}>
                      {x?.reference}
                    </div>
                    <div className={styles.col}>
                      {toLocaleStringDateFormat(x?.createdAt)}
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
        {
          selectedTransaction?.orderId?.length > 0 &&
          <div className={styles.wrapper}>
            <div className={styles.text}>
              Market Orders
            </div>
            <div className={styles.row}>
              <div className={styles.col}>Amount</div>
              <div className={styles.col}>Market</div>
              <div className={styles.col}>Type</div>
              <div className={styles.col}>Order Id</div>
            </div>
            {selectedTransaction?.transactionDetail[0]?.marketOrder?.length > 0 ?
              selectedTransaction?.transactionDetail[0]?.marketOrder?.map((x, index) => {
                return (
                  <div className={styles.row} key={index}>
                    <div className={styles.col}>
                      {parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) === x?.orderType ? `${getDigitsAfterDecimal(x?.fiatSpend, 2)} ${x.slug.split("-")[1].toUpperCase()}`
                        :
                        `${(getDigitsAfterDecimal((parseFloat(x?.cryptoFee) * parseFloat(x?.fiatPrice)) + parseFloat(x?.exeFiat), 8))} ${x.slug.split("-")[1].toUpperCase()}`
                      }
                    </div>
                    <div className={styles.col}>
                      {x.slug.toUpperCase()}
                    </div>
                    <div className={styles.col}>
                      {parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) === x?.orderType ? "Buy" : "Sell"}
                    </div>
                    <div className={styles.col}>
                      {x?.orderId}
                    </div>
                  </div>
                )
              })
              :
              <div className={styles.text}>
                No Data Found
              </div>
            }
          </div>
        }
        {
          selectedTransaction?.orderId?.length > 0 &&
          <div className={styles.wrapper}>
            <div className={styles.text}>
              Limit Orders
            </div>
            <div className={styles.row}>
              <div className={styles.col}>Amount</div>
              <div className={styles.col}>Market</div>
              <div className={styles.col}>Type</div>
              <div className={styles.col}>Order Id</div>
            </div>
            {
              selectedTransaction?.transactionDetail[0]?.order?.map((x, index) => {
                return (
                  <div className={styles.row} key={index}>
                    <div className={styles.col}>
                      {parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) === x?.orderType ? `${getDigitsAfterDecimal(x?.fiatSpend, 2)} ${x.slug.split("-")[1].toUpperCase()}`
                        :
                        `${getDigitsAfterDecimal(((parseFloat(x?.cryptoFee) * parseFloat(x?.fiatPrice)) + parseFloat(x?.exeFiat)), 2)} ${x.slug.split("-")[1].toUpperCase()}`
                      }
                    </div>
                    <div className={styles.col}>
                      {x.slug.toUpperCase()}
                    </div>
                    <div className={styles.col}>
                      {parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) === x?.orderType ? "Buy" : "Sell"}
                    </div>
                    <div className={styles.col}>
                      {x?.orderId}
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
        {
          selectedTransaction?.crypto_txid?.length > 0 &&
          <div className={styles.wrapper}>
            <div className={styles.text}>
              {convertSnakeCaseToTitleCase(selectedTransaction?.module)}
            </div>
            <div className={styles.row}>
              <div className={styles.col}>Amount</div>
              <div className={styles.col}>Transaction Id</div>
              <div className={styles.col}>Date</div>
            </div>
            {
              selectedTransaction?.transactionDetail?.map((x, index) => {
                return (
                  <div className={styles.row} key={index}>
                    <div className={styles.col}>
                      {`${x?.amount} ${x?.currency.toUpperCase()}`}
                    </div>
                    <div className={styles.col}>
                      {x?.txid}
                    </div>
                    <div className={styles.col}>
                      {toLocaleStringDateFormat(x?.createdAt)}
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
      </div>
    </>
  );
};

export default Share;