import React, { useState } from "react";
import cn from "classnames";
import styles from "./Refunds.module.sass";

// data
import { refunds } from "../../../mocks/refunds";
import Card from "../../../components/Card";
import NoDataFound from "../../../components/NoDataFound";
import Row from "./Row";
import Loader from "../../../components/Loader";

const navigation = ["Open requests", "Closed requests"];

const Refunds = ({ orderDetails, handlePageClick, pageNo }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title="Orders Information"
      classTitle={cn("title-purple", styles.title)}
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Id</div>
            <div className={styles.col}>Market</div>
            <div className={styles.col}>Type</div>
            <div className={styles.col}>Price</div>
            <div className={styles.col}>Crypto Amount</div>
            <div className={styles.col}>Fiat Amount</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
         
          {
            orderDetails?.length > 0 ?
              <>
                {orderDetails?.map((x, index) => (
                  <Row item={x} key={index} orderDetails={orderDetails} />
                ))}
                {
                  orderDetails?.length === (10 * pageNo) && <div className={styles.customButton}>
                    <button className={cn("button-stroke button-small", styles.button)} onClick={() => { handlePageClick() }}>
                      <span>Load more</span>
                    </button>
                  </div>
                }
              </>
              :
              "No data found"
          }
            
        </div>
      
    
          </div>
    
     
   
       
       
    </Card>
  );
};

export default Refunds;
