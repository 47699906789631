import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import Form from "../../../components/Form";
import Dropdown from "../../../components/Dropdown";
import Pagination from "../../../components/Pagination";
import requestHandler from "../../../actions/httpClient";
import { addNotification } from "../../../components/Notification";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const searchValues = ["Id", "UId", "Email", "First Name"];
const RecentPost = ({ className }) => {
  const [searchField, setSearchField] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [order, setOrder] = useState("DSC");
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(searchValues[0]);
  const [totalPages, setTotalPages] = useState();
  const [pageCount, setPageCount] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handlePageChange = (page) => {
    setPageCount(page.selected + 1);
  };

  const userSearch = async () => {
    setLoading(true);
    const data = {
      type: searchValue === "First Name" ? "fname" : searchValue.toLowerCase(),
      search_text: searchField,
      signature: localStorage.getItem("signature"),
      pageno: pageCount,
    };
    try {
      if (data.search_text === "") {
        setLoading(false);
        addNotification({
          title: 'Error',
          message: "Search field can not be empty.",
          type: 'danger'
        });
      }
      else {
        const userSearchPayload = await requestHandler("userListSearch", "post", data, "jwt_token");
        setLoading(false);
        setUsersData(userSearchPayload?.data?.message?.data);
        setTotalPages(userSearchPayload?.data?.message?.count);
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    };
  };

  const handleOrder = (e) => {
    const sorted =
      order && order === "ASC"
        ? usersData.sort((a, b) =>
          a["region"] > b["region"] ? 1 : -1
        )
        : usersData.sort((a, b) =>
          a["region"] < b["region"] ? 1 : -1
        );
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setUsersData(sorted);
    setOrder(sortingType);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Customers"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={searchValue}
                setValue={setSearchValue}
                options={searchValues}
                small
              />
            </div>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.control}>
              <button className={cn("button-small", styles.dropdown)} onClick={() => { userSearch(); }}>
                <span>Submit</span>
              </button>
            </div>
          </>
        }
      >
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>C Id</div>
              <div className={styles.col}>Customer</div>
              <div className={styles.col}>U Id</div>
              <div className={(styles.col, styles.sortIcon)} onClick={() => handleOrder()}>Region</div>
              <div className={styles.col}>Bank Status</div>
              <div className={styles.col}>KYC Status</div>
              <div className={styles.col}>Residence Status</div>
              <div className={styles.col}>Registration Date</div>
            </div>
            {
              loading ? <CustomerListSkeleton colCount={8} rowCount={1} /> :
                usersData?.map((x, index) => (
                  <Row item={x} key={index} />
                ))}
          </div>
        </div>
        {
          usersData?.length > 0 &&
          <Pagination
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        }
      </Card>
    </>
  );
};

export default RecentPost;
