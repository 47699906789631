import React from "react";
import styles from "./Cell.module.sass";
import Balance from "../../../../../components/Balance";

const Cell = ({ item }) => {

  return (
    <div className={styles.cell}>
      <Balance className={styles.balance} value={item} />
    </div>
  );
};

export default Cell;
