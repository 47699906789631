import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import LoaderScreen from "../../../components/LoaderScreen";
import Pagination from "../../../components/Pagination";
import OrderDropdown from "../../../components/OrderDropdown";
import Row from "./Row";
import Form from "../../../components/Form";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Dropdown from "../../../components/Dropdown";

const RecentPost = ({ className, usersData, status,
  kycReminderHandler,
  loading,
  emailReminderHandler,
  handleOrder,
  order,
  handleRiskOrder,
  regionData,
  setRegionText,
  regionText,
  regionValue,
  setRegionValue,
  updateUserHandler,
  reactiveUserEmailReminderHandler,
  setLoading,
  exportedData,
  setExportedData,
  ExportReactCSV,
  superadmin,
  kycReminderSelectedUsersHandler,
  selectedItems,
  setSelectedItems,
  totalPages,
  handlePageChange,
  searchValue,
  setSearchValue,
  searchValues,
  searchFieldHandler,
  dataToExport,
  complianceOfficer,
  rkmManager
}) => {
  const [searchField, setSearchField] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <Card
        className={cn(styles.card, className)}
        title="Customers"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={searchValue}
                setValue={setSearchValue}
                options={searchValues}
                small
              />
            </div>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by name, email or customer id"
              type="text"
              name="search"
              icon="search"
            />
            <button className={cn("button-small", styles.dropdown)} type="button"
              onClick={(e) => {
                e.preventDefault();
                searchFieldHandler(searchField);
              }}
            >
              Search
            </button>
            <div className={styles.dropdown}>
              <OrderDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={regionValue}
                setValue={setRegionValue}
                text={regionText}
                setText={setRegionText}
                options={regionData}
              />
            </div>
            {/* {status === process.env.REACT_APP_KYCVERIFICATIONPENDINGSTRING &&
              <button className={cn("button", styles.kycButton)} type="button"
                onClick={() => {
                  kycReminderHandler();
                }}
              >
                <span>Send KYC Reminder</span>
              </button>
            } */}
            {/* {status === process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS_STRING &&
              <button className={cn("button", styles.kycButton)} type="button"
                onClick={() => {
                  emailReminderHandler();
                }}
              >
                <span>Send Email Reminder</span>
              </button>} */}
            {/* {status === process.env.REACT_APP_REACTIVATE_CUSTOMERS_STRING &&
              <button className={cn("button", styles.kycButton)} type="button"
                onClick={() => {
                  reactiveUserEmailReminderHandler();
                }}
              >
                <span>Send Email Reminder</span>
              </button>} */}
            {
              (status === process.env.REACT_APP_REACTIVATE_CUSTOMERS_STRING) ||
                (status === process.env.REACT_APP_KYCVERIFICATIONCOMPLETESTRING) ||
                (status === process.env.REACT_APP_FREEZE_EMAIL_CUSTOMERS_STRING) ||
                (status === process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS_STRING) ||
                (status === process.env.REACT_APP_KYCVERIFICATIONPENDINGSTRING) ?
                <>
                  {(superadmin || complianceOfficer || rkmManager) && <button className={cn("button")} type="button">
                    <ExportReactCSV csvData={dataToExport} fileName="Customers" />
                  </button>}
                </> : ""}
          </>
        }
      >
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.row}>
              {(status === process.env.REACT_APP_KYCVERIFICATIONPENDINGSTRING ||
                status === process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS_STRING ||
                status === process.env.REACT_APP_REACTIVATE_CUSTOMERS_STRING
              ) && <div className={styles.col}>#</div>}
              <div className={styles.col}>C Id</div>
              <div className={styles.col}>Customer</div>
              <div className={styles.col}>U Id</div>
              <div className={(styles.col, styles.sortIcon)} onClick={() => handleOrder()}>{order === "DSC" ? "Region ▲" : "Region ▼"}</div>
              {status !== process.env.REACT_APP_KYCVERIFICATIONINPROCESSSTRING && (<div className={styles.col}>Bank Status</div>)}
              {status !== process.env.REACT_APP_KYCVERIFICATIONINPROCESSSTRING && (<div className={styles.col}>KYC Status</div>)}
              {status !== process.env.REACT_APP_KYCVERIFICATIONINPROCESSSTRING && (<div className={styles.col}>Residence Status</div>)}
              <div className={styles.col}>Registration Date</div>
              <div className={(styles.col, styles.sortIcon)} onClick={() => { handleRiskOrder() }}>{order === "DSC" ? "Risk ▲" : "Risk ▼"}</div>
              {(status == process.env.REACT_APP_KYCVERIFICATIONINPROCESSSTRING && superadmin) && (<div className={styles.col}>Action</div>)}
            </div>
            {loading ? <CustomerListSkeleton colCount={9} rowCount={12} />
              : usersData?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  order={order}
                  region={x?.region}
                  status={status}
                  updateUserHandler={updateUserHandler}
                  superadmin={superadmin}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                />
              ))
            }
          </div>
        </div>
        {usersData?.length > 0 && ((status === process.env.REACT_APP_KYCVERIFICATIONPENDINGSTRING) ||
          (status === process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS_STRING) ||
          (status === process.env.REACT_APP_REACTIVATE_CUSTOMERS_STRING)) &&
          <div className={styles.kycReminderButton}>
            <button className={cn("button")} type="button"
              onClick={() => {
                kycReminderSelectedUsersHandler(selectedItems);
              }}
            >
              <span>Send Email Reminder</span>
            </button>
          </div>}
        {
          usersData?.length > 0 &&
          <Pagination
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        }
      </Card>
    </>
  );
};

export default RecentPost;
