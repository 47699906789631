import React from "react";
import styles from "./NewProduct.module.sass";
import Form from "./Form";

const NewProduct = () => {

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Form className={styles.card} />
        </div>
      </div>
    </>
  );
};

export default NewProduct;
