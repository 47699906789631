import React, { useRef, useState } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import TextInput from "../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import requestHandler from "../../../../actions/httpClient";
import { addNotification } from "../../../../components/Notification";
import LoaderScreen from "../../../../components/LoaderScreen";
import Onfido from "../../../../components/Onfido";

const Details = ({ profileInfo, setVisibleModal, getCustomerDetails }) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [countryCode, setCountryCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [identityProof, setIdentityProof] = useState(null);
  const [addressProof, setAddressProof] = useState(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [token, setToken] = useState("");
  const [workFlowRunId, setWorkFlowRunId] = useState("");
  const [userId, setUserId] = useState("");

  const addDirectorHandler = async () => {
    setLoading(true);
    var finalNumber;
    if (phoneNumber?.length > 0) {
      let dummyNumber = phoneNumber;
      let dummyPhoneNumber = dummyNumber.slice(countryCode.length);
      finalNumber = dummyPhoneNumber;
    }
    let data = {
      signature: localStorage.getItem("signature"),
      fname: firstName,
      lname: lastName,
      email: email,
      country_code: countryCode,
      mobile_no: finalNumber,
      userId: profileInfo?.id,
    };
    try {
      const addDirectorPayload = await requestHandler("createCorporateDirector", "post", data, "jwt_token");
      setLoading(false);
      if (addDirectorPayload && addDirectorPayload.status === 200) {
        setToken(addDirectorPayload?.data?.data?.sdk_token);
        setWorkFlowRunId(addDirectorPayload?.data?.data?.workflow_run_id);
        setUserId("");
        setUserId(addDirectorPayload?.data?.data?.id);
        setActiveIndex(1);
      }
    }
    catch (e) {
      setLoading(false);
      setVisibleModal(false);
    };
  };

  return (
    <>
      {activeIndex === 0 &&
        (<div className={styles.details}>
          {loading && <LoaderScreen />}
          <div className={cn("title-purple", styles.title)}>Add Director</div>
          <div className={styles.description}>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="First Name"
                name="firstName"
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Please enter first name"
                required
                onBlur={() => simpleValidator.current.showMessageFor("firstName")}
              />
              {simpleValidator.current.message("firstName", firstName, "required")}
            </div>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="Last Name"
                name="lastName"
                type="text"
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Please enter last name"
                required
                onBlur={() => simpleValidator.current.showMessageFor("lastName")}
              />
              {simpleValidator.current.message("lastName", lastName, "required")}
            </div>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="Email"
                name="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Please enter email"
                required
                onBlur={() => simpleValidator.current.showMessageFor("email")}
              />
              {simpleValidator.current.message("email", email, "required|email")}
            </div>
            <div className={styles.customField}>
              <div className={styles.box}>
                <p>Business Mobile Number</p>
                <PhoneInput
                  className={styles.field}
                  ref={inputRef}
                  country={'us'}
                  name="phone"
                  value={phoneNumber}
                  inputProps={{
                    name: 'phone',
                    required: true,
                  }}
                  countryCode
                  onChange={(phone, country) => {
                    setPhoneNumber(phone)
                    setCountryCode(country.dialCode)
                  }}
                  onBlur={() => simpleValidator.current.showMessageFor("phone")}
                />
                {simpleValidator.current.message("phone", phoneNumber, "required")}
              </div>
            </div>
          </div>
          <div className={styles.control}>
            <button className={cn("button", styles.button)} onClick={(e) => {
              e.preventDefault();
              if (simpleValidator.current.allValid()) {
                addDirectorHandler();
              } else {
                simpleValidator.current.showMessages();
                forceUpdate(1);
              }
            }
            }>
              <span>Continue</span>
            </button>
          </div>
        </div>)
      }
      {activeIndex === 1 && (
        <Onfido
          getCustomerDetails={getCustomerDetails}
          token={token}
          workFlowRunId={workFlowRunId}
          setVisibleModal={setVisibleModal}
          userId={userId}
        />
      )}

    </>
  );
};

export default Details;
