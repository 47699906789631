import React from "react";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../../components/helper";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item, statusValue }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.currency}>
          <div className={styles.details}>
            {statusValue === parseInt(process.env.REACT_APP_COUPON_TYPE_PAYOUT_1) ?
              <div className={styles.info}>{item?.currency === undefined || item?.currency === null ? "---" : item?.currency?.toUpperCase()}</div>
              :
              (statusValue === parseInt(process.env.REACT_APP_COUPON_TYPE_PAYOUT_2)
                &&
                (item.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER)) ||
                (item.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER))) ?
                <div className={styles.info}>{item?.currency?.toUpperCase()}</div>
                :
                (statusValue === parseInt(process.env.REACT_APP_COUPON_TYPE_PAYOUT_2)
                  &&
                  (item.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER)) ||
                  (item.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)))
                  ?
                  <div className={styles.info}>{item?.coin?.toUpperCase()}</div>
                  :
                  ""
            }
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.info}>{getDigitsAfterDecimal(item?.amount, 8)}</div>
      </div>
      <div className={styles.col}>
        <div className={styles.info}>{item?.orderId}</div>
      </div>
      <div className={styles.col}>
        {item?.type === parseInt(process.env.REACT_APP_COUPON_TYPE_PAYOUT_1) ? (
          <div className={cn("category-green", styles.details)}>Cashback</div>
        ) :
          <div className={cn("category-green", styles.details)}>Commission</div>
        }
      </div>
      <div className={styles.col}>
        <div className={styles.info}>{toLocaleStringDateFormat(item?.createdAt)}</div>
      </div>
    </div>
  );
};

export default Row;
