import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import LoaderScreen from "../../../components/LoaderScreen";
import { addNotification } from "../../../components/Notification";
import Dropdown from "../../../components/Dropdown";
import CustomDropdown from "../../../components/CustomDropdown";
import SimpleReactValidator from 'simple-react-validator';

const userRoleOptions = [
  { "id": process.env.REACT_APP_MODERATOR, "name": "Moderator" },
  { "id": process.env.REACT_APP_RK_MANAGER, "name": "RK Manager" },
  { "id": process.env.REACT_APP_COMPLIANCE_OFFICER, "name": "Compliance Officer" },
  { "id": process.env.REACT_APP_MARKETEING_USER, "name": "Marketing User" },
];

const ProfileInformation = ({ className, updateUserBalancehandler, loading }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userRoleText, setUserRoleText] = useState("Select a value");
  const [userRoleValue, setUserRoleValue] = useState("");
  const [role, setRole] = useState('Select user role');
  const optionsRole = process.env.REACT_APP_USER_ROLES.split(',');
  const [passwordType, setPasswordType] = useState("password");
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [userId, setUserId] = useState("");
  const [coinSymbol, setcoinSymbol] = useState("");
  const [amount, setAmount] = useState("");
  const [region, setRegion] = useState("");

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    };
    setPasswordType("password")
  };

  const userRoleHandler = (x) => {
    setUserRoleText(x.name);
    setUserRoleValue(x.id);
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Update User Balance"
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <div className={styles.field}>
        <TextInput
          className={styles.className}
          label="User Id"
          name="userId"
          placeholder='Please enter a value'
          value={userId}
          type="text"
          onChange={(e) => setUserId(e.target.value)}
          required
          onBlur={() => simpleValidator.current.showMessageFor("userId")}
        />
        {simpleValidator.current.message("userId", userId, "required")}
      </div>
      <div className={styles.field}>

        <TextInput
          className={styles.className}
          label="Coin Symbol"
          name="coinSymbol"
          placeholder='Please enter a value'
          value={coinSymbol}
          type="text"
          onChange={(e) => setcoinSymbol(e.target.value)}
          required
          onBlur={() => simpleValidator.current.showMessageFor("coinSymbol")}
        />
        {simpleValidator.current.message("coinSymbol", coinSymbol, "required")}
      </div>
      <div className={styles.field}>

        <TextInput
          className={styles.className}
          label="Amount"
          name="amount"
          value={amount}
          placeholder='Please enter a value'
          type="email"
          onChange={(e) => setAmount(e.target.value)}
          required
          onBlur={() => simpleValidator.current.showMessageFor("amount")}
        />
        {simpleValidator.current.message("amount", amount, "required")}
      </div>
      <div className={styles.field}>
        <TextInput
          className={styles.className}
          label="Region"
          name="region"
          placeholder='Please enter a value'
          value={region}
          onChange={(e) => setRegion(e.target.value)}
          onBlur={() => simpleValidator.current.showMessageFor("region")}
        />
        {simpleValidator.current.message("region", region, "required")}
      </div>
      <div className={styles.field}>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              updateUserBalancehandler(userId, coinSymbol, amount, region);
            }
            else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }}
          className={cn("button", styles.button)}
        >
          Submit
        </button>
      </div>
    </Item >
  );
};

export default ProfileInformation;
