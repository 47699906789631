import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import CustomDropdown from "../../../../components/CustomDropdown";
import Row from "../RecentPost/Row";
import requestHandler from "../../../../actions/httpClient";
import LoaderScreen from "../../../../components/LoaderScreen";

var statusOptions = [
  { "id": parseInt(process.env.REACT_APP_COUPON_TYPE_PAYOUT_1), "name": "Coupon Cashback" },
  { "id": parseInt(process.env.REACT_APP_COUPON_TYPE_PAYOUT_2), "name": "Commission" },
];

const RecentPost = ({ className, id }) => {
  const [statusText, setStatusText] = useState(statusOptions[0].name);
  const [statusValue, setStatusValue] = useState(statusOptions[0].id);
  const [loading, setLoading] = useState(false);
  const [cashbackData, setCashbackData] = useState([]);

  const handleTypeSelection = (x) => {
    setStatusText(x?.name);
    setStatusValue(x?.id);
  };

  useEffect(() => {
    getRewardsAndPayoutDataHandler();
  }, [statusValue]);

  const getRewardsAndPayoutDataHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      type: statusValue,
      userId: id,
    };
    try {
      const getRewardsAndPayoutPayload = await requestHandler("getCustomerRewardSummury", "post", data, "jwt_token");
      setLoading(false);
      setCashbackData(getRewardsAndPayoutPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <Card
        className={cn(styles.card, className)}
        title="Cashback Info"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <CustomDropdown
              className={styles.field}
              value={statusValue}
              setValue={setStatusValue}
              text={statusText}
              setText={setStatusText}
              options={statusOptions}
              handleClick={handleTypeSelection}
            />
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Currency</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Order Id</div>
            <div className={styles.col}>Type</div>
            <div className={styles.col}>Date</div>
          </div>
          {cashbackData?.map((x, index) => (
            <Row
              item={x}
              key={index}
              statusValue={statusValue}
            />
          ))}
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
