import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({
  item,
  enableTransactionHandler,
  disableTransactionHandler
}) => {

  function capitalizeFirstLetter(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {item?.low}
        </div>
        <div className={styles.col}>
          {item?.medium}
        </div>
        <div className={styles.col}>
          {item?.high}
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.box}>
              {item?.duration}
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.box}>
              {capitalizeFirstLetter(item?.interval)}
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.box}>
              {item?.module === process.env.REACT_APP_TRANSACTION_TYPE_FIAT_DEPOSIT_SINGLE ?
                "Fiat Deposit"
                :
                item?.module === process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_DEPOSIT_SINGLE ?
                  "Crypto Deposit"
                  :
                  item?.module === process.env.REACT_APP_TRANSACTION_TYPE_FIAT_WITHDRAW_SINGLE ?
                    "Fiat Withdraw"
                    :
                    item?.module === process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_WITHDRAW_SINGLE ?
                      "Crypto Withdraw"
                      :
                      item?.module === process.env.REACT_APP_TRANSACTION_TYPE_FIAT_TO_CRYPTO_SINGLE ?
                        "Fiat to Crypto"
                        :
                        item?.module === process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_TO_FIAT_SINGLE ?
                          "Crypto to Fiat"
                          :
                          item?.module === process.env.REACT_APP_TRANSACTION_TYPE_FIAT_TO_CRYPTO_HOUR ?
                            "Fiat to Crypto"
                            :
                            item?.module === process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_TO_FIAT_HOUR ?
                              "Crypto to Fiat"
                              :
                              ""
              }
            </div>
          </div>
        </div>
        <div className={styles.col}>
          {item?.status === parseInt(process.env.REACT_APP_TRANSACTION_MONITORING_STATUS_PENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            item?.status === parseInt(process.env.REACT_APP_TRANSACTION_MONITORING_STATUS_ACTIVE) ?
              <div className={cn("status-green-dark", styles.distribution)}>
                Enabled
              </div>
              :
              item?.status === parseInt(process.env.REACT_APP_TRANSACTION_MONITORING_STATUS_DISABLE) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  Disabled
                </div> :
                ""
          }
        </div>
        <div className={styles.col}>
          {item?.status === parseInt(process.env.REACT_APP_TRANSACTION_MONITORING_STATUS_PENDING) ?
            <div className={styles.row}>
              <button className={cn("button", styles.acceptButton)} type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  enableTransactionHandler(item?.id);
                }}>
                <span>Enable</span>
              </button>
              <button className={cn("button", styles.rejectButton)} type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  disableTransactionHandler(item?.id);
                }}>
                <span>Disable</span>
              </button>
            </div> :
            item?.status === parseInt(process.env.REACT_APP_TRANSACTION_MONITORING_STATUS_ACTIVE) ?
              <div className={styles.row}>
                <button className={cn("button", styles.rejectButton)} type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    disableTransactionHandler(item?.id);
                  }}>
                  <span>Disable</span>
                </button>
              </div> :
              item?.status === parseInt(process.env.REACT_APP_TRANSACTION_MONITORING_STATUS_DISABLE) ?
                <div className={styles.row}>
                  <button className={cn("button", styles.acceptButton)} type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      enableTransactionHandler(item?.id);
                    }}>
                    <span>Enable</span>
                  </button>
                </div> :
                ""
          }
        </div>
      </div>
    </>
  );
};

export default Row;
