import {
    ADD_NEW_COIN_FAILED,
    ADD_NEW_COIN_REQUEST,
    ADD_NEW_COIN_SUCCESS,
} from "../actions/addNewCoin/actionType";


const initialState = {
    loading: false,
    error: "",
    coinData: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_COIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_NEW_COIN_SUCCESS:
            return {
                ...state,
                loading: false,
                coinData: action.payload,
            };
        case ADD_NEW_COIN_FAILED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state
    }
};

export default reducer;