import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/DetailsModal";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import CreateReward from "./Row/Details";
import Dropdown from "../../../components/Dropdown";

const RecentPost = ({
  className,
  visibleModal,
  setVisibleModal,
  transactionData,
  loading,
  regionValue,
  setRegionValue,
  regionValues,
  listStatus,
  setListStatus,
  listStatusValues,
  intervalStatus,
  setIntervalStatus,
  intervalValues,
  selectedTransaction,
  setSelectedTransaction,
  downloadFileHandler,
  moduleValues,
  module,
  setModule
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Transactions"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            {/* <div className={styles.regionDropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={regionValue}
                setValue={setRegionValue}
                options={regionValues}
                small
              />
            </div> */}
            <div className={styles.regionDropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={listStatus}
                setValue={setListStatus}
                options={listStatusValues}
                small
              />
            </div>
            <div className={styles.regionDropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={intervalStatus}
                setValue={setIntervalStatus}
                options={intervalValues}
                small
              />
            </div>
            <div className={styles.regionDropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={module}
                setValue={setModule}
                options={moduleValues}
                small
              />
            </div>
            {transactionData?.length > 0 && <button className={cn("button", styles.button)} onClick={() => downloadFileHandler()}>
              <span>Export Data</span>
            </button>}
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>User Id</div>
            <div className={styles.col}>Module</div>
            <div className={styles.col}>Amount</div>
            {listStatus.toLowerCase() === "completed" && <div className={styles.col}>Checked By</div>}
            {intervalStatus.toLowerCase() !== "days" &&
              <>
                <div className={styles.col}>Order Id</div>
                <div className={styles.col}>Crypto Txid</div>
                <div className={styles.col}>Fiat Reference</div>
              </>
            }
            <div className={styles.col}>Interval</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={8} rowCount={12} />
            :
            transactionData?.length > 0 ?
              <>
                {
                  transactionData?.filter((y) => {
                    if (module === "Fiat Deposit") {
                      return y.module === process.env.REACT_APP_TRANSACTION_TYPE_FIAT_DEPOSIT_SINGLE;
                    }
                    else if (module === "Crypto Deposit") {
                      return y.module === process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_DEPOSIT_SINGLE;
                    }
                    else if (module === "Fiat Withdraw") {
                      return y.module === process.env.REACT_APP_TRANSACTION_TYPE_FIAT_WITHDRAW_SINGLE;
                    }
                    else if (module === "Crypto Withdraw") {
                      return y.module === process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_WITHDRAW_SINGLE;
                    }
                    else if (module === "Fiat to Crypto") {
                      return y.module === process.env.REACT_APP_TRANSACTION_TYPE_FIAT_TO_CRYPTO_SINGLE;
                    }
                    else if (module === "Crypto to Fiat") {
                      return y.module === process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_TO_FIAT_SINGLE;
                    }
                  })
                    .map((x, index) => (
                      <Row
                        item={x}
                        key={index}
                        regionValue={regionValue}
                        setVisibleModal={setVisibleModal}
                        setSelectedTransaction={setSelectedTransaction}
                        listStatus={listStatus}
                        intervalStatus={intervalStatus}
                      />
                    ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        showModal={visibleModal}
        handleCloseModal={() => setVisibleModal(false)}
      >
        <CreateReward
          selectedTransaction={selectedTransaction}
        />
      </Modal>
    </>
  );
};

export default RecentPost;