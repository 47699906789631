import React, { useEffect, useState } from "react";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";

const Promote = () => {
  const [coinsData, setCoinsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCoins();
  }, []);

  let data = {
    signature: localStorage.getItem("signature"),
  };

  const getCoins = async () => {
    setLoading(true);
    try {
      const coinsPayload = await requestHandler("getCoins", "post", data, "jwt_token");
      setLoading(false);
      setCoinsData(coinsPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          coinsData={coinsData}
          loading={loading}
        />
      </div>
    </>
  );
};

export default Promote;
