import React, { useState } from "react";
import { getDigitsAfterDecimal } from "../../../../components/helper";
import NewModal from "../../../../components/NewModal";
import Details from "./Details";
import styles from "./Row.module.sass";

const Row = ({ item, walletDetails }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className={styles.row}
        onClick={() => setShowModal(true)}
      >
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              <img
                srcSet={`${item.image2x} 2x`}
                src={walletDetails.image_url + item.image}
                alt="Icon"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.name.toUpperCase()}</div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{item.symbol.toUpperCase()}</div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{getDigitsAfterDecimal(item?.balance, item?.decimalPrecision)}</div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{getDigitsAfterDecimal(item?.int_balance, item?.decimalPrecision)}</div>
          </div>
        </div>
      </div>
      <NewModal
        outerClassName={styles.outer}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
      >
        <Details item={item} walletDetails={walletDetails} />
      </NewModal>
    </>
  );
};

export default Row;
