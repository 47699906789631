import { Navigate } from "react-router";

export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};


export const getCustomerStatus = (status) => {
  if (status === 'Unverified') {
    return parseInt(process.env.REACT_APP_UNVERIFIED_CUSTOMERS);
  }
  else if (status === 'Pending') {
    return parseInt(process.env.REACT_APP_PENDING_CUSTOMERS);
  }
  else if (status === 'Verified') {
    return parseInt(process.env.REACT_APP_VERIFIED_CUSTOMERS);
  }
  else if (status === 'Freezed') {
    return parseInt(process.env.REACT_APP_FREEZED_CUSTOMERS);
  }
  else {
    return process.env.REACT_APP_UNVERIFIED_CUSTOMERS;
  };
};


export const getTwoFaStatus = (status) => {
  if (status === "" || status === null) {
    return 'Disabled';
  }
  else {
    return "Enable";
  };
};

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('signature');

  return isAuthenticated ? children : <Navigate to="/sign-in" />;
};


export {
  ProtectedRoute,
};