import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Confirm.module.sass";
import Loader from "../../../components/Loader";
import SimpleReactValidator from "simple-react-validator";
import { addNotification } from "../../../components/Notification";

const Confirm = ({ verify2FAHandler }) => {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  const [confirm, setConfirm] = useState(true);
  const [firstField, setFirstField] = useState("");
  const [secondField, setSecondField] = useState("");
  const [thirdField, setThirdField] = useState("");
  const [fourthField, setFourthField] = useState("");
  const [fifthField, setFifthField] = useState("");
  const [sixthField, setSixthField] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();

  return (
    <div className={styles.code}>
      <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>


        <div className={styles.body}>
          <h3 className={cn("h3", styles.title)}>Enter your 2FA code.</h3>
          <br />
          <div className={styles.info}>
            Help us to secure your account. <br></br>Please complete the verifications
            below
          </div>
          <div>
            Please enter the code below which you get on your mobile phone.
          </div>
          <div className={styles.fieldset}>
            <div className={styles.field}>
              <input autoFocus
                className={styles.input}
                ref={ref1}
                maxLength="1"
                value={firstField}
                name="number1"
                type="tel"
                required
                onChange={(e) => {
                  if (e.target.value) {
                    setFirstField(e.target.value);
                    ref2.current.focus();
                  }
                  else if (e.target.value === '') {
                    setFirstField('');
                  }
                }}
                onBlur={() => { simpleValidator.current.showMessageFor("field") }}
              />
            </div>
            <div className={styles.field}>
              <input
                ref={ref2}
                maxLength="1"
                value={secondField}
                className={styles.input}
                name="number2"
                type="tel"
                required
                onChange={(e) => {
                  if (e.target.value) {
                    setSecondField(e.target.value);
                    ref3.current.focus();
                  }
                  else if (e.target.value === '') {
                    setSecondField('');
                    ref1.current.focus();
                  }
                }}
                onBlur={() => { simpleValidator.current.showMessageFor("field") }}
              />
            </div>
            <div className={styles.field}>
              <input
                ref={ref3}
                maxLength="1"
                value={thirdField}
                className={styles.input}
                name="number3"
                type="tel"
                required
                onChange={(e) => {
                  if (e.target.value) {
                    setThirdField(e.target.value);
                    ref4.current.focus();
                  }
                  else if (e.target.value === '') {
                    setThirdField('');
                    ref2.current.focus();
                  }
                }}
              />
            </div>
            <div className={styles.field}>
              <input
                ref={ref4}
                maxLength="1"
                value={fourthField}
                className={styles.input}
                name="number4"
                type="tel"
                required
                onChange={(e) => {
                  if (e.target.value) {
                    setFourthField(e.target.value);
                    ref5.current.focus();
                  }
                  else if (e.target.value === '') {
                    setFourthField('');
                    ref3.current.focus();
                  }
                }}
              />
            </div>
            <div className={styles.field}>
              <input
                ref={ref5}
                maxLength="1"
                value={fifthField}
                className={styles.input}
                name="number5"
                type="tel"
                required
                onChange={(e) => {
                  if (e.target.value) {
                    setFifthField(e.target.value);
                    ref6.current.focus();
                  }
                  else if (e.target.value === '') {
                    setFifthField('');
                    ref4.current.focus();
                  }
                }}
              />
            </div>
            <div className={styles.field}>
              <input
                ref={ref6}
                maxLength="1"
                value={sixthField}
                className={styles.input}
                name="number6"
                type="tel"
                required
                onChange={(e) => {
                  if (e.target.value) {
                    setSixthField(e.target.value);
                  }
                  else if (e.target.value === '') {
                    setSixthField('');
                    ref5.current.focus();
                  }
                }}
              />
            </div>
          </div>
          {/* <div className={styles.errorNote}>
          The code you entered is incorrect.
        </div> */}
          <button className={cn("button", styles.button)}
            onClick={() => {
              if (firstField !== "" && secondField !== "" && thirdField !== "" && fourthField !== "" && fifthField !== "" && sixthField !== "") {
                // if (simpleValidator.current.allValid()) {
                verify2FAHandler(firstField, secondField, thirdField, fourthField, fifthField, sixthField);
              } else {
                addNotification({
                  title: 'Error',
                  message: "Please enter a valid otp.",
                  type: 'danger'
                });
                // simpleValidator.current.showMessages();
                // forceUpdate(1);
              }
            }}
          >
            {/* <Loader className={styles.loader} white /> */}
            <span>Continue</span>
          </button>
          {/* <div className={styles.note}>
            This site is protected by reCAPTCHA and the Google Privacy Policy.
          </div> */}
        </div>
      </form>
    </div>
  );
};

export default Confirm;
