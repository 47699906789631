import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";
import NewModal from "../../../../components/NewModal";
import Details from "./Details";

const Row = ({ item, updateWithdrawFiatHandler }) => {

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className={styles.row} onClick={() => handleOpenModal()}>
        <div className={styles.col}>
          {item?.userId}
        </div>
        <div className={styles.col}>
          {item?.m_user?.uid}
        </div>
        <div className={styles.col}>
          {item?.amount}
          <div className={styles.text}>
            {item?.currency}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.box}>
              <div className={styles.number}>
                {item?.reference}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PENDING) && (
            <div className={cn("status-red-dark", styles.distribution)}>Pending</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_APPROVED) && (
            <div className={cn("status-green-dark", styles.distribution)}>Success</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_CANCELLED) && (
            <div className={cn("status-red-dark", styles.distribution)}>Cancelled</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_DECLINED) && (
            <div className={cn("status-red-dark", styles.distribution)}>Declined</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PROCESS) && (
            <div className={cn("status-red-dark", styles.distribution)}>In Process</div>
          )}
          {item?.status === null || item?.status === undefined && "---"}
        </div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(item?.createdAt)}
        </div>
        <div className={styles.col}>
          {item.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PENDING) &&
            <div className={styles.row}>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <div>
                    <button className={cn("button", styles.acceptButton)} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateWithdrawFiatHandler(item?.userId, item?.id, parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PROCESS))
                      }}
                    >
                      <span>Process</span>
                    </button>
                    <button className={cn("button", styles.acceptButton)} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateWithdrawFiatHandler(item?.userId, item?.id, parseInt(process.env.REACT_APP_FIAT_WITHDRAW_APPROVED))
                      }}
                    >
                      <span>Verify</span>
                    </button>
                    <button className={cn("button", styles.rejectButton)} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateWithdrawFiatHandler(item?.userId, item?.id, parseInt(process.env.REACT_APP_FIAT_WITHDRAW_DECLINED))
                      }}
                    >
                      <span>Decline</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
          {item.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PROCESS) &&
            <div className={styles.row}>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <div>
                    <button className={cn("button", styles.acceptButton)} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateWithdrawFiatHandler(item?.userId, item?.id, parseInt(process.env.REACT_APP_FIAT_WITHDRAW_APPROVED))
                      }}
                    >
                      <span>Verify</span>
                    </button>
                    <button className={cn("button", styles.rejectButton)} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateWithdrawFiatHandler(item?.userId, item?.id, parseInt(process.env.REACT_APP_FIAT_WITHDRAW_DECLINED))
                      }}
                    >
                      <span>Decline</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <NewModal
        outerClassName={styles.outer}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
      >
        <Details item={item} />
      </NewModal>
    </>
  );
};

export default Row;
