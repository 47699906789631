import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ProductsDashboard.module.sass";
import Products from "./Products"
import Overview from "./Overview"
import TooltipGlodal from "../../components/TooltipGlodal";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const OrderList = () => {
  const [allMarketsData, setAllMarketsData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [dropDownValues, setDropDownValues] = useState([]);
  const [orderId, setOrderId] = useState(parseInt(process.env.REACT_APP_ORDER_STATUS_OPEN));
  const [pageNo, setPageNo] = useState(1);
  const [marketText, setMarketText] = useState('Select Market');
  const [marketValue, setMarketValue] = useState('');
  const [ordersDetails, setOrdersDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [regionDropDown, setRegionDropdown] = useState([])
  // const [regionText, setRegionText] = useState(process.env.REACT_APP_DEFAULT_REGION_TEXT);
  const [regionText, setRegionText] = useState("Select Region");
  // const [regionValue, setRegionValue] = useState(process.env.REACT_APP_DEFAULT_REGION_ID);
  const [regionValue, setRegionValue] = useState(process.env.REACT_APP_DEFAULT_REGION_ID);
  const [currencyName, setCurrencyName] = useState(0);
  const [active, setActive] = useState(true);

  const handleClick = () => {
    setActive(!active);
  };

  useEffect(() => {
    getRegion();
  }, []);

  useEffect(() => {
    if (marketValue) {
      getOrderManagementDetails(marketValue);
    }
  }, [orderId, currencyName, active]);

  const handlePageClick = () => {
    setPageNo(pageNo + 1);
  };

  const getOrderData = (value) => {
    setOrderId(value);
  };

  const getRegion = async () => {
    setLoading(true);
    try {
      const regionPayload = await requestHandler("region", "post");
      setLoading(false);
      setRegionDropdown(regionPayload.data.data)
    } catch (error) {
      setLoading(false);
    };
  };

  const getMarkets = async (regionValue) => {
    setLoading(true);
    try {
      let data = {
        type: "adminMarkets",
        region: parseInt(regionValue),
      };
      const marketsPayload = await requestHandler("get_markets", "post", data);
      setLoading(false);
      setAllMarketsData(marketsPayload?.data?.data);

      const newDropDownValue = marketsPayload?.data?.data[0]?.markets?.map((x) => { return { "id": x.id, "name": x.slug } });
      setDropDownValues([...newDropDownValue]);

      setMarketText(newDropDownValue[0]?.name);
      setMarketValue(newDropDownValue[0]?.id);

      if (marketsPayload && marketsPayload.status === 200) {
        getOrderManagementDetails(newDropDownValue[0]?.id);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    };
  };

  const getOrderManagementDetails = async (marketValue1) => {
    setLoading(true);
    let formData = {
      signature: localStorage.getItem("signature"),
      market_id: marketValue1,
      order_type: orderId ? orderId : parseInt(process.env.REACT_APP_ORDER_STATUS_OPEN),
      page: pageNo,
      type: active ? parseInt(process.env.REACT_APP_LIMIT_ORDERS)
        : !active ? parseInt(process.env.REACT_APP_MARKET_ORDERS)
          : null,
    };
    try {
      const ordersPayload = await requestHandler("orderManagement", "post", formData, "jwt_token");
      createMarketOrders(ordersPayload?.data?.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const createMarketOrders = (orders) => {
    let createOrders = [];
    let tempData = [];
    for (let order of orders) {
      if (tempData.indexOf(parseFloat(order.orderId)) < 0) {
        createOrders.push(order)
        tempData.push(parseFloat(order.orderId));
      } else {
        let index = tempData.indexOf(parseFloat(order.orderId));
        createOrders[index].crypto_amount = parseFloat(order.crypto_amount) + parseFloat(createOrders[index].crypto_amount);
        createOrders[index].fiat_amount = parseFloat(order.fiat_amount) + parseFloat(createOrders[index].fiat_amount);
      }
    }
    setOrdersDetails(createOrders)
  };

  return (
    <>
      <div className={styles.section}>
        <Overview
          className={styles.card}
          allMarketsData={allMarketsData}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          dropDownValues={dropDownValues}
          setDropDownValues={setDropDownValues}
          marketText={marketText}
          setMarketText={setMarketText}
          marketValue={marketValue}
          setMarketValue={setMarketValue}
          loading={loading}
          regionDropDown={regionDropDown}
          regionText={regionText}
          setRegionText={setRegionText}
          regionValue={regionValue}
          setRegionValue={setRegionValue}
          getOrderManagementDetails={getOrderManagementDetails}
          getMarkets={getMarkets}
          setCurrencyName={setCurrencyName}
        />
        <Products
          getOrderData={getOrderData}
          handlePageClick={handlePageClick}
          ordersDetails={ordersDetails}
          pageNo={pageNo}
          loading={loading}
          getOrderManagementDetails={getOrderManagementDetails}
          marketValue={marketValue}
          active={active}
          handleClick={handleClick}
        />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default OrderList;
