import React, { useState } from "react";
import styles from "./Market.module.sass";
import Row from "./Row";
import cn from "classnames";
import NewModal from "../../../../components/NewModal";
import Details from "./Details";
import OrderManagementSkeleton from "../../../../components/Skeleton/OrderManagementSkeleton";

const Market = ({ ordersDetails, activeTab, handlePageClick, pageNo, loading, active, searchField }) => {
  const [showModal, setShowModal] = useState(false);

  const [modalData, setModalData] = useState();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = (x) => {
    setModalData(x);
    setShowModal(true);
  };

  return (
    <div className={styles.table}>
      <div className={styles.row}>
        <div className={styles.col}>C Id</div>
        <div className={styles.col}>Date</div>
        <div className={styles.col}>Order Id</div>
        <div className={styles.col}>Market</div>
        <div className={styles.col}>Type</div>
        {activeTab.toLowerCase().includes("history") && active &&
          <div className={styles.col}>Category</div>}
        <div className={styles.col}>Price</div>
        <div className={styles.col}>Amount</div>
        <div className={styles.col}>Total</div>
        <div className={styles.col}>Status</div>
      </div>
      {
        loading ? <OrderManagementSkeleton rowCount={10} colCount={9} /> :
          ordersDetails?.length > 0 ?
            <>
              {
                ordersDetails?.filter((data) => {
                  return data.orderId.includes(searchField.toLowerCase())
                })
                  .map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      activeTab={activeTab}
                      showModal={showModal}
                      handleCloseModal={handleCloseModal}
                      handleOpenModal={() => handleOpenModal(x)}
                      active={active}
                    />
                  ))}
              {
                ordersDetails?.length === (10 * pageNo) && (
                  <div className={styles.foot}>
                    <button className={cn("button-stroke button-small", styles.button)} onClick={() => { handlePageClick() }}>
                      <span>Load more</span>
                    </button>
                  </div>
                )
              }
            </> :
            "No data found"
      }
      {activeTab.toLowerCase().includes("history") &&
        (<NewModal
          outerClassName={styles.outer}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        >
          <Details item={modalData} />
        </NewModal>)
      }
    </div>
  );
};

export default Market;
