import React from 'react'
import styles from "./Row.module.sass";
import Icon from '../../../../components/Icon';
import { getDigitsAfterDecimal } from '../../../../components/helper';

const index = ({ item }) => {

    return (
        <div className={styles.list}>
            <div className={styles.details}>
                <div
                    className={styles.post}
                    dangerouslySetInnerHTML={{ __html: item.symbol.toUpperCase() }}>
                </div>
                <div className={styles.text}><small>{item.name.toUpperCase()}</small></div>
            </div>
            <div className={styles.item}>
                <div
                    className={styles.icon}
                    style={{ backgroundColor: "#B5E4CA" }}
                >
                    <Icon name={"arrows-up-down"} size="24" />
                </div>
                <div className={styles.details}>
                    <div className={styles.label}>
                        Total Amount
                    </div>
                    <div className={styles.counter}>{getDigitsAfterDecimal(item?.amount, item?.precision)} <small>{item?.symbol?.toUpperCase()}</small></div>
                </div>
            </div>
            <div className={styles.item}>
                <div
                    className={styles.icon}
                    style={{ backgroundColor: "#FFBC99" }}
                >
                    <Icon name={"arrows-up-down"} size="24" />
                </div>
                <div className={styles.details}>
                    <div className={styles.label}>
                        Total Requests
                    </div>
                    <div className={styles.counter}>{item?.success_request}</div>
                </div>
            </div>
            <div className={styles.item}>
                <div
                    className={styles.icon}
                    style={{ backgroundColor: "#B1E5FC" }}
                >
                    <Icon name={"arrows-up-down"} size="24" />
                </div>
                <div className={styles.details}>
                    <div className={styles.label}>
                        Successful Requests
                    </div>
                    <div className={styles.counter}>{item?.success_request}</div>
                </div>
            </div>
        </div>
    )
};

export default index;
