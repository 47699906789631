import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CutomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({ className,
  bankData,
  loading,
  updateStatusHandler
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Requests"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Customer Id</div>
            <div className={styles.col}>Account Number</div>
            <div className={styles.col}>Proof</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CutomerListSkeleton colCount={6} rowCount={12} />
            :
            bankData.length > 0 ?
              <>
                {
                  bankData.map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      updateStatusHandler={updateStatusHandler}
                    />
                  ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
