import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import LoaderScreen from "../../../components/LoaderScreen";
import OrderDropdown from "../../../components/OrderDropdown";
import Row from "./Row";

// data
import Form from "../../../components/Form";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";

const intervals = ["Last 7 days", "This month", "All time"];

const RecentPost = ({ className, usersData, loading, regionData,
  setRegionText,
  regionText,
  regionValue,
  setRegionValue,
  uploadDocumentsHandler,
  status,
  approveCorporateAccountHandler,
  superadmin
}) => {
  const [searchField, setSearchField] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Corporate"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.dropdown}>
              <OrderDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={regionValue}
                setValue={setRegionValue}
                text={regionText}
                setText={setRegionText}
                options={regionData}
              />
            </div>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by name, email or customer id"
              type="text"
              name="search"
              icon="search"
            />
            {superadmin && <div className={styles.control}>
              <Link className={cn("button", styles.button)} to="/corporate/add-corporate">
                <Icon name="add" size="20" />
                <span>Add Corporate</span>
              </Link>
            </div>}

          </>
        }
      >
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>B Id</div>
              <div className={styles.col}>Business</div>
              <div className={styles.col}>Unique Id</div>
              <div className={(styles.col, styles.sortIcon)}>Region</div>
              <div className={styles.col}>Bank Status</div>
              <div className={styles.col}>KYB Status</div>
              <div className={styles.col}>Address Status</div>
              <div className={styles.col}>Registration Date</div>
              <div className={(styles.col, styles.sortIcon)}>Risk</div>
              {superadmin && <div className={styles.col}>Upload Document</div>}
              {status === process.env.REACT_APP_KYCVERIFICATIONPENDINGSTRING && (<div className={styles.col}>Action</div>)}
            </div>
            {loading ? <CustomerListSkeleton colCount={10} rowCount={12} />
              : usersData?.filter((data) => {
                return (data?.corporate_details?.email.toLowerCase().includes(searchField.toLowerCase())) ||
                  (data?.corporate_details?.uid.toLowerCase().includes(searchField.toLowerCase())) ||
                  (data?.corporate_details?.fname !== null && data?.corporate_details?.fname.toLowerCase().includes(searchField.toLowerCase())) || (data?.corporate_details?.lname !== null && data?.corporate_details?.lname.toLowerCase().includes(searchField.toLowerCase()))
              })
                .map((x, index) => (
                  <Row
                    item={x?.corporate_details}
                    key={index}
                    region={x?.region}
                    uploadDocumentsHandler={uploadDocumentsHandler}
                    loading={loading}
                    documents={x?.documents}
                    directors={x?.director}
                    status={status}
                    approveCorporateAccountHandler={approveCorporateAccountHandler}
                    superadmin={superadmin}
                  />
                ))
            }
          </div>
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
