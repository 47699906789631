import React, { useState } from "react";
import styles from "./NewProduct.module.sass";
import NameAndDescription from "./NameAndDescription";

const NewProduct = () => {
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription className={styles.card} />
        </div>
      </div>
    </>
  );
};

export default NewProduct;
