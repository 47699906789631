import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import LoaderScreen from "../../../components/LoaderScreen";
import OrderDropdown from "../../../components/OrderDropdown";
import CustomDropdown from "../../../components/CustomDropdown";
import Select from 'react-select';
import requestHandler from "../../../actions/httpClient";
import TextInput from "../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate } from "react-router";
import { addNotification } from "../../../components/Notification";

const botTypeOtion = [
  { "id": parseInt(process.env.REACT_APP_VOLUME_BOT), "name": "VOLUME BOT" },
  { "id": parseInt(process.env.REACT_APP_LIMIT_ORDER_BOT), "name": "LIMIT ORDER BOT" },
];

const Form = ({ className }) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [regionText, setRegionText] = useState("Please select region");
  const [slugText, setSlugText] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const [marketListData, setMarketListData] = useState([]);
  const [buyFee, setBuyFee] = useState("");
  const [sellFee, setSellFee] = useState("");
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const selectInputRef = useRef();
  const [botTypeText, setBotTypeText] = useState("Please select value");
  const [botTypeValue, setBotTypeValue] = useState("");

  const onClear = () => {
    selectInputRef.current.clearValue();
  };

  useEffect(() => {
    getRegion()
  }, []);

  useEffect(() => {
    if (regionValue) {
      onClear();
      setSelectedMarkets([]);
      getMarkets(regionValue);
    }
  }, [regionValue]);

  const handleChange = (x) => {
    let finalValueList = x.map((y) => {
      return y.value
    });
    setSelectedMarkets(finalValueList);
  };

  const getRegion = async () => {
    setLoading(true);
    try {
      const regionPayload = await requestHandler("region", "post");
      for (let i = 0; i < regionPayload.data?.data.length; i++) {
        if (regionPayload?.data?.data[i].description == 'eur') {
          getMarkets(regionPayload?.data?.data[i]?.id);
        }
      }
      const newDropDownValue = regionPayload?.data?.data?.map((x) => { return { "id": x.id, "name": x.name } });
      setLoading(false);
      setRegionData([...newDropDownValue]);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const getMarkets = async (id) => {
    setLoading(true);
    try {
      let data = {
        type: "adminMarkets",
        region: id,
      };
      const marketsPayload = await requestHandler("get_markets", "post", data);
      const newDropDownValue = marketsPayload?.data?.data[0]?.markets.map((x) => { return { "value": x.id, "label": x.slug } }
      );
      setLoading(false);
      setMarketListData([...newDropDownValue]);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const addBotHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      slug: slugText,
      markets: selectedMarkets,
      buy_fee: buyFee,
      sell_fee: sellFee,
      region: regionValue,
      description: description,
      type: botTypeValue,
    };
    try {
      const addBotPayload = await requestHandler("createBotUser", "post", data, "jwt_token");
      setLoading(false);
      if (addBotPayload && addBotPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: addBotPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
        navigate("/bots/pending-bots");
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  const handleBotTypeSelection = (x) => {
    setBotTypeText(x.name);
    setBotTypeValue(x.id);
  };

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <div className={styles.description}>
        <div className={styles.customField}>
          <OrderDropdown
            label={"Select Region"}
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={regionValue}
            setValue={setRegionValue}
            text={regionText}
            setText={setRegionText}
            options={regionData}
          />
        </div>
        <div className={styles.customField}>
          <p>Please Select Markets</p>
          <Select
            options={marketListData}
            onChange={handleChange}
            isMulti
            ref={selectInputRef}
            placeholder={"Please select markets"}
          />
        </div>

      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Buy Fee"
            name="buyFee"
            type="text"
            value={buyFee}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBuyFee(e.target.value)
              }
            }}
            placeholder="Please enter buy fee"
            required
            onBlur={() => simpleValidator.current.showMessageFor("buyFee")}
          />
          {simpleValidator.current.message("buyFee", buyFee, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Sell Fee"
            name="sellFee"
            type="text"
            value={sellFee}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setSellFee(e.target.value)
              }
            }}
            placeholder="Please enter sell fee"
            required
            onBlur={() => simpleValidator.current.showMessageFor("sellFee")}
          />
          {simpleValidator.current.message("sellFee", sellFee, "required")}
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Slug"
            name="slug"
            type="text"
            value={slugText}
            onChange={(e) => {
              const re = /^[a-zA-Z0-9-_]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setSlugText(e.target.value)
              }
            }
            }
            placeholder="Please enter slug"
            required
            onBlur={() => simpleValidator.current.showMessageFor("slug")}
          />
          {simpleValidator.current.message("slug", slugText, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Description"
            name="description"
            type="text"
            value={description}
            onChange={(e) => { setDescription(e.target.value) }}
            placeholder="Please enter description"
            required
            onBlur={() => simpleValidator.current.showMessageFor("description")}
          />
          {simpleValidator.current.message("description", description, "required")}
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <CustomDropdown
            className={styles.field}
            label="Bot Type"
            options={botTypeOtion}
            text={botTypeText}
            setText={setBotTypeText}
            value={botTypeValue}
            setValue={setBotTypeValue}
            handleClick={handleBotTypeSelection}
          />
        </div>
      </div>
      <div className={styles.control}>
        <button className={cn("button", styles.button)} onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (regionText !== "Please select region" && selectedMarkets?.length > 0) {
              if (botTypeText !== "Please select value") {
                addBotHandler();
              }
              else {
                addNotification({
                  title: 'Error',
                  message: "Please select the Bot type.",
                  type: 'danger'
                });
              }
            }
            else {
              addNotification({
                title: 'Error',
                message: "Please select the region and Markets",
                type: 'danger'
              });
            }
          } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
          }
        }
        }>
          <span>Add Bot</span>
        </button>
      </div>
    </Card >
  );
};

export default Form;
