import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import OrderDropdown from "../../../components/OrderDropdown";
import Row from "./Row";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import Form from "../../../components/Form";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({ className,
  marketCurrency,
  marketListData,
  setMarketValue,
  marketText,
  setMarketText,
  marketValue,
  regionData,
  loading,
  dragStart,
  dragEnter,
  drop,
  reOrderMarketListHandler,
  updateHistoryHandler
}) => {
  const [searchField, setSearchField] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Markets"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.dropdown}>
              <OrderDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={marketValue}
                setValue={setMarketValue}
                text={marketText}
                setText={setMarketText}
                options={regionData}
              />
            </div>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by name or slug"
              type="text"
              name="search"
              icon="search"
            />
            <button className={cn("button", styles.button)}
              onClick={() => { reOrderMarketListHandler(); }}
            >
              Save Positions
            </button>
            <div className={styles.control}>
              <Link className={cn("button", styles.button)} to="/trade/market/add-market">
                <Icon name="add" size="24" />
                <span>Add Market</span>
              </Link>
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Market</div>
            <div className={styles.col}>Market Order</div>
            <div className={styles.col}>Market price</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Decimal Precision</div>
            <div className={styles.col}>Action</div>
            <div className={styles.col}>Last Updated</div>
            <div className={styles.col}>Update Market Data</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={8} rowCount={12} />
            : marketListData?.filter((data) => {
              return data?.name.toLowerCase().includes(searchField?.toLowerCase()) ||
                data?.slug?.toLowerCase().includes(searchField?.toLowerCase())
            })
              .map((y, index) => (
                <Row
                  item={y}
                  key={index}
                  index={index}
                  currency={marketCurrency}
                  dragStart={dragStart}
                  dragEnter={dragEnter}
                  drop={drop}
                  updateHistoryHandler={updateHistoryHandler}
                />
              ))}
        </div>
      </Card >
    </>
  );
};

export default RecentPost;
