import React from "react";
import cn from "classnames";
import styles from "./Share.module.sass";

const Share = ({ item }) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Bank Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          U Id
        </div>
        <div className={styles.col}>
          {item?.m_user?.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Reference
        </div>
        <div className={styles.col}>
          {item?.reference}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Name
        </div>
        <div className={styles.col}>
          {`${item?.m_user?.fname ? item?.m_user?.fname : "---"} ${item?.m_user?.lname ? item?.m_user?.lname : "---"}`}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Email
        </div>
        <div className={styles.col}>
          {item?.m_user?.email}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Account Number</div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.details}>
              <div
                className={styles.post}
                dangerouslySetInnerHTML={{ __html: item?.bank_details?.bank_name }}
              ></div>
              <div className={styles.post}>
                {item?.bank_details?.account_number}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.text}>
          Withdrawal amount
        </div>
        <div className={styles.col}>
          {item?.amount} {item.currency.toUpperCase()}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Withdraw Id
        </div>
        <div className={styles.col}>
          {item?.reference}
        </div>
      </div>
    </div>
  );
};

export default Share;
