import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/DetailsModal";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import CreateReward from "./Row/Details";
import Dropdown from "../../../components/Dropdown";

const RecentPost = ({
  className,
  visibleModal,
  setVisibleModal,
  transactionData,
  loading,
  regionValue,
  setRegionValue,
  regionValues,
  listStatus,
  setListStatus,
  listStatusValues,
  intervalStatus,
  setIntervalStatus,
  intervalValues,
  selectedTransaction,
  setSelectedTransaction,
  markAsCheckedHandler,
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Transactions"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            {/* <div className={styles.regionDropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={regionValue}
                setValue={setRegionValue}
                options={regionValues}
                small
              />
            </div> */}
            <div className={styles.regionDropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={listStatus}
                setValue={setListStatus}
                options={listStatusValues}
                small
              />
            </div>
            <div className={styles.regionDropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={intervalStatus}
                setValue={setIntervalStatus}
                options={intervalValues}
                small
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>User Id</div>
            <div className={styles.col}>Module</div>
            <div className={styles.col}>Amount</div>
            {listStatus.toLowerCase() === "completed" && <div className={styles.col}>Checked By</div>}
            {intervalStatus.toLowerCase() !== "days" &&
              <>
                <div className={styles.col}>Order Id</div>
                <div className={styles.col}>Crypto Txid</div>
                <div className={styles.col}>Fiat Reference</div>
              </>
            }
            <div className={styles.col}>Interval</div>
            {listStatus.toLowerCase() === "completed" && <div className={styles.col}>Date</div>}
            {listStatus.toLowerCase() === "pending" && <div className={styles.col}>Action</div>}
          </div>
          {loading ? <CustomerListSkeleton colCount={8} rowCount={12} />
            :
            transactionData?.length > 0 ?
              <>
                {
                  transactionData?.map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      regionValue={regionValue}
                      setVisibleModal={setVisibleModal}
                      setSelectedTransaction={setSelectedTransaction}
                      markAsCheckedHandler={markAsCheckedHandler}
                      listStatus={listStatus}
                      intervalStatus={intervalStatus}
                    />
                  ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        showModal={visibleModal}
        handleCloseModal={() => setVisibleModal(false)}
      >
        <CreateReward
          selectedTransaction={selectedTransaction}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
