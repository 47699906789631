import { init } from 'onfido-sdk-ui'
import { useEffect, useState } from 'react'
import requestHandler from '../../actions/httpClient';
import styles from "./Onfido.module.sass";
import { useNavigate } from "react-router-dom";

const Onfido = ({ token, workFlowRunId, userId }) => {
    const navigate = useNavigate();

    useEffect(() => {
        init({
            token: token,
            containerId: 'onfido-mount',
            onComplete: async (data) => {
                const reqBody = {
                    signature: localStorage.getItem("signature"),
                    type: parseInt(process.env.REACT_APP_KYCVERIFICATIONINPROCESS),
                    directorId: userId,
                };
                completeOnFido(reqBody);
            },
            onError: (error) => {
                const reqBody = {
                    signature: localStorage.getItem("signature"),
                    type: parseInt(process.env.REACT_APP_KYCVERIFICATIONONERROR),
                    directorId: userId,
                };
                yourCustomFunction(reqBody);
            },
            onUserExit: (userExit) => {
                const reqBody = {
                    signature: localStorage.getItem("signature"),
                    type: parseInt(process.env.REACT_APP_KYCVERIFICATIONUSEREXIT),
                    directorId: userId
                }
                yourCustomFunction(reqBody);
            },
            workflowRunId: workFlowRunId,
        });

    }, []);

    const yourCustomFunction = async (reqBody) => {
        await requestHandler('updateKycStatus', 'post', reqBody, 'jwt_token');
    };
    const completeOnFido = async (reqBody) => {
        await requestHandler('updateKycStatus', 'post', reqBody, 'jwt_token');
        navigate("/corporate/complete-corporate");
        window.location.reload();
    };

    return (
        <div className={styles.modal} id="onfido-mount" style={{ padding: 20 }}></div>
    )
}


export default Onfido