import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import requestHandler from "../../../actions/httpClient";
import { addNotification } from "../../../components/Notification";
import Form from "../../../components/Form";
import CutomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({ className }) => {
  const [pendingSepaDepositList, setPendingSepaDepositList] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllCustomersSepaRequest();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const getAllCustomersSepaRequest = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: process.env.REACT_APP_FIAT_DEPOSIT_PENDING,
    };
    try {
      const getAllCustomersSepaPayload = await requestHandler("getFiatDepositRequestList", "post", data, "jwt_token");
      setLoading(false);
      setPendingSepaDepositList(getAllCustomersSepaPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  const updateSepaRequestHandler = async (reference, status) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      reference: reference,
      status: status,
    };
    try {
      const updateSepaRequestPayload = await requestHandler("actionFiatDepositRequest", "post", data, "jwt_token");
      setLoading(false);
      if (updateSepaRequestPayload.status === 200) {
        addNotification({
          title: "Success",
          message: updateSepaRequestPayload?.data,
          type: "success",
        });
        getAllCustomersSepaRequest();
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Requests"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by Reference Id"
              type="text"
              name="search"
              icon="search"
            />
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Customer Id</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Reference Id</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CutomerListSkeleton colCount={6} rowCount={12} />
            :
            pendingSepaDepositList?.length > 0 ?
              <>
                {
                  pendingSepaDepositList?.filter((data) => {
                    return data?.reference?.toLowerCase().includes(searchField.toLowerCase())
                  })
                    .map((x, index) => (
                      <Row
                        item={x}
                        key={index}
                        updateSepaRequestHandler={updateSepaRequestHandler}
                      />
                    ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
