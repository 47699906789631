import React, { useState } from "react";
import cn from "classnames";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import OrderDropdown from "../../../components/OrderDropdown";
import requestHandler from "../../../actions/httpClient";
import { useEffect } from "react";
import Dropdown from "../../../components/Dropdown";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import { addNotification } from "../../../components/Notification";
import LoaderScreen from "../../../components/LoaderScreen";

const NameAndDescription = ({ className }) => {
  const [regionText, setRegionText] = useState("Please select region");
  const [regionValue, setRegionValue] = useState();
  const [regionData, setRegionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [coinsData, setCoinsData] = useState([]);
  const [coinText, setCoinText] = useState('Please select coin');
  const [amount, setAmount] = useState();
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();

  useEffect(() => {
    getRegion();
  }, [regionValue]);

  const getRegion = async () => {
    setLoading(true);
    setCoinText("Please select coin");
    setCoinsData([]);
    try {
      const regionPayload = await requestHandler("region", "post");
      setLoading(false);
      getMarkets(regionValue ? regionValue : regionPayload?.data?.data[0]?.id);

      const newDropDownValue = regionPayload?.data?.data?.map((x) => { return { "id": x.id, "name": x.name } });
      setRegionData([...newDropDownValue]);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const getMarkets = async (id) => {
    setLoading(true);
    try {
      let data = {
        type: "adminRecharge",
        region: id,
      };
      const marketsPayload = await requestHandler("get_markets", "post", data);
      setLoading(false);
      if (regionText && regionValue) {
        const coinListDropDownValue = marketsPayload?.data?.data[0].markets?.map((x) => { return x.symbol });
        setCoinsData([...coinListDropDownValue]);
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  const updateBotBalanceHandler = async (coin, amount, region) => {
    setLoading(true);
    let data = {
      coin: coin,
      amount: amount,
      region: region,
      signature: localStorage.getItem("signature"),
    };
    try {
      const updateBotBalancePayload = await requestHandler("updateBotBalance", "post", data, "jwt_token");
      setLoading(false);
      if (updateBotBalancePayload && updateBotBalancePayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateBotBalancePayload?.data?.message[0]?.msg,
          type: 'success'
        })
      }
      setAmount('');
      setRegionText("Please select region");
      setCoinText("Please select coin");
    }
    catch (e) {
      setLoading(false);
    }
  };

  return (

    <Card
      className={cn(styles.card, className)}
      title="Admin Balance Update"
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}

      <div className={styles.description}>
        <div className={styles.group}>
          <div className={styles.field}>
            <div className={styles.customDropDown}>
              <OrderDropdown
                label="Select Region"
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={regionValue}
                setValue={setRegionValue}
                text={regionText}
                setText={setRegionText}
                options={regionData}
              />
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.customDropDown}>
              <Dropdown
                className={styles.field}
                label="Coin"
                value={coinText}
                setValue={setCoinText}
                options={coinsData}
              />
            </div>
          </div>

          <TextInput
            className={styles.field}
            label="Enter Amount"
            name="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type="text"
            placeholder="Please enter amount"
            required
            onBlur={() => simpleValidator.current.showMessageFor("amount")}
          />
          {simpleValidator.current.message("amount", amount, "required")}
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              if (regionText.toLowerCase() === "please select region") {
                addNotification({
                  title: 'Error',
                  message: "Please Select Region",
                  type: 'danger'
                })
              }
              else if (coinText.toLowerCase() === "please select coin") {
                addNotification({
                  title: 'Error',
                  message: "Please Select Icon",
                  type: 'danger'
                })
              }
              else {
                updateBotBalanceHandler(coinText, amount, regionValue);
              }
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }
          }
          className={cn("button", styles.button)}
        >
          Save Changes
        </button>
      </div>
    </Card>
  );
};

export default NameAndDescription;
