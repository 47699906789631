import {
    COIN_REQUEST,
    COIN_SUCCESS,
    COIN_FAILED,
} from '../actions/coin/actionType'

const initialState = {
    loading: false,
    error: "",
    coinData: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case COIN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case COIN_SUCCESS:
            return {
                ...state,
                loading: false,
                coinData: action.payload,
            }
        case COIN_FAILED:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

export default reducer;