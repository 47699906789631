import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({
  item,
  setVisibleModal,
  walletData,
  setCoinData
}) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.preview}>
            <img
              srcSet={`${item.image2x} 2x`}
              src={walletData.image_url + item.image}
              alt="Icon"
            />
          </div>
          <div className={styles.details}>
            <div className={styles.product}>{item.name.toUpperCase()}</div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {item?.symbol?.toUpperCase()}
      </div>
      <div className={styles.col}>
        {getDigitsAfterDecimal(item?.balance, item?.decimalPrecision)}
      </div>
      <div className={styles.col}>
        {getDigitsAfterDecimal(item?.int_balance, item?.decimalPrecision)}
      </div>
      <div className={styles.col}>
        <button
          className={cn("button-small", styles.button)}
          onClick={() => {
            setVisibleModal(true);
            setCoinData(item);
          }}
        >
          Recharge
        </button>
      </div>
    </div>
  );
};

export default Row;
