import React, { useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";

const UserDocument = ({ className, profileInfo }) => {

  return (
    <Item
      className={cn(styles.card, className)}
      title="KYC Documents"
      classTitle="title-green"
    >
      <div className={styles.fieldset}>
        <div className={styles.customField}>
          <div className={styles.row}>
            <div className={styles.buttonClass}>
              {/* <div className={styles.settings}>
                <button className={cn("button",)} style={{ background: 'green' }} type="button"
                  onClick={(e) => e.preventDefault()}
                >
                  <span>Download Front ID</span>
                </button>
              </div> */}
            </div>
            {/* <div className={styles.buttonClass}>
              <div className={styles.settings}>
                <button className={cn("button",)} style={{ background: 'green' }} type="button"
                  onClick={(e) => e.preventDefault()}
                >
                  <span>Download Back ID </span>
                </button>
              </div>
            </div> */}
            <div className={styles.buttonClass}>
              <div className={styles.settings}>
                <button className={cn("button-small",)} style={{ background: 'green' }} type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    {
                      profileInfo?.old_kyc_documents !== null ? window.open(process.env.REACT_APP_IMAGE_URL + "/" + profileInfo?.old_kyc_documents, '_blank', 'noopener,noreferrer') :
                        window.open(process.env.REACT_APP_ONFIDO_REPORT_URL + "/" + profileInfo.workflowrun_kyc_id, '_blank', 'noopener,noreferrer')
                    }
                  }}
                >
                  <span>View KYC</span>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Item >
  );
};

export default UserDocument;
