import React, { useEffect, useState } from "react";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const Promote = () => {
  const [bankData, setBankData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBankHandler();
  }, []);

  const getBankHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const bankPayload = await requestHandler("getMiracleSepaBankList", "post", data, "jwt_token");
      setLoading(false);
      setBankData(bankPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  const updateStatusHandler = async (status, id, account_number) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: status,
      id: id,
      account_number: account_number,
    };
    try {
      const statusActionPayload = await requestHandler("actionMiracleSepaBankList", "post", data, "jwt_token");
      setLoading(false);
      if (statusActionPayload && statusActionPayload.status === 200) {
        addNotification({
          title: "Success",
          message: statusActionPayload?.data?.message?.msg,
          type: "success",
        });
        getBankHandler();
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          bankData={bankData}
          loading={loading}
          updateStatusHandler={updateStatusHandler}
        />
      </div>
    </>
  );
};

export default Promote;
