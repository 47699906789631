import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import LoaderScreen from "../../../components/LoaderScreen";
import { addNotification } from "../../../components/Notification";
import Dropdown from "../../../components/Dropdown";
import CustomDropdown from "../../../components/CustomDropdown";
import SimpleReactValidator from 'simple-react-validator';

const userRoleOptions = [
  { "id": process.env.REACT_APP_MODERATOR, "name": "Moderator" },
  { "id": process.env.REACT_APP_RK_MANAGER, "name": "RK Manager" },
  { "id": process.env.REACT_APP_COMPLIANCE_OFFICER, "name": "Compliance Officer" },
  { "id": process.env.REACT_APP_MARKETEING_USER, "name": "Marketing User" },
];

const ProfileInformation = ({ className, createUser, loading }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userRoleText, setUserRoleText] = useState("Select a value");
  const [userRoleValue, setUserRoleValue] = useState("");
  const [role, setRole] = useState('Select user role');
  const optionsRole = process.env.REACT_APP_USER_ROLES.split(',');
  const [passwordType, setPasswordType] = useState("password");
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    };
    setPasswordType("password")
  };

  const userRoleHandler = (x) => {
    setUserRoleText(x.name);
    setUserRoleValue(x.id);
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Create new user"
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <div className={styles.field}>
        <TextInput
          className={styles.className}
          label="First name"
          name="firstname"
          placeholder='Please enter name'
          value={firstName}
          type="text"
          onChange={(e) => setFirstName(e.target.value)}
          required
          onBlur={() => simpleValidator.current.showMessageFor("firstname")}
        />
        {simpleValidator.current.message("firstname", firstName, "required")}
      </div>
      <div className={styles.field}>

        <TextInput
          className={styles.className}
          label="Last name"
          name="lastname"
          placeholder='Please enter last name'
          value={lastName}
          type="text"
          onChange={(e) => setLastName(e.target.value)}
          required
          onBlur={() => simpleValidator.current.showMessageFor("lastname")}
        />
        {simpleValidator.current.message("lastname", lastName, "required")}
      </div>
      <div className={styles.field}>

        <TextInput
          className={styles.className}
          label="Email"
          name="email"
          value={email}
          placeholder='Please enter email'
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          required
          onBlur={() => simpleValidator.current.showMessageFor("email")}
        />
        {simpleValidator.current.message("email", email, "required|email")}
      </div>
      <div className={styles.field}>
        <TextInput
          className={styles.className}
          label="Password"
          name="password"
          placeholder='Please enter password'
          value={password}
          type={passwordType}
          view
          togglePassword={togglePassword}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={() => simpleValidator.current.showMessageFor("password")}
        />
        {simpleValidator.current.message("password", password, "required|min:9")}
      </div>
      <div className={styles.field}>
        <CustomDropdown
          className={styles.field}
          label="Role"
          options={userRoleOptions}
          text={userRoleText}
          setText={setUserRoleText}
          value={userRoleValue}
          setValue={setUserRoleValue}
          handleClick={userRoleHandler}
        />
        <br />
        <button
          onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              if (userRoleText !== 'Select a value') {
                createUser(email, password, userRoleValue, firstName, lastName);
              }
              else {
                addNotification({
                  title: 'Error',
                  message: "Please select a role.",
                  type: 'danger'
                });
              }
            }
            else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }}
          className={cn("button", styles.button)}
        >
          Submit
        </button>
      </div>
    </Item >
  );
};

export default ProfileInformation;
