import React, { useState } from "react";
import styles from "./Products.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Market from "./Market";
import Form from "../../../components/Form";

const Products = ({ active, handleClick, getOrderData, handlePageClick, ordersDetails, pageNo, loading, getOrderManagementDetails, marketValue }) => {
  const navigation = ["Open Orders", "Orders History"];

  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [searchField, setSearchField] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Card
      className={styles.card}
      title="Orders"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <div className={cn(styles.nav, "tablet-hide")}>
            {navigation.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x === activeTab,
                })}
                onClick={() => {
                  getOrderData((x.toLowerCase().includes("history")) ? parseInt(process.env.REACT_APP_ORDER_STATUS_FILLED) :
                    (x.toLowerCase().includes("open")) ? parseInt(process.env.REACT_APP_ORDER_STATUS_OPEN) : null)
                  setActiveTab(x);
                }}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
        </>
      }
    >
      <div className={styles.products}>
        {(activeTab.toLowerCase().includes("history")) && active ?
          <>
            <button
              className={cn("button button-small", styles.button)}
              onClick={(e) => {
                if (active === false) {
                  handleClick();
                }
              }}
            >
              Limit Orders
            </button>
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={(e) => {
                if (active === true) {
                  handleClick();
                }
              }}
            >
              Market Orders
            </button>
          </> :
          (activeTab.toLowerCase().includes("history")) && active === false ?
            <>
              <button
                className={cn("button-stroke button-small", styles.button)}
                onClick={(e) => {
                  if (active === false) {
                    handleClick();
                  }
                }}
              >
                Limit Orders
              </button>
              <button
                className={cn("button button-small", styles.button)}
                onClick={(e) => {
                  if (active === true) {
                    handleClick();
                  }
                }}
              >
                Market Orders
              </button>
            </> : ""
        }
        <div className={styles.searchFieldClass}>
          <Form
            className={styles.form}
            value={searchField}
            setValue={setSearchField}
            onSubmit={handleSubmit}
            placeholder="Search by Order Id"
            type="text"
            name="search"
            icon="search"
          />
        </div>
        <div className={styles.wrapper}>
          <Market
            ordersDetails={ordersDetails}
            activeTab={activeTab}
            handlePageClick={handlePageClick}
            pageNo={pageNo}
            loading={loading}
            active={active}
            searchField={searchField}
          />
        </div>
      </div>
    </Card>
  );
};

export default Products;
