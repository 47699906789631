import React from "react";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../../components/helper";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item }) => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.preview}>
            <img srcSet={`${item.image2x} 2x`} src={process.env.REACT_APP_IMAGE_URL + "/" + item?.icon} alt="Coin" />
          </div>
          <div className={styles.details}>
            <div
              className={styles.post}
              dangerouslySetInnerHTML={{ __html: item.name.toUpperCase() }}
            ></div>
            <div className={styles.text}>
              {item.symbol.toUpperCase()}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.text}>
          {item?.touserid}
        </div>
      </div>
      <div className={styles.col}>
        {getDigitsAfterDecimal(item?.amount, item?.decimalPrecision)}
        <div className={styles.text}>
          <small>{item?.symbol.toUpperCase()}</small>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.txid}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_SUCCESS) && (
          <div className={cn("status-green-dark", styles.distribution)}>Success</div>
        )}
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.createdAt)}
      </div>
    </div>
  );
};

export default Row;
