import React, { useDeferredValue, useEffect, useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Checkbox from "../../../components/Checkbox";
import Loader from "../../../components/Loader";
import Row from "./Row";
import { customers } from "../../../mocks/customers";
import { useDispatch, useSelector } from "react-redux";
import { userWalletDetailsCreator } from "../../../actions/userWalletDetails";
import { useParams } from "react-router";
import Item from "../Item";
import requestHandler from "../../../actions/httpClient";
import TextInput from "../../../components/TextInput";

// data
const Table = ({ className, activeTable, setActiveTable, bankDetails, gatewayList,
  updateUserBankHandler, wwftDetailsData,
  profileInfo, superadmin }) => {
  const [constable, setConstable] = useState([]);
  const [chooseAll, setСhooseAll] = useState(false);
  const [activeId, setActiveId] = useState(customers[0].id);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const dispatch = useDispatch()
  const { id } = useParams()

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <>
      <Item
        className={cn(styles.card, className)}
        title="Bank information"
        classTitle="title-green"
      >
        <div className={cn(styles.wrapper, className)}>
          <div className={cn(styles.table)}>
            <div className={cn(styles.row, { [styles.active]: activeTable })}>
              <div className={styles.col}>
              </div>
              <div className={styles.col}>Account Number</div>
              <div className={styles.col}>Bank Name</div>
              <div className={styles.col}>Bank Proof</div>
              <div className={styles.col}>Payment Mode</div>
              <div className={styles.col}>Status</div>
              {superadmin && <div className={styles.col}>Action</div>}
            </div>
            {bankDetails?.map((x, index) => (
              <Row
                superadmin={superadmin}
                item={x}
                key={index}
                activeTable={activeTable}
                setActiveTable={setActiveTable}
                activeId={activeId}
                setActiveId={setActiveId}
                value={selectedFilters.includes(x.id)}
                onChange={() => handleChange(x.id)}
                bankDetails={bankDetails}
                updateUserBankHandler={updateUserBankHandler}
                profileInfo={profileInfo}
              />
            ))}
          </div>
        </div>
      </Item>

      <Item
        className={cn(styles.card, styles.itemClass)}
        title="Wwft Information"
        classTitle="title-green"
      >
        <div className={styles.fieldset}>
          {/* <h3 className={styles.title}>As per Wwft Regulations in the Netherlands and Europe, following information is here.</h3> */}
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="YOUR PROFESSION / OCCUPATION ?"
              name="profession"
              value={wwftDetailsData?.profession === null || wwftDetailsData?.profession === undefined ? "---" : wwftDetailsData?.profession}
              type="text"
              required
              disabled={true}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="YOUR AVERAGE TRADING ANNUAL VOLUME?"
              name="annualVolume"
              value={wwftDetailsData?.avg_trading_annual_vol === undefined || wwftDetailsData?.avg_trading_annual_vol === null ? "---" : wwftDetailsData?.avg_trading_annual_vol}
              type="text"
              required
              disabled={true}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="WHERE DO YOU GET YOUR FIAT (EURO) FROM?"
              name="euroFrom"
              value={wwftDetailsData?.euro_from === null || wwftDetailsData?.euro_from === undefined ? "---" : wwftDetailsData?.euro_from}
              type="text"
              required
              disabled={true}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="GROSS ANNUAL INCOME?"
              name="grossIncome"
              value={wwftDetailsData?.gross_income === undefined || wwftDetailsData?.gross_income === null ? "---" : wwftDetailsData?.gross_income}
              type="text"
              required
              disabled={true}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="IN WHICH COINS WILL YOU TRADE THE MOST?"
              name="mostTradingCoin"
              value={wwftDetailsData?.most_trading_coin === null || wwftDetailsData?.most_trading_coin === undefined ? "---" : wwftDetailsData?.most_trading_coin}
              type="text"
              required
              disabled={true}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="WHY DO YOU WANT TO OPEN AN ACCOUNT?"
              name="whyOpenAccount"
              value={wwftDetailsData?.why_open_account === null || wwftDetailsData?.why_open_account === undefined ? "---" : wwftDetailsData?.why_open_account}
              type="text"
              required
              disabled={true}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="ARE YOU EXPECTING TO TRANSFER FUND TO ANY PARTY OUTSIDE OF THE NETHERLANDS?"
              name="whyOpenAccount"
              value={wwftDetailsData?.transfer_outside_nth === null || wwftDetailsData?.transfer_outside_nth === undefined ? "---" : wwftDetailsData?.transfer_outside_nth}
              type="text"
              required
              disabled={true}
            />
          </div>
        </div>
      </Item>
    </>
  );
};

export default Table;
