import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./LogIn.module.sass";
import { use100vh } from "react-div-100vh";
import { useNavigate } from "react-router-dom";
import Entry from "./Entry";
import Code from "./Code";
import Confirm from "./Confirm";
import { useDispatch, useSelector } from "react-redux";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";
import Barcode from "./Barcode";
import LoaderScreen from "../../components/LoaderScreen";

const items = [
  "Manage Customers",
  "Manage Coins & Markets",
  "Reporting",
  "Order Management",
];

const LogIn = () => {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const heightWindow = use100vh();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginSuccess, setLoginSuccess] = useState([]);
  const [email, setEmail] = useState("");
  const [verify2FASuccess, setVerify2FASuccess] = useState([]);
  const [captchaData, setCaptchaData] = useState([]);
  const [captchaField, setCaptchaField] = useState("");
  const [signInLoading, setSignInLoading] = useState(false);
  const [captchaLoading, setCaptchaLoading] = useState(false);

  useEffect(() => {
    captchaHandler();
  }, []);

  const loginHandler = async (email, password, cId) => {
    setSignInLoading(true);
    try {
      let data = {
        id: cId,
        code: captchaField
      };
      const verifyCaptchaPayload = await requestHandler("verifyCaptcha", 'post', data);
      // setSignInLoading(false);
      if (verifyCaptchaPayload && verifyCaptchaPayload.status === 200) {
        let data = {
          email: email,
          password: password
        }
        setEmail(data.email);
        const signInPayload = await requestHandler("login", 'post', data);
        setLoginSuccess(signInPayload);
        if (signInPayload && signInPayload.status === 200) {
          setSignInLoading(false);
          setActiveIndex(1);
        }
        else if (signInPayload && signInPayload.status === 201) {
          setSignInLoading(false);
          setActiveIndex(2);
        }
        else if (signInPayload && signInPayload.status === 202) {
          setSignInLoading(false);
          setActiveIndex(3);
        }
      }
    }
    catch (e) {
      setSignInLoading(false);
      setCaptchaField("");
      await captchaHandler();
      addNotification({
        title: "Error",
        message: e.data.message[0].msg,
        type: "danger"
      });
    };
  };

  const codeScreenHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField) => {
    setSignInLoading(true);
    const code = firstField + secondField + thirdField + fourthField + fifthField + sixthField;
    try {
      let data = {
        email_otp: code,
        email,
        type: loginSuccess.data.message.type
      };
      const codeScreenPayload = await requestHandler("verify_otp", "post", data);
      setLoginSuccess(codeScreenPayload);
      if (codeScreenPayload && codeScreenPayload.status === 200) {
        setSignInLoading(false);
        setActiveIndex(1);
      }
      else if (codeScreenPayload && codeScreenPayload.status === 202) {
        setSignInLoading(false);
        setActiveIndex(3);
      };
    }
    catch (e) {
      setSignInLoading(false);
      addNotification({
        title: 'Error',
        message: e.data.message[0].msg,
        type: 'danger'
      });
    }
  };

  const verify2FAHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField) => {
    setSignInLoading(true);
    const code = firstField + secondField + thirdField + fourthField + fifthField + sixthField;
    try {
      let data = {
        email,
        code: code,
      };
      const verify2FAPayload = await requestHandler("verifyLogin", "post", data);
      if (verify2FAPayload && verify2FAPayload.status === 200) {
        setSignInLoading(false);
        localStorage.setItem("signature", verify2FAPayload?.data?.data[(verify2FAPayload?.data?.data?.length) - 1]?.signature);
        localStorage.setItem("jwt_token", verify2FAPayload?.data?.data[(verify2FAPayload?.data?.data?.length) - 1]?.jwt_token);
        document.cookie = "refresh_token=" + verify2FAPayload.data.data[(verify2FAPayload.data.data.length) - 1].jwt_refresh_token;
        localStorage.setItem("sideMenu", true);
        navigate("/");
      }
    }
    catch (e) {
      setSignInLoading(false);
      addNotification({
        title: 'Error',
        message: e.data.message[0].msg,
        type: 'danger'
      });
    }
  };

  const resendOtpHandler = async () => {
    setSignInLoading(true);
    let data = {
      email: email,
      type: loginSuccess.data.message.type,
    };
    try {
      const resendOtpPayload = await requestHandler("gen_otp", 'post', data, 'jwt_token');
      if (resendOtpPayload && resendOtpPayload.status === 201) {
        setSignInLoading(false);
        addNotification({
          title: 'Success',
          message: resendOtpPayload?.data?.message?.status,
          type: 'success'
        });
      }
    }
    catch (e) {
      setSignInLoading(false);
    }
  };

  const captchaHandler = async () => {
    setCaptchaLoading(true);
    let data = {
      theme: localStorage.getItem("darkMode"),
    }
    try {
      const captchaPayload = await requestHandler("createCaptcha", "post", data);
      setCaptchaData(captchaPayload?.data);
      setCaptchaLoading(false);
    } catch (e) {
      setCaptchaLoading(false);
    }
  }

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.wrap}>
          <div className={styles.preview}>
            <img src="/images/content/logo.svg" alt="Login" />
          </div>
          <div className={cn("h4", styles.subtitle)}>Bitdenex Administration</div>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li key={index}>{x}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.wrapper}>
          <div className={cn("h2", styles.title)}>Sign In</div>
          {signInLoading && <LoaderScreen />}
          {activeIndex === 0 && <Entry
            loginHandler={loginHandler}
            captchaData={captchaData}
            captchaField={captchaField}
            setCaptchaField={setCaptchaField}
            captchaLoading={captchaLoading}
          />}
          {activeIndex === 1 && <Barcode
            loginSuccess={loginSuccess}
            verify2FAHandler={verify2FAHandler}
          />}
          {activeIndex === 2 && <Code
            codeScreenHandler={codeScreenHandler}
            resendOtpHandler={resendOtpHandler}
          />}
          {activeIndex === 3 && <Confirm
            loginSuccess={loginSuccess}
            verify2FAHandler={verify2FAHandler}
          />}
        </div>
      </div>
    </div>
  );
};

export default LogIn;
