import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({ item, setVisibleModal, setSelectedOrder }) => {

  return (
    <div className={styles.row} onClick={() => {
      item?.status === parseInt(process.env.REACT_APP_ORDER_STATUS_FILLED) &&
        setVisibleModal(true);
      setSelectedOrder(item);
    }}>
      <div className={styles.col}>
        {item.userId}
      </div>
      <div className={styles.col}>
        {item.region}
      </div>
      <div className={styles.col}>
        <div className={styles.info}>{toLocaleStringDateFormat(item.updatedAt)} </div>
      </div>
      <div className={styles.col}>
        {item.orderId}
      </div>

      <div className={styles.col}>
        {item?.slug?.toUpperCase()}
      </div>

      <div className={styles.col}>
        {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) && (
          <div className={styles.positive}><small>Buy</small></div>
        )}
        {(item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) && (
          <div className={styles.negative}><small>Sell</small></div>
        )}
      </div>

      <div className={styles.col}>
        {item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ? (
          <div className={styles.positive}>{getDigitsAfterDecimal(item.price, item.priceDecimalPrecision)} <small>  {item?.slug.split("-")[1].toUpperCase()} </small> </div>
        ) :
          (<div className={styles.negative}>{getDigitsAfterDecimal(item.price, item.priceDecimalPrecision)} <small>  {item?.slug.split("-")[1].toUpperCase()} </small> </div>)}
      </div>

      <div className={styles.col}>
        {item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ? (
          item?.status === parseInt(process.env.REACT_APP_CANCELLED_ORDERS) ?
            <div className={styles.positive}>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)} <small> {item?.slug.split("-")[0].toUpperCase()} {item.exeCrypto > 0 ? <div>{"(P.filled =" + item.exeCrypto + ")"}</div> : null} </small>  </div>
            :
            <div className={styles.positive}>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)} <small> {item?.slug.split("-")[0].toUpperCase()} {item.crypto_exe_amount > 0 ? <div> {"(P.filled =" + item.crypto_exe_amount + ")"} </div> : null} </small>  </div>

        ) :
          (<div className={styles.negative}>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)} <small> {item?.slug.split("-")[0].toUpperCase()} {item.crypto_exe_amount > 0 ? <div> {"(P.filled =" + item.crypto_exe_amount + ")"} </div> : null} </small>  </div>)}
      </div>
      <div className={styles.col}>
        {item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ? (
          item?.status === parseInt(process.env.REACT_APP_CANCELLED_ORDERS) ?
            <div className={styles.positive}>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)} <small>  {item?.slug.split("-")[1].toUpperCase()} {item.exeFiat > 0 ? <div>{"(P.filled =" + item.exeFiat + ")"}</div> : null} </small>   </div>
            :
            <div className={styles.positive}>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)} <small>  {item?.slug.split("-")[1].toUpperCase()} {item.fiat_exe_amount > 0 ? <div> {"(P.filled =" + item.fiat_exe_amount + ")"} </div> : null} </small>   </div>
        ) :
          (<div className={styles.negative}>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)} <small> {item?.slug.split("-")[1].toUpperCase()} {item.fiat_exe_amount > 0 ? <div> {"(P.filled =" + item.fiat_exe_amount + ")"} </div> : null} </small>   </div>)}
      </div>
      <div className={styles.col}>
        {item?.status === parseInt(process.env.REACT_APP_ORDER_STATUS_OPEN) ? "Open" : "Completed"}
      </div>
    </div>
  );
};

export default Row;
