import React, { useEffect, useState } from "react";
import styles from "./NewProduct.module.sass";
import NameAndDescription from "./NameAndDescription";
import requestHandler from "../../actions/httpClient";
import { useLocation, useNavigate } from "react-router";
import { addNotification } from "../../components/Notification";

const UpdateCoin = ({ }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [coinsNetwork, setCoinsNetwork] = useState([]);
  const [coinsId, setCoinsId] = useState([]);
  const [networkSlugId, setNetworkSlugId] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    setFields(state?.questions || [{ title: '', description: '' }]);
  }, [state]);

  const addField = () => {
    setFields([...fields, { title: '', description: '' }]);
  };

  const removeField = (index) => {
    const updatedFormData = [...fields];
    updatedFormData.splice(index, 1);
    setFields(updatedFormData);
  };

  const handleInputChange = (index, key, value) => {
    const updatedFormData = [...fields];
    updatedFormData[index][key] = value;
    setFields(updatedFormData);
  };

  useEffect(() => {
    // if (state.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN || state.stableCoin === 0)) {
    getCoinsNetwork()
    // }
  }, []);

  const getCoinsNetwork = async () => {
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      setLoading(true);
      var coinsNetworkPayload = await requestHandler("get_coin_network", "post", data, "jwt_token");
      setLoading(false);
      let dummyArr = coinsNetworkPayload?.data?.data?.map((x) => {
        return { "value": x?.id, "label": `${x?.network_slug} (${x?.network_name})` }
      });

      setCoinsNetwork([...dummyArr]);

      let arr2 = [];
      let networkIdArr = [];
      for (var i = 0; i < dummyArr.length; i++) {
        for (var j = 0; j < state.networks.length; j++) {
          if (dummyArr[i].value == parseInt(state.networks[j])) {
            arr2.push(dummyArr[i]);
            networkIdArr.push(dummyArr[i].value);
          }
        }
      }
      setSelected(arr2);
      setCoinsId(networkIdArr);
      let newArr = arr2.map((x) => { return x.value });
      setNetworkSlugId(newArr);
    }
    catch (e) {
      setLoading(false);
    }
  }

  const handleChange = (x) => {
    if (x.length > 0) {
      let newArr = x.map((y) => { return y.value });
      setNetworkSlugId(newArr);
    }
    else if (x.length === 0) {
      setNetworkSlugId([]);
    }
  };

  const updateCoinHandler = async (status, maintenanceMode, decimalPrecision,
    name, coinMaxFee, coinMinFee, coinMinBalance, enableDepositValue, enableWithdrawValue, symbol, coinImage, bannerImage, coinDescription) => {
    setLoading(true);
    let questionData = fields.map(field => ({
      title: field.title,
      description: field.description
    }));
    let data = {
      coin_id: state?.id,
      status: status.toLowerCase() === "enable" ? parseInt(process.env.REACT_APP_UPDATE_COIN_STATUS_ENABLE)
        : parseInt(process.env.REACT_APP_UPDATE_COIN_STATUS_DISABLE),
      maintenanceMode: maintenanceMode.toLowerCase() === "yes" ? parseInt(process.env.REACT_APP_UPDATE_COIN_MAINTENANCE_MODE_YES) : parseInt(process.env.REACT_APP_UPDATE_COIN_MAINTENANCE_MODE_NO),
      signature: localStorage.getItem("signature"),
      networks: networkSlugId ? JSON.stringify(networkSlugId) : null,
      decimalPrecision: decimalPrecision,
      name: name,
      maxFee: coinMaxFee,
      minFee: coinMinFee,
      minBalance: coinMinBalance,
      enable_deposit: enableDepositValue,
      enable_withdraw: enableWithdrawValue,
      icon: coinImage,
      symbol: symbol,
      question: JSON.stringify(questionData),
      description: coinDescription,
      banner: bannerImage,
      internalbot: process.env.REACT_APP_COIN_INTERNALBOT_VALUE,
      spread_value: parseInt(process.env.REACT_APP_COIN_SPREAD_VALUE),
    };
    try {
      const updateCoinPayload = await requestHandler("update_coin", "post", data, "jwt_token", "file");
      setLoading(false);
      if (updateCoinPayload && updateCoinPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateCoinPayload?.data?.message[0]?.msg,
          type: 'success'
        });
        getCoins();
        navigate("/trade/coins");
        window.location.reload();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    }
  };

  const getCoins = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const coinsPayload = await requestHandler("getCoins", "post", data, "jwt_token");
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription
            className={styles.card}
            state={state}
            updateCoinHandler={updateCoinHandler}
            loading={loading}
            setLoading={setLoading}
            getCoinsNetwork={getCoinsNetwork}
            coinsNetwork={coinsNetwork}
            selected={selected}
            coinsId={coinsId}
            setCoinsId={setCoinsId}
            handleChange={handleChange}
            networkSlugId={networkSlugId}
            setNetworkSlugId={setNetworkSlugId}
            fields={fields}
            addField={addField}
            removeField={removeField}
            handleInputChange={handleInputChange}
          />
        </div>
      </div>
    </>
  );
};

export default UpdateCoin;
