import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import { useEffect } from "react";
import requestHandler from "../../../actions/httpClient";
import { addNotification } from "../../../components/Notification";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({ className, status }) => {
  const [allCryptoWithdrawData, setAllCryptoWithdrawData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);

  useEffect(() => {
    getAllCryptoWithdrawRequests();
  }, [status]);

  const getAllCryptoWithdrawRequests = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: status,
    };
    try {
      const getAllCryptoWithdrawPayload = await requestHandler("getMiracleCryptoWithdraw", "post", data, "jwt_token");
      setLoading(false);
      setAllCryptoWithdrawData(getAllCryptoWithdrawPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const updateCryptoWithdrawHandler = async (userId) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      id: userId
    };
    try {
      const updateCryptoWithdrawPayload = await requestHandler("actionMiracleCryptoWithdraw", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      if (updateCryptoWithdrawPayload && updateCryptoWithdrawPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateCryptoWithdrawPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    }
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Requests"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Address</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            {status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_PENDING) && <div className={styles.col}>Action</div>}
          </div>
          {loading ? <CustomerListSkeleton colCount={6} rowCount={12} />
            :
            allCryptoWithdrawData?.length > 0 ?
              <>
                {
                  allCryptoWithdrawData.map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      updateCryptoWithdrawHandler={updateCryptoWithdrawHandler}
                      status={status}
                    />
                  ))
                }
              </>
              :
              "No data found"
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
