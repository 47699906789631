import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import CutomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Row from "./Row";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import Form from "../../../components/Form";

const RecentPost = ({ className, coinsData, loading }) => {
  const [searchField, setSearchField] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Coins"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by name or symbol"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.control}>
              <Link className={cn("button", styles.button)} to="/trade/coins/add-coin">
                <Icon name="add" size="24" />
                <span>Add Coin</span>
              </Link>
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>Maintenance mode</div>
            <div className={styles.col}>Is Active</div>
            <div className={styles.col}>Fireblock Id</div>
            <div className={styles.col}>Networks</div>
            <div className={styles.col}>Fee</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CutomerListSkeleton colCount={7} rowCount={12} />
            : coinsData.filter((data) => {
              return data.name.toLowerCase().includes(searchField.toLowerCase()) ||
                data.symbol.toLowerCase().includes(searchField.toLowerCase())
            })
              .map((x, index) => (
                <Row item={x} key={index} />
              ))}
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
