import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CreateReward from "./Details";
import Form from "../../../components/Form";
import requestHandler from "../../../actions/httpClient";
import { addNotification } from "../../../components/Notification";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Modal from "../../../components/Modal";

const RecentPost = ({ className }) => {
  const [searchField, setSearchField] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const orderSearchHandler = async () => {
    setLoading(true);
    const data = {
      orderId: searchField.trim(),
      signature: localStorage.getItem("signature"),
    };
    try {
      if (data.search_text === "") {
        setLoading(false);
        addNotification({
          title: 'Error',
          message: "Search field can not be empty.",
          type: 'danger'
        });
      }
      else {
        const orderSearchPayload = await requestHandler("getOrderById", "post", data, "jwt_token");
        setLoading(false);
        setOrderData(orderSearchPayload?.data?.data)
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    }
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Order"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by order id"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.control}>
              <button className={cn("button", styles.button)} onClick={() => { orderSearchHandler(); }}>
                <span>Submit</span>
              </button>
            </div>
          </>
        }
      >
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>C Id</div>
              <div className={styles.col}>Region</div>
              <div className={styles.col}>Date</div>
              <div className={styles.col}>Order Id</div>
              <div className={styles.col}>Market</div>
              <div className={styles.col}>Type</div>
              <div className={styles.col}>Price</div>
              <div className={styles.col}>Amount</div>
              <div className={styles.col}>Total</div>
              <div className={styles.col}>Status</div>
            </div>
            {
              loading ? <CustomerListSkeleton colCount={10} rowCount={1} /> :
                orderData?.map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                    setVisibleModal={setVisibleModal}
                    setSelectedOrder={setSelectedOrder}
                  />
                ))}
          </div>
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <CreateReward
          selectedOrder={selectedOrder}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
