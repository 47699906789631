import React from "react";
import { toLocaleStringDateFormat } from "../../../../../components/helper";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item }) => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item?.amount}
        <div className={styles.text}>
          {item?.currency}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.reference}
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.col}>
        {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_STATUS_PENDING) && (
          <div className={cn("status-red-dark", styles.distribution)}>Pending</div>
        )}
        {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_STATUS_APPROVED) && (
          <div className={cn("status-green-dark", styles.distribution)}>Approved</div>
        )}
        {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_STATUS_CANCELLED) && (
          <div className={cn("status-red-dark", styles.distribution)}>Cancelled</div>
        )}
      </div> */}
      <div className={styles.col}>
        {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PENDING) && (
          <div className={cn("status-red-dark", styles.distribution)}>Pending</div>
        )}
        {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_APPROVED) && (
          <div className={cn("status-green-dark", styles.distribution)}>Success</div>
        )}
        {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_CANCELLED) && (
          <div className={cn("status-red-dark", styles.distribution)}>Cancelled</div>
        )}
        {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_DECLINED) && (
          <div className={cn("status-red-dark", styles.distribution)}>Declined</div>
        )}
        {item?.status === parseInt(process.env.REACT_APP_FIAT_WITHDRAW_PROCESS) && (
          <div className={cn("status-red-dark", styles.distribution)}>In Process</div>
        )}
        {item?.status === null || item?.status === undefined && "---"}
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.createdAt)}
      </div>
    </div>
  );
};

export default Row;
