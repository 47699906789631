import { init } from 'onfido-sdk-ui'
import { useEffect } from 'react'
import styles from "./Onfido.module.sass";
import cn from "classnames";
import { addNotification } from '../../../../../components/Notification';

const Onfido = ({ token, workFlowRunId, getCustomerDetails }) => {

    useEffect(() => {
        init({
            token: token,
            containerId: 'onfido-mount',
            onComplete: async function (data) {
                addNotification({
                    title: 'Success',
                    message: "Kyc is completed.",
                    type: 'Success'
                });
            },
            onError: async function (error) {

            },
            onUserExit: async function (userExit) {

            },
            workflowRunId: workFlowRunId,
            customUI: {
                "colorBackgroundButtonPrimary": "#22767D", "colorBackgroundButtonPrimaryHover": "#123D41"
            }
        })
    }, [])

    return (
        <div className={styles.modal} id="onfido-mount" style={{ padding: 20 }}></div>
    )
}


export default Onfido