import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import requestHandler from "../../../actions/httpClient";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Form from "../../../components/Form";
import Dropdown from "../../../components/Dropdown";
import Pagination from "../../../components/Pagination";
import { addNotification } from "../../../components/Notification";

const regionValues = [process.env.REACT_APP_EUROPE_REGION_TEXT.toUpperCase(), process.env.REACT_APP_DEFAULT_REGION_TEXT.toUpperCase(), process.env.REACT_APP_SURINAME_REGION.toUpperCase()];
const searchValues = ["Reference", "UId", "Id"];
const RecentPost = ({ className }) => {
  const dateFilter = process.env.REACT_APP_DATE_FILTER.split(',');
  const [loading, setLoading] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [approvedSepaDepositList, setApprovedSepaDepositList] = useState([]);
  const [date, setDate] = useState(dateFilter[0]);
  const [regionValue, setRegionValue] = useState(regionValues[0]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [searchValue, setSearchValue] = useState(searchValues[0]);

  useEffect(() => {
    setSearchField("");
    getApprovedSepaRequest();
  }, [date, regionValue, pageCount]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const getApprovedSepaRequest = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      pageno: pageCount,
      region: regionValue,
      interval: date.toLowerCase() === "day" ? parseInt(process.env.REACT_APP_DAILY_DATA) :
        date.toLowerCase() === "week" ? parseInt(process.env.REACT_APP_WEEKLY_DATA) :
          date.toLowerCase() === "month" ? parseInt(process.env.REACT_APP_MONTHLY_DATA) :
            date.toLowerCase() === "year" ? parseInt(process.env.REACT_APP_ANNUALY_DATA) : null
    };
    try {
      const getApprovedSepaRequestPayload = await requestHandler("actionSepaRequestList", "post", data, "jwt_token");
      setLoading(false);
      setApprovedSepaDepositList(getApprovedSepaRequestPayload?.data?.message.data);
      setTotalPages(getApprovedSepaRequestPayload?.data?.message.count);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const handlePageChange = (page) => {
    setPageCount(page.selected + 1);
  };

  const approvedSepaSearchHandler = async (searchField) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      pageno: pageCount,
      region: regionValue,
      interval: date.toLowerCase() === "day" ? parseInt(process.env.REACT_APP_DAILY_DATA) :
        date.toLowerCase() === "week" ? parseInt(process.env.REACT_APP_WEEKLY_DATA) :
          date.toLowerCase() === "month" ? parseInt(process.env.REACT_APP_MONTHLY_DATA) :
            date.toLowerCase() === "year" ? parseInt(process.env.REACT_APP_ANNUALY_DATA) : null,
      search_text: searchField,
      type: searchValue === "First Name" ? "fname" : searchValue.toLowerCase(),
    };
    try {
      if (data?.search_text === "") {
        setLoading(false);
        addNotification({
          title: "Alert",
          message: "Search field can not be empty",
          type: "danger",
        });
      }
      else {
        const approvedSepaSearchPayload = await requestHandler("actionSepaRequestListSearch", "post", data, "jwt_token");
        setLoading(false);
        setApprovedSepaDepositList(approvedSepaSearchPayload?.data?.message.data);
        setTotalPages(approvedSepaSearchPayload?.data?.message.count);
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Please verify the entered details.",
        type: "danger",
      });
    }
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Deposits"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={searchValue}
                setValue={setSearchValue}
                options={searchValues}
                small
              />
            </div>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search"
              type="text"
              name="search"
              icon="search"
            />
            <button className={cn("button-small", styles.dropdown)} type="button"
              onClick={(e) => {
                e.preventDefault();
                approvedSepaSearchHandler(searchField);
              }}
            >
              Search
            </button>
            <div className={styles.regionDropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={regionValue}
                setValue={setRegionValue}
                options={regionValues}
                small
              />
            </div>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={date}
                setValue={setDate}
                options={dateFilter}
                small
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>C Id</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>U Id</div>
            <div className={styles.col}>Reference Id</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={6} rowCount={12} />
            :
            approvedSepaDepositList.length > 0 ?
              <>
                {
                  approvedSepaDepositList?.map((x, index) => (
                    <Row item={x} key={index} />
                  ))}
                {/* {approvedSepaDepositList?.length === (10 * pageNo) && <div className={styles.foot}>
                  <button className={cn("button-stroke button-small", styles.button)} onClick={() => { handlePageClick() }}>
                    <span>Load more</span>
                  </button>
                </div>
                } */}
              </>
              :
              "No data found"
          }
        </div>
        {
          approvedSepaDepositList?.length > 0 &&
          <Pagination
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        }
      </Card>
    </>
  );
};

export default RecentPost;
