import React, { useState } from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import { toLocaleStringDateFormat } from "../../../../../components/helper";

const Share = ({ item, botTypeValue }) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Bot Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Bot Id
        </div>
        <div>
          {item?.id}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Unique Id</div>
        <div>
          {item?.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Slug</div>
        <div>
          {item?.slug}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Buy Fee</div>
        <div>
          {item?.buy_fee}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Sell Fee</div>
        <div>
          {item?.sell_fee}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Markets
          <div>
            {item?.markets && item?.markets.length > 0 &&
              <>
                {item?.markets?.map((x, index) => {
                  return (
                    <div className={styles.col} key={index}>
                      {`${index + 1}. ${x.toUpperCase()}`}
                    </div>
                  )
                })}
              </>
            }
          </div>
        </div>
      </div>
      {botTypeValue === parseInt(process.env.REACT_APP_LIMIT_ORDER_BOT) &&
        <div className={styles.wrapper}>
          <div className={styles.text}>Wallet & Balance
            {item?.wallet && item?.wallet?.length > 0 &&
              <>
                {item?.wallet?.map((x, index) => {
                  return (
                    <div className={styles.markets}>
                      <div className={styles.item} key={index}>
                        {`${index + 1}. ${x?.name?.toUpperCase()}`}
                      </div>
                      <div className={styles.item}>{x?.balance}</div>
                    </div>
                  )
                })}
              </>
            }
          </div>
        </div>}

      <div className={styles.wrapper}>
        <div className={styles.text}>Description</div>
        <div className={styles.col}>
          {item.description}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Date</div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(item.createdAt)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Status</div>
        <div className={styles.col}>
          {item.status === parseInt(process.env.REACT_APP_BOT_USER_ACTIVE) ?
            (<span className={cn("status-green-dark", styles.distribution)}>Active</span>) :
            item?.status === parseInt(process.env.REACT_APP_BOT_USER_DEACTIVE) ?
              (<span className={cn("status-red-dark", styles.distribution)}>Pending</span>) : ""}
        </div>
      </div>
    </div >
  );
};

export default Share;
