import React, { useEffect, useState } from "react";
import styles from "./Promote.module.sass";
import Overview from "./Overview";
import LoaderScreen from "../../components/LoaderScreen";
import requestHandler from "../../actions/httpClient";

const regionValues = [process.env.REACT_APP_EUROPE_REGION_TEXT.toUpperCase(), process.env.REACT_APP_DEFAULT_REGION_TEXT.toUpperCase(), process.env.REACT_APP_SURINAME_REGION.toUpperCase()];

const Promote = () => {

    const dateFilter = process.env.REACT_APP_DATE_WITH_CUSTOM_FILTER.split(',');
    const [date, setDate] = useState(dateFilter[0]);
    const [regionValue, setRegionValue] = useState(regionValues[0]);
    const [loading, setLoading] = useState(false);
    const [financeData, setFinanceData] = useState([]);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const currentDate = new Date();

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };

    useEffect(() => {
        getFinanceDataHandler();
    }, [date, regionValue, fromDate, toDate]);

    const getFinanceDataHandler = async () => {
        setLoading(true);
        let data = {
            signature: localStorage.getItem("signature"),
            region: regionValue,
            interval: date.toLowerCase() === "day" ? parseInt(process.env.REACT_APP_DAILY_DATA) :
                date.toLowerCase() === "week" ? parseInt(process.env.REACT_APP_WEEKLY_DATA) :
                    date.toLowerCase() === "month" ? parseInt(process.env.REACT_APP_MONTHLY_DATA) :
                        date.toLowerCase() === "year" ? parseInt(process.env.REACT_APP_ANNUALY_DATA) :
                            date.toLowerCase() === "hour" ? parseInt(process.env.REACT_APP_HOURLY_DATA) :
                                date.toLowerCase() === "custom" ? parseInt(process.env.REACT_APP_CUSTOM_DATE_DATA) : null,
            from: fromDate,
            to: toDate,
        };
        try {
            const financePayload = await requestHandler("finance_fiat_deposit", "post", data, "jwt_token");
            setFinanceData(financePayload?.data?.message);
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
        };
    };

    return (
        <>
            {loading && <LoaderScreen />}
            <div className={styles.section}>
                <Overview
                    className={styles.card}
                    regionValues={regionValues}
                    date={date}
                    setDate={setDate}
                    dateFilter={dateFilter}
                    regionValue={regionValue}
                    setRegionValue={setRegionValue}
                    financeData={financeData}
                    fromDate={fromDate}
                    toDate={toDate}
                    handleToDateChange={handleToDateChange}
                    handleFromDateChange={handleFromDateChange}
                    currentDate={currentDate}
                />
            </div>
        </>
    );
};

export default Promote;
