import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({
  item,
  regionValue,
  setVisibleModal,
  setSelectedTransaction,
  markAsCheckedHandler,
  listStatus,
  intervalStatus
}) => {

  function hoursToDays(hours) {
    return hours / 24;
  };

  return (
    <>
      <div className={(styles.row)} onClick={() => {
        setVisibleModal(true);
        setSelectedTransaction(item);
      }}>
        <div className={styles.col}>
          {item?.userId}
        </div>
        <div className={styles.col}>
          {item?.module === process.env.REACT_APP_TRANSACTION_TYPE_FIAT_DEPOSIT_SINGLE ?
            "Fiat Deposit"
            :
            item?.module === process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_DEPOSIT_SINGLE ?
              "Crypto Deposit"
              :
              item?.module === process.env.REACT_APP_TRANSACTION_TYPE_FIAT_WITHDRAW_SINGLE ?
                "Fiat Withdraw"
                :
                item?.module === process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_WITHDRAW_SINGLE ?
                  "Crypto Withdraw"
                  :
                  item?.module === process.env.REACT_APP_TRANSACTION_TYPE_FIAT_TO_CRYPTO_SINGLE ?
                    "Fiat to Crypto"
                    :
                    item?.module === process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_TO_FIAT_SINGLE ?
                      "Crypto to Fiat"
                      :
                      item?.module === process.env.REACT_APP_TRANSACTION_TYPE_FIAT_TO_CRYPTO_HOUR ?
                        "Fiat to Crypto"
                        :
                        item?.module === process.env.REACT_APP_TRANSACTION_TYPE_CRYPTO_TO_FIAT_HOUR ?
                          "Crypto to Fiat"
                          :
                          ""
          }
        </div>
        <div className={styles.col}>
          {regionValue.toLowerCase() === "europe" ?
            `${getDigitsAfterDecimal(item?.total_amount, 2)} EUR`
            :
            `${getDigitsAfterDecimal(item?.total_amount, 2)} USDT`
          }
        </div>
        {
          listStatus.toLowerCase() === "completed" &&
          <div className={styles.col}>
            <div className={styles.cell}>
              <div className={styles.box}>
                {item?.checkedby === undefined || item?.checkedby === null ? "---" : item?.checkedby}
              </div>
            </div>
          </div>
        }
        {
          intervalStatus.toLowerCase() !== "days" &&
          <>
            <div className={styles.col}>
              <div className={styles.cell}>
                <div className={styles.box}>
                  {item?.orderId === undefined || item?.orderId === null ? "---" : item?.orderId}
                </div>
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.cell}>
                <div className={styles.box}>
                  {item?.crypto_txid === null || item?.crypto_txid === undefined ? "---" : item?.crypto_txid}
                </div>
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.cell}>
                <div className={styles.box}>
                  {item?.fiat_reference === null || item?.fiat_reference === undefined ? "---" : item?.fiat_reference}
                </div>
              </div>
            </div>
          </>
        }
        <div className={styles.col}>
          {item?.duration === "0" ? "Single" : `${hoursToDays(item?.duration)} ${item?.duration === "24" ? "Day" : "Days"}`}
        </div>
        {/* {
          listStatus.toLowerCase() === "pending" &&
          <div className={styles.col}>
            <button className={cn("button-small", styles.button)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                markAsCheckedHandler(item?.monitoring_id);
              }}
            >
              Mark As Checked</button>
          </div>
        } */}
      </div>
    </>
  );
};

export default Row;
