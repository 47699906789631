import React, { useState } from "react";
import styles from "./Row.module.sass";
import Details from "./Details";
import cn from "classnames";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../components/helper";
import NewModal from "../../../../components/NewModal";

const Row = ({ item, updateCryptoWithdrawHandler, regionValue }) => {

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className={styles.row} onClick={handleOpenModal}>
        <div className={styles.col}>
          {item.userId}
        </div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              <img srcSet={`${item.image2x} 2x`} src={process.env.REACT_APP_IMAGE_URL + "/" + item?.icon} alt="Coin" />
            </div>
            <div className={styles.details}>
              <div
                className={styles.post}
                dangerouslySetInnerHTML={{ __html: item.name.toUpperCase() }}
              ></div>
              <div className={styles.text}>
                {item.symbol}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.box}>
              <div className={styles.number}>
                {item.uid}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          {getDigitsAfterDecimal(item.amount, item.decimalPrecision) + " " + item.symbol.toUpperCase()}
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{item.address}</div>
          </div>
        </div>
        {item?.region === process.env.REACT_APP_EUROPE_REGION_TEXT &&
          <div className={styles.col}>
            {item?.address_status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PENDING) ? (
              <div className={cn("status-red-dark", styles.distribution)}>Pending</div>
            ) :
              item?.address_status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PROCESSING) ? (
                <div className={cn("status-red-dark", styles.distribution)}>Process</div>
              ) :
                item?.address_status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED) ? (
                  <div className={cn("status-green-dark", styles.distribution)}>Verified</div>
                ) :
                  item?.address_status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_DECLINED) ? (
                    <div className={cn("status-red-dark", styles.distribution)}>Declined</div>
                  ) :
                    item?.address_status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_CANCELLED) ? (
                      <div className={cn("status-red-dark", styles.distribution)}>Cancelled</div>
                    ) : ""
            }
          </div>
        }
        <div className={styles.col}>
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_UNVERIFIED) && (
            <div className={cn("status-red-dark", styles.distribution)}>Unverified</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_PENDING) && (
            <div className={cn("status-red-dark", styles.distribution)}>Pending</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO__TRANSACTION_STATUS_PROCESS) && (
            <div className={cn("status-red-dark", styles.distribution)}>Process</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_SUCCESS) && (
            <div className={cn("status-green-dark", styles.distribution)}>Success</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_CANCEL) && (
            <div className={cn("status-red-dark", styles.distribution)}>Cancelled</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_DECLINE) && (
            <div className={cn("status-red-dark", styles.distribution)}>Declined</div>
          )}
          {item?.status === null || item?.status === undefined && "---"}
        </div>
        <div className={styles.col}>
          {item?.network_slug ? item?.network_slug : "---"}
        </div>
        {item?.region === process.env.REACT_APP_EUROPE_REGION_TEXT ?
          <div className={styles.col}>
            <div className={styles.row}>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  {item?.address_status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED) && item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_PENDING) &&

                    <div>
                      <button className={cn("button", styles.acceptButton)} type="button"
                        onClick={() => {
                          updateCryptoWithdrawHandler(parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO__TRANSACTION_STATUS_PROCESS), item.id, item.userId);
                        }}
                      >
                        <span>Process</span>
                      </button>
                      <button className={cn("button", styles.rejectButton)} type="button"
                        onClick={() => {
                          updateCryptoWithdrawHandler(parseInt(process.env.REACT_APP_DEPOSIT_CRYPTO_TRANSACTION_STATUS_DECLINE), item.id, item.userId);
                        }}
                      >
                        <span>Decline</span>
                      </button>
                    </div>
                  }
                  {item?.address_status !== parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED) &&
                    <button className={cn("button", styles.rejectButton)} type="button"
                      onClick={() => {
                        updateCryptoWithdrawHandler(parseInt(process.env.REACT_APP_DEPOSIT_CRYPTO_TRANSACTION_STATUS_DECLINE), item.id, item.userId);
                      }}
                    >
                      <span>Decline</span>
                    </button>}
                  {item?.status !== parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_PENDING) &&
                    "---"}
                </div>
              </div>
            </div>
          </div>
          :
          <div className={styles.col}>
            <div className={styles.row}>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <div>
                    <button className={cn("button", styles.acceptButton)} type="button"
                      onClick={() => {
                        updateCryptoWithdrawHandler(parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO__TRANSACTION_STATUS_PROCESS), item.id, item.userId);
                      }}
                    >
                      <span>Process</span>
                    </button>
                    <button className={cn("button", styles.rejectButton)} type="button"
                      onClick={() => {
                        updateCryptoWithdrawHandler(parseInt(process.env.REACT_APP_DEPOSIT_CRYPTO_TRANSACTION_STATUS_DECLINE), item.id, item.userId);
                      }}
                    >
                      <span>Decline</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <NewModal
        outerClassName={styles.outer}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
      >
        <Details
          item={item}
          regionValue={regionValue}
        />
      </NewModal>
    </>
  );
};

export default Row;
