import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Modal from "../../../../components/Modal";
import DirectorDetails from "./DirectorDetails";

const Row = ({
  item,
  checkDirectorKycHandler,
  kybInProcessActionHandler,
  setUserId,
  superadmin
}) => {

  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={styles.row} onClick={() => { setVisible(true) }}>
        <div className={styles.col}>
          {item.fname ? item.fname : "---"}
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            {item.lname ? item.lname : "---"}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            {item.email ? item.email : "---"}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            {`(${item.country_code})${item.mobile_no}`}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            {item.kyb_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING) && (<div className={styles.content}>Pending</div>)}
            {item.kyb_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONUSEREXIT) && (<div className={styles.content}>User Exit</div>)}
            {item.kyb_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONINPROCESS) && (<div className={styles.content}>In process</div>)}
            {item.kyb_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONONERROR) && (<div className={styles.content}>Cancelled</div>)}
            {item.kyb_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE) && (<div className={styles.content}>Complete</div>)}
            {item.kyb_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONINREVIEW) && (<div className={styles.content}>In process</div>)}

          </div>
        </div>
        {superadmin &&
          <div className={styles.col}>
            {item.kyb_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING) ?
              <button className={cn("button button-small", styles.button)} onClick={(e) => {
                e.stopPropagation();
                checkDirectorKycHandler(item?.id);
                setUserId("");
                setUserId(item?.id);
              }}>
                <span>Start KYC Again</span>
              </button>
              :
              item?.kyb_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONINREVIEW) ?
                <>

                  <button className={cn("button button-small", styles.button, styles.acceptButton)} onClick={(e) => { e.stopPropagation(); kybInProcessActionHandler(item?.id, parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE)) }}>
                    <span>Accept</span>
                  </button>

                  <button className={cn("button button-small", styles.button, styles.rejectButton)} onClick={(e) => { e.stopPropagation(); kybInProcessActionHandler(item?.id, parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING)) }}>
                    <span>Reject</span>
                  </button>
                </>
                :
                item?.kyb_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE) ?
                  <button className={cn("button button-small", styles.button, styles.acceptButton)} onClick={(e) => { e.stopPropagation(); window.open(process.env.REACT_APP_ONFIDO_REPORT_URL + "/" + item.workflowrun_kyc_id, '_blank', 'noopener,noreferrer') }}>
                    <span>View KYC</span>
                  </button>
                  :
                  "---"
            }
          </div>
        }
      </div>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <DirectorDetails item={item} />
      </Modal>
    </>
  );
};

export default Row;
