import React from 'react'
import styles from "./Row.module.sass";
import Icon from '../../../../components/Icon';
import { getDigitsAfterDecimal } from '../../../../components/helper';

const index = ({ item }) => {

    return (
        <>
            <div className={styles.list}>
                <div className={styles.item}>
                    <div
                        className={styles.icon}
                        style={{ backgroundColor: "#B5E4CA" }}
                    >
                        <Icon name={"arrows-up-down"} size="24" />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.label}>
                            Total Buy Amount
                        </div>
                        <div className={styles.counter}>{getDigitsAfterDecimal(item?.buy_amount, 2)} <small>{item?.currency}</small></div>
                    </div>
                </div>
                <div className={styles.item}>
                    <div
                        className={styles.icon}
                        style={{ backgroundColor: "#CABDFF" }}
                    >
                        <Icon name={"arrows-up-down"} size="24" />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.label}>
                            Total Buy Profit
                        </div>
                        <div className={styles.counter}>{getDigitsAfterDecimal(item?.buy_profit, 2)} <small>{item?.currency}</small></div>
                    </div>
                </div>
                <div className={styles.item}>
                    <div
                        className={styles.icon}
                        style={{ backgroundColor: "#FFBC99" }}
                    >
                        <Icon name={"arrows-up-down"} size="24" />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.label}>
                            Total Buy Orders
                        </div>
                        <div className={styles.counter}>{item?.buy_total_request}</div>
                    </div>
                </div>
                <div className={styles.item}>
                    <div
                        className={styles.icon}
                        style={{ backgroundColor: "#B1E5FC" }}
                    >
                        <Icon name={"arrows-up-down"} size="24" />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.label}>
                            Total Buy Success Orders
                        </div>
                        <div className={styles.counter}>{item?.buy_success_request}</div>
                    </div>
                </div>
            </div>
            <div className={styles.list}>
                <div className={styles.item}>
                    <div
                        className={styles.icon}
                        style={{ backgroundColor: "#B5E4CA" }}
                    >
                        <Icon name={"arrows-up-down"} size="24" />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.label}>
                            Total Sell Amount
                        </div>
                        <div className={styles.counter}>{getDigitsAfterDecimal(item?.sell_amount, item?.amountDecimalPrecision)} <small>{item?.coin?.toUpperCase()}</small></div>
                    </div>
                </div>
                <div className={styles.item}>
                    <div
                        className={styles.icon}
                        style={{ backgroundColor: "#CABDFF" }}
                    >
                        <Icon name={"arrows-up-down"} size="24" />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.label}>
                            Total Sell Profit
                        </div>
                        <div className={styles.counter}>{getDigitsAfterDecimal(item?.sell_profit, item?.amountDecimalPrecision)} <small>{item?.coin?.toUpperCase()}</small></div>
                    </div>
                </div>
                <div className={styles.item}>
                    <div
                        className={styles.icon}
                        style={{ backgroundColor: "#FFBC99" }}
                    >
                        <Icon name={"arrows-up-down"} size="24" />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.label}>
                            Total Sell Orders
                        </div>
                        <div className={styles.counter}>{item?.sell_total_request}</div>
                    </div>
                </div>
                <div className={styles.item}>
                    <div
                        className={styles.icon}
                        style={{ backgroundColor: "#B1E5FC" }}
                    >
                        <Icon name={"arrows-up-down"} size="24" />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.label}>
                            Total Sell Success Orders
                        </div>
                        <div className={styles.counter}>{item?.sell_success_request}</div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default index;
