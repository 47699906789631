import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import requestHandler from "../../../actions/httpClient";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Form from "../../../components/Form";
import Dropdown from "../../../components/Dropdown";
import { addNotification } from "../../../components/Notification";

const statusValues = [process.env.REACT_APP_FIAT_DEPOSIT_SUCCESS, process.env.REACT_APP_FIAT_DEPOSIT_DECLINED];
const RecentPost = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [approvedSepaDepositList, setApprovedSepaDepositList] = useState([]);
  const [statusValue, setStatusValue] = useState(statusValues[0]);

  useEffect(() => {
    getAllCustomersSepaRequest();
  }, [statusValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const getAllCustomersSepaRequest = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: statusValue,
    };
    try {
      const getAllCustomersSepaPayload = await requestHandler("getFiatDepositRequestList", "post", data, "jwt_token");
      setLoading(false);
      setApprovedSepaDepositList(getAllCustomersSepaPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Requests"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by Customer Id or Reference Id"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.regionDropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={statusValue}
                setValue={setStatusValue}
                options={statusValues}
                small
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Customer Id</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Reference Id</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={5} rowCount={12} />
            :
            approvedSepaDepositList.length > 0 ?
              <>
                {
                  approvedSepaDepositList?.filter((data) => {
                    return data?.reference?.toLowerCase().includes(searchField?.toLowerCase())

                  })
                    .map((x, index) => (
                      <Row item={x} key={index} />
                    ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
