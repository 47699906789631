import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

import { toLocaleStringDateFormat } from "../../../../components/helper";
const Row = ({ item, updateAddressHandler }) => {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>{item.id}</div>
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.box}>
              <div className={styles.number}>{item?.userId}</div>
            </div>
          </div>
        </div>

        <div className={styles.col}>{item.monitor_id}</div>

        <div className={styles.col}>
          {item.status ===
            parseInt(process.env.REACT_APP_WATCHLIST_UPDATE_STATUS_PENDING) && (
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
          )}
          {item.status ===
            parseInt(
              process.env.REACT_APP_WATCHLIST_UPDATE_STATUS_COMPLETED
            ) && (
            <div className={cn("status-green-dark", styles.distribution)}>
              Complete
            </div>
          )}

          {item.status === null || (item.status === undefined && "---")}
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>
              {toLocaleStringDateFormat(item?.createdAt)}
            </div>
          </div>
        </div>

        <div className={styles.col}>
          {parseInt(item.status) ===
            parseInt(process.env.REACT_APP_WATCHLIST_UPDATE_STATUS_PENDING) && (
            <>
              <div className={styles.buttonClass}>
                <button
                  className={cn("button-small")}
                  style={{ background: "#55a288c4" }}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateAddressHandler(
                      item.id,
                      parseInt(
                        process.env.REACT_APP_WATCHLIST_UPDATE_STATUS_COMPLETED
                      )
                    );
                  }}
                >
                  <span>Mark </span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Row;
